import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Button from '../../atoms/Button/Button';
import Card from '../../molecules/Card/Card';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import dataLayer from "../../../common/GTM/dataLayer";
import noimage from './../../../assets/images/no-image-available.png';

const settings = (props) => {
    const Arrow = (props) => {
        const { className, onClick, gtm } = props;
        return (
            <div
                className={className + " " + gtm}
                onClick={onClick}
            />
        );
    };
    
    let config = props.type === "img" || props.type === "detail-product" ? {
        infinite: true,
        speed: 500,
        slidesToShow: props.slider_slidestoshow,
        slidesToScroll: 1,
        autoplay: props.autoplay ? props.autoplay : false,
        autoplaySpeed: props.autoplaySpeed ? props.autoplaySpeed : false
    } : {
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            autoplay: props.autoplay ? props.autoplay : false,
            autoplaySpeed: props.autoplaySpeed ? props.autoplaySpeed : false,
            nextArrow: <Arrow gtm="gtmHomeBannerRotFlec" />,
            prevArrow: <Arrow gtm="gtmHomeBannerRotFlec" />,
            appendDots: dots => (
                <div>
                    <ul>
                        {dots.map((item, index) => {
                            let childrenElement = React.cloneElement(item.props.children, { className: "gtmHomeBannerRotNum" });
                            return (
                                <li className={item.props.className + ((props.gtm) ? " " + props.gtm : "")} key={index}>{childrenElement}</li>
                            );
                        })}
                    </ul>
                </div>
            ),
            customPaging: i => (
                <div>
                    0{i + 1}
                </div>
            )
        }
    return config;
};

const handleBannerClick = (value) => {
    dataLayer.pushEvent('Home', 'ClicBanner', value);
};
const CustomSlider = props => {
    const { t } = useTranslation();
   // console.log(props.array);
    return (
        <>
            {props.title && <h3 className={`section-title ${props.titleclass}`}>{props.title}</h3>}

            <Slider dotsClass={props.slider_dotclass} className={props.slider_class} slidesToShow={props.slider_slidestoshow} dots={props.slider_dots} {...settings(props)} responsive={props.responsive}>
                {props.type === 'banner' ?
                    props.array.map((item, index) => (
                        <div className="o__banner-wrap" key={item.id}>
                            <Link className="o__banner-link-mobile" to={`/product${item.slug}/${item.id}`}>
                            </Link>
                            <div className="o__banner-container">
                                <div className="o__banner-slider banner-info">
                                    <h1>{item.nombre}</h1>
                                    <p>{item.descripcion}</p>
                                    <br />
                                    <Button classnames="primary o__banner-slider--btn" text={t('pages.home.btn-see-products')} type="link" href={`/product${item.slug}/${item.id}`} onclick={() => handleBannerClick(item.nombre)}></Button>
                                </div>
                                <div className="o__banner-slider banner-image">
                                    <img src={(item.img) ? item.img : noimage} alt={`${props.type} product ${index + 1}`} />
                                </div>
                            </div>
                        </div>
                    ))
                    : (props.type === 'detail-product') ?
                        props.array.length > 0 ? props.array.map((item, index) => (
                            <Card
                                cardtype='product-detail'
                                imagetype="image" key={index}
                                image_src={(item) ? item : noimage}
                                alt={(item.descripcion) ? item.descripcion : ''}
                                classname="image-fullwidth" />
                        )) :
                            <Card
                                cardtype='product-detail'
                                imagetype="image" key={0}
                                image_src={noimage}
                                alt={''}
                                classname="image-fullwidth" />
                        :
                        props.array.map((item, index) => {
                          return <Card
                                cardtype='image'
                                imagetype="image"
                                key={index}
                                image_src={(item.img) ? item.img : noimage}
                                alt={(item.descripcion) ? item.descripcion : ''}
                                text={item.nombre}
                                id={item.id}
                                precio={item.precio}
                                href={`/product${item.slug}/${item.id}`}
                                reload={props.reload}
                                classname="image-fullwidth" />
                        })
                }
            </Slider>
        </>
    )
}

// `${props.type} product ${index + 1}`

CustomSlider.propTypes = {
    array: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    image_class: PropTypes.string,
    slider_class: PropTypes.string,
    slider_dots: PropTypes.bool,
    slider_slidestoshow: PropTypes.number,
    slider_dotclass: PropTypes.string,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number
};
export default CustomSlider;