import * as constants from "./../../state/constants/appConstants";
var _token = `token_${constants.rolUsuario}`;
var _timer = `token_${constants.rolUsuario}`;

export class Token {

    isTokenValid = () => {
        try {
            return localStorage.getItem(_token);
        } catch (e) {
            return false;
        }
    };
    getTimer() {
        return localStorage.getItem(_timer);
    }
    setTimer(timer) {
        return localStorage.setItem(_timer, timer);
    }
    deleteTimer(){
        return localStorage.removeItem(_timer);
    }
    getToken() {
        return localStorage.getItem(_token);
    }

    setToken(token) {
        return localStorage.setItem(_token, token);
    }

    deleteToken() {
        return localStorage.removeItem(_token);
    }
}

export default new Token();