import React from "react";
import { Link } from "react-router-dom";
import ImgConstruction from "../../../assets/images/in-construction.svg";
import { useTranslation } from "react-i18next";

const InConstruction = () => {
  const { t } = useTranslation();

  return (
    <div className="t__in-construction">
      <div className="t__in-construction-container">
        <div className="container-img">
          <img
            className="img-constructuion"
            src={ImgConstruction}
            alt={t("pages.in-construction.title")}
          />
        </div>
        <h2 className="title-in-construction">{t("pages.in-construction.title")}</h2>
        <Link to="/">{t("pages.in-construction.btn-back")}</Link>
      </div>
    </div>
  );
};

export default InConstruction;
