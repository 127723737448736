import React from "react";
import PropTypes from "prop-types";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import { BsSearch } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { FiShoppingCart } from "react-icons/fi";

const IconButton = (props) => {
  if (props.type === "searchButton")
    return (
      <Button
        classnames={`gtmHomeSupBuscar icon-button ${
          !props.isactive ? "icon-button--active" : ""
        } ${props.type}`}
        type="iconbutton"
        onClick={props.onclick}
      >
        {/* <Icon gtm="gtmHomeSupBuscar" icon="icon-search1" size="1" tagtype="i" /> */}
        <BsSearch className="gtmHomeSupBuscar" />
      </Button>
    );
  else if (props.type === "userButton")
    return (
      <span id={props.id} gtm={props.gtm} className={props.className} onClick={() => window.location = "/login"}>
        <FiUser />
      </span> 
    );
  else if (props.type === "userButtonActive")
    return (
      <span id={props.id} gtm={props.gtm} className={props.className} onClick={props.onclick}>
        <FiUser />
      </span> 
    );
  else if (props.type === "carButton")
    return (
      <span id={props.id} gtm={props.gtm} className={props.className} onClick={() => props.openModal('cart-modal')}>
        <FiShoppingCart />
        <sup className={parseInt(props.totalCartNumber) > 0 ? "hascontent" : "" }>{parseInt(props.totalCartNumber) }</sup>
      </span> 
    );
  else
    return (
      <Icon
        icon={`icon-search1 cerulean ${props.type}`}
        size="1-2"
        tagtype="span"
        onclick={props.onclick}
      />
    );
};

IconButton.propTypes = {
  onclick: PropTypes.func,
  isactive: PropTypes.bool,
  type: PropTypes.string,
};

export default IconButton;
