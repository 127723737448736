import React, { useState, useEffect } from "react";
import Card from '../../molecules/Card/Card';
import OutletServices from "../../../services/OutletServices/OutletServices";
import noimage from './../../../assets/images/no-image-available.png';
import Loader from "../../atoms/Loader/Loader";
import { useTranslation } from "react-i18next";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import Pagination from "react-js-pagination";
import * as constants from "./../../../state/constants/appConstants";


const OutletProducts = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [listProductsOutlet, setListProductsOutlet] = useState([]);
    const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';
    const [activePage, setActivePage] = useState(1);


    useEffect(() => {
        const userInfo = JSON.parse(UserInfo.getUser());
        const getProductsOutlet = () => {
            let payload = {
                params: {
                    idioma: getCurrentLng(),
                    rolUsuario: constants.rolUsuario,
                    listaPrecio: (userInfo && userInfo.listaPrecio) ? userInfo.listaPrecio : 20
                }
            };
            console.log(payload)
            OutletServices.getProducts(payload).then(res => {
                if (res.estadoRespuesta === 0) {
                    setListProductsOutlet(res.productosOutlet);
                    setIsLoading(false);
                } else {
                    console.warn("Error en servicio outlet: ", res);
                }
            }).catch(err => console.error(err));
        };
        getProductsOutlet();
    }, []);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        window.scrollTo(0, 0);

    }
    return (isLoading === false ?
        <div className="o__outletproducts">
            <h1>Outlet</h1>
            <p>{t('pages.outlet.description')}</p>
            <section className="d-flex o__outletproducts--container">
                <div className="o__outletproducts__products">
                    {listProductsOutlet.map((item, i) => {
                        let limit_right = 12 * activePage;
                        let limit_left = limit_right - 12;
                        if (i >= limit_left && i < limit_right) {
                            return <Card
                                cardtype='outlet'
                                imagetype="img"
                                //    image_src={(item.img) ? "data:image/png;base64, " + item.img : noimage}
                                image_src={(item.img) ? item.img : noimage}
                                alt={(item.nombre) ? item.nombre : 'No name'}
                                text={(item.nombre) ? item.nombre : 'SIN NOMBRE'}
                                id={item.id}
                                precioAnterior={item.precioAnterior}
                                precioConDescuento={item.precioConDescuento}
                                href={`/product${item.slug}/${item.id}`}
                                classname=""
                                key={i} />
                        } else {
                            return false;
                        }
                    })}
                </div>
            </section>
            <Pagination
                activePage={activePage}
                itemsCountPerPage={12}
                totalItemsCount={listProductsOutlet.length}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
            />
        </div> : <Loader />
    )
}

export default OutletProducts;