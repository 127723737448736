import React, { useState, useEffect } from "react";
import referralServices from "../../../services/RegisterServices/RegisterServices";
import * as constants from "../../../state/constants/appConstants";
import Token from "../../../common/LocalStorage/Token";
import { useHistory } from "react-router-dom";
import Spacer from "../../atoms/Spacer/Spacer";


const ReferralBoardTemplate = (props) => {
  let token = Token.getToken();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (!token) {
      history.push("/login");
    } else {
      setUserInfo(
        JSON.parse(localStorage.getItem(`userInfo_${constants.rolUsuario}`))
      );
    }
  }, []);
  useEffect(() => {
    if (userInfo) {
      const getRefferalServices = async () => {
        let payload = {
          vededor: 4302,
          rol: constants.rolUsuario,
        };
        let result = await referralServices.getReferral(payload);
        console.log(result);
      };
      getRefferalServices();
    }
  }, [userInfo]);
  return (
    <div className="container">
      <Spacer px={30} />
      <h1 className="max-width-800">Mis referidos</h1>
      <Spacer px={20} />
      <div className="white-card-bkg"></div>
    </div>
  );
};

export default ReferralBoardTemplate;
