import * as types from './../constants/actionConstants';

const initialState = {
    bannerProducts: [],
    featuredProducts: [],
    trendingProducts: []
};

const rdc_product = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.HOME_BANNER_PRODUCTS_SUCCESS:
            return {
                ...state,
                bannerProducts: payload.ProductosHome
            };
        case types.HOME_BANNER_PRODUCTS_FAILED:
            return state;
        case types.HOME_FEATURED_PRODUCTS_SUCCESS:
            return {
                ...state,
                featuredProducts: payload.ProductosTrending
            };
        case types.HOME_FEATURED_PRODUCTS_FAILED:
            return state;
        case types.HOME_TRENDING_PRODUCTS_SUCCESS:
            return {
                ...state,
                trendingProducts: payload.ProductosTrending
            };
        case types.HOME_TRENDING_PRODUCTS_FAILED:
            return state;
        default:
            return state;
    }
};

export default rdc_product;