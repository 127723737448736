import React from "react";

const noop = () => { };
const FileInput = (props) => {
    const { value, onChange = noop, label, placeholder, isUserError } = props;

    return (
        <div className="u-file-input">
            <span>{label}</span>
            <label className={isUserError ? "field-error" : ""}>
                {Boolean(value.length) ? (
                    <div>{value.map(f => f.name).join(", ")}</div>
                ) : <div>{placeholder}</div>}
                <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={e => {
                        onChange([...e.target.files]);
                    }} />
            </label>
        </div>
    )
};

export default FileInput;