import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class ProductServices {
  static getProductsByCategoryAndSubCategory(payload) {
    return axios.get(endpoints.getProducts, payload).then(res => res.data);
  }

  static getProductById(payload) {
    return axios.get(endpoints.getDetailProduct, payload).then(res => res.data);
  }
  static getProctsRelated(payload) {
    return axios.post(endpoints.productosRelacionados, payload).then(res => res.data);
  }
  static getEscalas(payload) {
    return axios.get(endpoints.getScalas, payload).then(res => res.data);
  }
}

export default ProductServices;