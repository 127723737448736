import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Image from '../../atoms/Image/Image';
import NumberFormat from 'react-number-format';
import Icon from './../../atoms/Icon/Icon';
import dataLayer from "../../../common/GTM/dataLayer";
import { getCart } from "../../../state/actions/act_cart";

const CartCardProduct = props => {
    const { srcImg, title, amount, updateProductList, idProduct, updateValue, currency, discount, fullPrice } = props;
    const handleDelete = () => {
        updateProductList(idProduct);
        dataLayer.pushEvent("CarritoDeCompra", "ClicSeleccionProductoEliminarProducto", title);
        props.getCart();
    };

    const handleSusQuantity = () => {
        if (amount > 1) {
            updateValue(idProduct, amount - 1);
            props.getCart();
        }
    };

    const handleSumQuantity = () => {
        updateValue(idProduct, amount + 1);
        props.getCart();
    };

    return (
        <ul className="m__cart-card-product white-card-bkg" >
            <li className="left-cont">
                <Image type={'image'} src={srcImg} alt={''} classname={''} />
                <div>
                    <p> {title} </p>
                    <span>
                        {fullPrice && <>
                            {currency === "USD" ? (
                                <>
                                    <b><NumberFormat value={fullPrice.USD.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> USD x {amount}</b>
                                </>
                            ) : (
                                <>
                                    {discount > 0 ?
                                        <>
                                            <b className='tachedNumber'><NumberFormat value={fullPrice.COP.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> COP x {amount}</b>
                                            <b><NumberFormat value={discount.toFixed(0)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> COP x {amount}</b>
                                        </>
                                        :
                                        <b><NumberFormat value={fullPrice.COP.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> COP x {amount}</b>

                                    }
                                </>
                            )
                            }
                        </>
                        }
                    </span>
                </div>
            </li>
            <li className="right-cont">
                <div className="m__amount">
                    <button className="btn-sus" onClick={() => handleSusQuantity()}> - </button>
                    <input
                        name="amount"
                        className="num-amount"
                        type="text"
                        value={amount}
                        onChange={() => { }} />
                    <button className="btn-sum" onClick={() => handleSumQuantity()}> + </button>
                </div>

                {fullPrice && discount > 0 ? <>
                    {currency === "USD" ? (
                        <small>{<NumberFormat className="total-price" value={fullPrice.USD * amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />} USD</small>
                    ) : (
                        <small>{<NumberFormat className="total-price" value={(discount * amount).toFixed(0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />} COP</small>
                    )
                    }
                </>
                    : <>
                        {fullPrice && <>
                            {currency === "USD" ? (
                                <small>{<NumberFormat className="total-price" value={fullPrice.USD * amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />} USD</small>
                            ) : (
                                <small>{<NumberFormat className="total-price" value={fullPrice.COP * amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />} COP</small>
                            )
                            }
                        </>
                        }
                    </>
                }
            </li>
            <li className="delete-cont">
                <button className="gtmCarritoDeCompraEliminarProducto btn-delete" onClick={() => handleDelete()}>
                    <Icon gtm="gtmCarritoDeCompraEliminarProducto" icon="icon-trash-2" size="1-5" tagtype="i" />
                </button>
            </li>
        </ul>
    )
}

CartCardProduct.propTypes = {
    srcImg: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    getProductById: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    total: PropTypes.number,
    updateSummary: PropTypes.func,
    recalculateAmount: PropTypes.func,
    totalOrder: PropTypes.number,
}

const mapStateToProps = state => ({
    cart: state.cart
});

const mapDispatchToProps = {
    getCart
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartCardProduct);