import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const PricesPolicy = props => {
  const { t } = useTranslation();
  const costShippingRef = useRef(null);
  const delTimesRef = useRef(null);
  const { state } = props.location;

  useEffect(() => {
    if (typeof state != "undefined") {
      switch (state.scrollTo) {
        case 'cost-shipping':
          scrollToRef(costShippingRef);
          break;

        case 'delivery-times':
          scrollToRef(delTimesRef);
          break;
        default:
          window.scrollTo(0, 0);
          break;
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [state]);

  return (
    <div className="content-page-container">
      <h1> {t("pages.shipping-policy.title")} </h1>
      <p>{t("pages.shipping-policy.description")}</p>
      <h3 ref={delTimesRef}>{t("pages.shipping-policy.subtitle-delivery-times")}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t("pages.shipping-policy.description-delivery-times")
        }}
      ></div>
      <h3>{t("pages.shipping-policy.subtitle-price-clauses-colombia")}</h3>
      <ul
        dangerouslySetInnerHTML={{
          __html: t("pages.shipping-policy.description-price-clauses-colombia")
        }}
      ></ul>
      <h3>{t("pages.shipping-policy.subtitle-price-clauses-outside")}</h3>
      <ul
        dangerouslySetInnerHTML={{
          __html: t("pages.shipping-policy.description-price-clauses-outside")
        }}
      ></ul>
      <h2 ref={costShippingRef}>{t("pages.shipping-policy.subtitle-shipping-costs")}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: t("pages.shipping-policy.description-shipping-costs")
        }}
      ></p>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PricesPolicy);
