import React from 'react';
import CategoryCard from './../../molecules/CategoryCard/CategoryCard';
import noimage from './../../../assets/images/no-image-available.png';
import PropTypes from 'prop-types';

const CategorySubcategories = props => {
    const { subcategories, cat } = props;
    return (
        (subcategories) &&
        <div className="o__categoriessubcategories">
            {subcategories.map((subcat, i) => (
               // <CategoryCard cat={cat} subcat={subcat} src={(subcat.img) ? "data:image/png;base64, " + subcat.img : noimage} key={i} />
               <CategoryCard cat={cat} subcat={subcat} src={(subcat.img) ? subcat.img : noimage} key={i} />
            ))}
        </div>
    );
}

CategorySubcategories.propTypes = {
    cat: PropTypes.object.isRequired,
    subcategories: PropTypes.arrayOf(Object).isRequired,
    codigo: PropTypes.string,
    nombreSubcategoria: PropTypes.string
}

export default CategorySubcategories;