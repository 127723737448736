import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const ForeignSales = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page-container">
      <h1>{ t('pages.exchange-and/or-return-policies.title') }</h1>
      <p dangerouslySetInnerHTML={{
          __html: t("pages.exchange-and/or-return-policies.description-return-policies")
        }}>
      </p>
      <h3>{ t('pages.exchange-and/or-return-policies.subtitle-refund-order') } </h3>
      <p dangerouslySetInnerHTML={{
          __html: t("pages.exchange-and/or-return-policies.description-refund-order")
        }}>
      </p>
      <h3>{ t('pages.exchange-and/or-return-policies.subtitle-what-is-a-partial-refund') }</h3>
      <div dangerouslySetInnerHTML={{
          __html: t("pages.exchange-and/or-return-policies.description-what-is-a-partial-refund")
        }}>
      </div>
      <h3> { t('pages.exchange-and/or-return-policies.subtitle-credit-card') } </h3>
      <p dangerouslySetInnerHTML={{
          __html: t("pages.exchange-and/or-return-policies.description-credit-card")
        }}>
      </p>
      <h3> { t('pages.exchange-and/or-return-policies.subtitle-methods-pay') } </h3>
      <div dangerouslySetInnerHTML={{
          __html: t("pages.exchange-and/or-return-policies.description-methods-pay")
        }}>
      </div>
      <h3>{ t('pages.exchange-and/or-return-policies.subtitle-right-withdrawal') }</h3>
      <div dangerouslySetInnerHTML={{
          __html: t("pages.exchange-and/or-return-policies.description-right-withdrawal")
        }}>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForeignSales);
