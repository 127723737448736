import React from "react";
import { connect } from "react-redux";

class TermsAndConditions extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="content-page-container">
        <h1>TÉRMINOS Y CONDICIONES ACCESO A PLATAFORMA VIRTUAL UNITEC USA</h1>
        <p><strong>UNITEC USA</strong> pone a su disposición el canal de acceso para la realización de actividades descritas más adelante con el objeto de facilitar el acceso a los clientes de nuestra plataforma transaccional a nivel mundial.
          </p>
        <p>El usuario se compromete a leer los términos y condiciones aquí establecidos y aceptarlos como condición para su primer ingreso, por tanto, se entiende que cuenta con el conocimiento integral de los términos y condiciones de uso y la consecuente aceptación de la totalidad de sus estipulaciones.</p>
        <p>El usuario reconoce que el ingreso de su información personal, y los datos que contiene la aplicación o portal web a su disposición respecto a los productos activos, la realiza de manera voluntaria, quienes optan por acceder, lo hacen por iniciativa propia.</p>
        <p>El usuario entiende y acepta que esta es operada por <strong>UNITEC USA</strong>, la información contenida en la misma será la referente a su vínculo comercial, por tanto, las funcionalidades ofrecidas por la aplicación serán entregadas según el perfil del usuario que acceda.</p>
        <p>En la aplicación se pondrá a disposición del CLIENTE información y/o se permitirá la realización de las transacciones determinadas o habilitadas por <strong>UNITEC USA</strong> para cada perfil en articular. <strong>UNITEC USA</strong> podrá adicionar, modificar o eliminar las funcionalidades en cualquier momento, esto incluye recibir periódicamente notificaciones, lo cual acepta el usuario.</p>
        <p>Los tiempos de respuesta, trámites y demás comportamientos efectuados por las transacciones realizadas por el usuario mediante la aplicación será procesadas de conformidad con las especificaciones de cada producto activo y a discreción de las políticas establecidas por <strong>UNITEC USA</strong></p>
        <p>El usuario acepta y autoriza que los registros electrónicos como resultado del uso de las funcionalidades del portal constituyen prueba de las transacciones solicitadas, y será responsable de estas.</p>
        <p>Funcionalidades de la aplicación</p>
        <ul>
          <li>Administración de comercios</li>
          <li>Venta de productos</li>
          <li>Administración de saldos en la red</li>
        </ul>
        <h2>REQUISITOS PARA USO</h2>
        <p>El usuario deberá contar con un dispositivo móvil inteligente (smartphone), tableta o equipo de computo, con acceso a internet, ambos seguros y confiables, UNITEC USA no será responsable por la seguridad de los equipos,  propiedad de los usuarios utilizados para el acceso al canal.</p>
        <h2>OBLIGACIONES DE LOS USUARIOS</h2>
        <p>El usuario se obliga a usar la aplicación o portal web  y/o  los contenidos encontrados en ella de una manera diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a continuación:</p>
        <ul>
          <li>Utilizar los contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público.</li>
          <li>Reproducir, copiar, representar, utilizar, distribuir, transformar o modificar los contenidos de la aplicación, por cualquier procedimiento o sobre cualquier soporte, total o parcial.</li>
          <li>Suprimir, eludir o manipular el derecho de autor y demás datos identificativos de los derechos de autor incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren tener los contenidos.</li>
          <li>Permitir que terceros ajenos a usted usen la aplicación movil o el portal web  con su clave.</li>
          <li>Utilizar la aplicación y los contenidos con fines contrarios a lo establecido en estos Términos y Condiciones.</li>
        </ul>
        <h2>USO DE INFORMACIÓN Y PRIVACIDAD</h2>
        <p>Aceptando los términos y condiciones el usuario autoriza a <strong>UNITEC USA</strong> a la utilización de sus datos en calidad de responsable del tratamiento de los mismos, los cuales pueden ser obtenidos y homologados por cualquier medio de acceso a la plataforma, WEB, APP, USSD, SMS, u otros que sean implementados, <strong>UNITEC USA</strong> informa que podrá ejercer sus derechos a conocer, actualizar, rectificar y suprimir su información personal; así como el derecho a revocar el consentimiento otorgado para el tratamiento de datos personales previstos en la ley 1581 de 2012 solicitando estos cambios al correo <a href="mailto:info@unitecusa.com.co">info@unitecusa.com.co</a></p>
        <p>Aceptando los términos y condiciones autoriza a <strong>UNITEC USA</strong> a la utilización de sus datos según las políticas de tratamientos de datos de la compañía, las cuales puede consultar a través de la página web <a href="www.unitecusa.com.co">www.unitecusa.com.co</a></p>
        <p><strong>UNITEC USA</strong> podrá dar a conocer, transferir y/o trasmitir sus datos personales dentro de su red, a cualquier empresa miembro del grupo <strong>UNITEC USA</strong>, así como a terceros a consecuencia de un contrato, ley o vínculo lícito que así lo requiera, para todo lo anterior da su autorización expresa e inequívoca.</p>
        <h2>CAMBIOS</h2>
        <p>Nos reservamos el derecho, a nuestra sola discreción, de modificar o reemplazar estos Términos en cualquier momento.</p>
        <h2>CONTÁCTENOS</h2>
        <p>Si tiene alguna pregunta sobre estos Términos, póngase en contacto con nosotros.</p>
        <p><a href="mailto:info@unitecusa.com.co">info@unitecusa.com.co</a></p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditions);
