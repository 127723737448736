import React from 'react';
import Nav from '../../molecules/Nav/Nav';
import PropTypes from 'prop-types';
import HeaderInfo from '../../molecules/HeaderInfo/HeaderInfo';

const Header = props => (
    <header className="o__Header">
        {/* <SearchBar type="searchButton" /> */}
        <HeaderInfo type="header" switchmodal={props.handleswitchmodal} />
        <Nav
            switchmodal={props.handleswitchmodal}
            showmodal={props.handleshowmodal}
            hidemodal={props.handlehidemodal} />
        
    </header>
)

Header.propTypes = {
    handleshowmodal: PropTypes.func,
    handlehidemodal: PropTypes.func,
    handleswitchmodal: PropTypes.func,
    modalType: PropTypes.string
}

export default Header;