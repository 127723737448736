import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as constants from "./../../../state/constants/appConstants";
import Button from "../../atoms/Button/Button";
import LoginServices from "../../../services/LoginServices/LoginServices";
import Token from "../../../common/LocalStorage/Token";
import { ToastContainer, toast, Slide } from "react-toastify";
import dataLayer from "../../../common/GTM/dataLayer";
import { Modal } from "react-bootstrap";
import facebook from "../../../assets/images/facebook.png";
import instagram from "../../../assets/images/instagram.png";
import twitter from "../../../assets/images/twitter.png";
import BtnClienteFinal from "../../../assets/images/coming-soon-Unitec-USA.gif";
import LogoBlanco from "../../../assets/images/logo_blanco.png";
/* import BtnDistribuidor from "../../../assets/images/pup-up-mayoristas.jpg"; */

const LoginTemplate = () => {
  let userInfo = JSON.parse(UserInfo.getUser());
  const history = useHistory();
  const { t } = useTranslation();
  const [usuarioError, setusuarioError] = useState(false);
  const [claveError, setclaveError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [modalStarted, setModalStarted] = useState(false);
  const textError = useRef(null);

  const defaultForm = {
    usuario: "",
    clave: "",
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateErrors()) {
      login();
    }
  };
  const validateErrors = () => {
    if (formData.usuario === "") {
      setusuarioError(true);
    } else {
      setusuarioError(false);
    }

    if (formData.clave === "" || formData.clave.length < 6) {
      setclaveError(true);
    } else {
      setclaveError(false);
    }

    return (
      formData.usuario === "" ||
      formData.clave === "" ||
      formData.clave.length < 6
    );
  };
  const reset = () => {
    setformData(defaultForm);
  };
  const handleClose = () => {
    setModalStarted(false);
  };
  const login = () => {
    setLoginLoader(true);
    setLoginError(false);
    LoginServices.login(formData)
      .then((res) => {
         console.log('  >>>>  ', res);
        if (res.status === 200) {
          if (res.data.estadoRespuesta === 0) {
            let usuario = { ...res.data.datosUsuario };
            usuario.document = formData.usuario;
            UserInfo.setUserInfo(JSON.stringify(usuario));
            Token.setToken(res.data.token);
            let date = new Date();
            date.setHours(date.getHours() - 5); //provistional for testing
            Token.setTimer(date.getTime().toString());
            reset();
            // handleHideModal();
            toast.success(
              "¡Unitec le da la bienvenida a nuestra tienda virtual!",
              {
                className: "toast-u-container",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                transition: Slide,
              }
            );
            setTimeout(() => {
              dataLayer.pushEvent(
                "IniciaSesion",
                "ClicIniciarSesion",
                "IngresarExitoso"
              );
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }, 1500);
            //   history.push("/");
          } else if (res.data.estadoRespuesta === 2) {
            setLoginError(true);
            textError.current.innerHTML = res.data.mensajeDeError;
          } else {
            console.warn("Response data: ", res.data);
          }
        } else {
          console.error("Response error: ", res);
        }
        setLoginLoader(false);
      })
      .catch((err) => console.error(err));
  };
  const handleOnChange = (node, event) => {
    node === "usuario"
      ? setformData({
          ...formData,
          usuario: event.target.value.replace(/ /g, ""),
        })
      : node === "clave"
      ? setformData({
          ...formData,
          clave: event.target.value.replace(/ /g, ""),
        })
      : setformData({ ...formData });
  };
  const [formData, setformData] = useState(defaultForm);
  useEffect(() => {
    if (userInfo) history.push("/");
  }, []);
  return (
    <div className="container-xxl" id="login">
      <div
        className="white-card-bkg"
        style={{ maxWidth: "490px", width: "100%" }}
      >
        <h1 style={{ textAlign: "left" }}>Login</h1>
        <article className="o__lightbox o__lblogincont">
          <div className="o__lightboxlogin">
            <div className="a__input-primary">
              <label>
                Ingresa usuario o contraseña
                <input
                  className={usuarioError ? "field-error" : ""}
                  type="text"
                  value={formData.usuario}
                  onChange={(e) => handleOnChange("usuario", e)}
                  placeholder="Usuario / Cédula / Nit"
                />
              </label>
            </div>

            <div className="a__input-primary">
              <label>
                <input
                  className={claveError ? "field-error" : ""}
                  type="password"
                  value={formData.clave}
                  onChange={(e) => handleOnChange("clave", e)}
                  placeholder="Contraseña"
                />
              </label>
            </div>

            {(usuarioError === true ||
              claveError === true ||
              loginError === true) && (
              <div className="center-content">
                <p className="msj-error" ref={textError}>
                  *{t("pages.checkout.step-2.verify-fields")}
                </p>
              </div>
            )}

            <div className="o__loginactions" style={{marginBottom: '40px'}}>
              <Link
                className="gtmIniciaSesionOlvidasteTuContrasena o__pwdrecoverylink"
                to="/recover-password"
                // onClick={() => handleHideModal()}
              >
                {t("pages.log-in.forget-password")}
              </Link>
              {constants.rolUsuario > 1 ? (
                <Link
                  className="gtmIniciaSesionEnvioDeContrasena o__pwdrecoverylink"
                  to="/recover-password"
                  // onClick={() => handleHideModal()}
                >
                  Envío de contraseña
                </Link>
              ) : (
                <Link
                  className="gtmIniciaSesionEnvioDeContrasena o__pwdrecoverylink"
                  to="/register"
                  //onClick={() => handleHideModal()}
                >
                  Quiero Registrarme
                </Link>
              )}
              <Button
                classnames="gtmIniciaSesionIngresar primary"
                text={t("pages.log-in.btn-enterokay")}
                type="link"
                href={`/`}
                onclick={(e) => handleSubmit(e)}
              ></Button>
            </div>
            {/* <div className="o__registeraction">
            <p> {t("pages.log-in.dont-have-account-yet")} </p>
            <Link className="gtmIniciaSesionRegistrate" to="/register" onClick={() => handleHideModal()}>
              {t("pages.log-in.sing-up")}
            </Link>
          </div> */}
          </div>

          <ToastContainer />
        </article>
      </div>
      <div className="txt-bienvenidos">
        <h2>Bienvenidos</h2>
      </div>
      <div className="logo-login">
          <img src={LogoBlanco} />
      </div>
      <Modal show={modalStarted} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton className="t__closeButton"></Modal.Header>
        <Modal.Body>
          <div className="row t__modal-container">
            <div className="col-12" style={{ textAlign: "center" }}>
              <div className="row">
                <div
                  className="col-sm-12 col-md-12"
                  style={{ padding: 0 }}
                  /* onClick={() => {
                    if (constants.rolUsuario === 2) {
                      window.location.href = 
                        "https://unitecusa.com.co/"; 
                    } else if(constants.rolUsuario === 1) {
                      setModalStarted(false);
                    }
                  }} */
                >
                  <img src={BtnClienteFinal} style={{ margin: "0px auto" }} />
                </div>
              </div>
            </div>
            <div className="col-12  t__modal-redes">
              <ul>
                <li>
                  <img
                    src={facebook}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/UnitecUsa/",
                        "_blanc"
                      )
                    }
                  />
                </li>
                <li>
                  <img
                    src={instagram}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/unitec_usa/",
                        "_blanc"
                      )
                    }
                  />
                </li>
                <li>
                  <img
                    src={twitter}
                    onClick={() =>
                      window.open("https://twitter.com/UsaUnitec/", "_blanc")
                    }
                  />
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginTemplate);
