import React from 'react';
import wppicon from './../../../assets/images/wppicon.png';
import { useTranslation } from "react-i18next";


const WppButton = () => {
    const { t } = useTranslation();
    return (
        <a className="wpp-button gtmHomeLatWhatsapp" target="_blank" href="http://api.whatsapp.com/send?phone=573174050346" rel="noopener noreferrer">
            <img className="wpp-button-img gtmHomeLatWhatsapp" src={wppicon} alt={"Whatsapp Unitec"} />
            {/*  <p className="wpp-button-text gtmHomeLatWhatsapp">
                {t("header.wpp-button-text")}
            </p> */}
        </a>
    )
}

export default WppButton; 