import * as constants from "./../../state/constants/appConstants";
var _userIfo = `userInfo_${constants.rolUsuario}`;
export class UserInfo {

  getUser() {
    return localStorage.getItem(_userIfo);
  }

  setUserInfo(data) {
    return localStorage.setItem(_userIfo, data);
  }

  deleteUser() {
    return localStorage.removeItem(_userIfo);
  }
}

export default new UserInfo();