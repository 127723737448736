import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const PersonalPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page-container">
      <h1> {t("pages.authorization-processing-personal-data.title")} </h1>
      <div
        dangerouslySetInnerHTML={{
          __html: t("pages.authorization-processing-personal-data.description")
        }}
      ></div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalPolicy);
