import CartServices from "../../services/Cart/CartServices";
import ProductServices from '../../services/Products/ProductServices';
import UserInfo from '../../common/LocalStorage/UserInfo';

import * as constants from "../../state/constants/appConstants";
export class Cart {
  chequearDiponibilidad = async (productID, cant) => {
    const userInfo = JSON.parse(UserInfo.getUser());
    var rolUsuario = constants.rolUsuario;
    let payload = {
      params: {
        idProducto: productID,
        idioma: 'es',
        rolUsuario,
        listaPrecio: (userInfo && userInfo.listaPrecio) ? userInfo.listaPrecio : 20,
      }
    };
    console.log(cant);
    let res = await ProductServices.getProductById(payload)
    console.log(res, res.detalleProducto.inventarioDisponible >= cant);
    return res.detalleProducto.inventarioDisponible >= cant;
  }
  randomID() {
    let name = ''
    let arr = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "u", "v", "w", "x", "y", "z", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    for (let index = 0; index < 10; index++) {
      let letter = Math.floor(Math.random() * (arr.length - 1) + 1);
      name += arr[letter];
    }
    return name;
  }
  /* addToCart(item) {
    let cart = null;
    //cart.productos = [];
    let addtocart = true;
    let payload = {
      idcarrito: null,
      idpro: item.idProducto,
      cantidad: 0
    }
    let __cart = localStorage.getItem(' ');
    // let cart = null;
    console.log(__cart)
    if (__cart != null && __cart.length > 2) {
      cart = JSON.parse(__cart);
    }
    if (cart) {
      if (cart.productos) {
        // console.log(cart)
        if (!cart.productos) cart.productos = cart;
        //return
        cart.productos.map((prod, index) => {
          if (prod.idProducto === item.idProducto) {
            cart.productos[index].cantidad = cart.productos[index].cantidad + item.cantidad;
            payload.cantidad = cart.productos[index].cantidad + item.cantidad;
            addtocart = false;
          }
          return true;
        });
      }
    } else {
      cart = {}
      cart.idcarrito = this.randomID();
      cart.productos = [];
    }
    if (addtocart === true) {
      cart.productos.push(item);
    }
    payload.idcarrito = cart.idcarrito;
    CartServices.send(payload).then(res => console.log(res));

    return this.setCart(JSON.stringify(cart));
  } */

  async addToCart(item) {
    let usr = JSON.parse(UserInfo.getUser()) || [];
    let cart = [];
    let addtocart = true;
    let payload = {
      idcarrito: null,
      idpro: item.idProducto,
      cantidad: 0,
      email: usr.email || null
    }
    //console.log(payload);
    if (this.getCart() != null) {
      cart = JSON.parse(this.getCart());

      // console.log(cart)
      cart.map((prod, index) => {
        if (index !== `${constants.rolUsuario}_u-cartf`) {
          if (prod.idProducto === item.idProducto) {
            cart[index].cantidad = parseInt(cart[index].cantidad) + parseInt(item.cantidad);
            payload.cantidad = cart[index].cantidad;
            addtocart = false;
          }
        }
        return true;
      });
    }
    if (await this.chequearDiponibilidad(item.idProducto, payload.cantidad) === false) {
      alert('Ya no hay más unidades disponibles de este producto en el inventario');
      return;
    }
    if (addtocart === true) {
      if (!this.getCartId()) this.setCartId()
      cart.push(item);
    }
    payload.idcarrito = this.getCartId();
    //console.log(payload);
    await CartServices.send(payload);
    return this.setCart(JSON.stringify(cart));
  }

  getCart() {
    return localStorage.getItem(`${constants.rolUsuario}_u-cartf`);
  }

  setCart(item) {
    return localStorage.setItem(`${constants.rolUsuario}_u-cartf`, item);
  }

  deleteCart() {
    localStorage.removeItem(`${constants.rolUsuario}_idCarrito`);
    return localStorage.removeItem(`${constants.rolUsuario}_u-cartf`);
  }
  getCartId() {
    return localStorage.getItem(`${constants.rolUsuario}_idCarrito`);
  }
  setCartId() {
    return localStorage.setItem(`${constants.rolUsuario}_idCarrito`, this.randomID());
  }
}

export default new Cart();