import React from 'react';
import { Link } from 'react-router-dom';
import Image from './../../atoms/Image/Image';
import PropTypes from 'prop-types';

const CategoryCard = props => {
    const { src, subcat, cat } = props;
    const slug = `/cat/${cat.codigo}/subcat/${subcat.codigo + subcat.slug}`;

    return (
        <Link to={{ pathname: slug }} className="m__categorycard">
            <Image src={src} type="image" />
            <p> {subcat.nombreSubcategoria} </p>
        </Link>
    )
}
CategoryCard.propTypes = {
    cat: PropTypes.object.isRequired,
    src: PropTypes.string.isRequired,
    subcat: PropTypes.object.isRequired
}
export default CategoryCard;