import { createActions } from 'redux-actions';

export const {
    getBannerProductsHome,
    bannerProductsHome,
    getProductsRecommendedHome,
    productsRecommendedHome,
    getProductsTrendingHome,
    productsTrendingHome
} = createActions(
    {
        'GET_BANNER_PRODUCTS_HOME': () => { }
    },
    'BANNER_PRODUCTS_HOME',
    'GET_PRODUCTS_RECOMMENDED_HOME',
    'PRODUCTS_RECOMMENDED_HOME',
    'GET_PRODUCTS_TRENDING_HOME',
    'PRODUCTS_TRENDING_HOME',
);
