import React from 'react';
import PropTypes from 'prop-types';

const Icon = props => (
    props.tagtype === 'span' ?
        <span onClick={props.onclick} id={props.id} className={props.className + " "+props.icon + " x" + props.size + ((props.gtm) ? " " + props.gtm : "")}>
            {props.totalCartNumber > 0 &&
                <div className="u-cart-number">{parseInt(props.totalCartNumber)}</div>
            }
        </span>
        :
        <i className={props.icon + " x" + props.size + ((props.gtm) ? " " + props.gtm : "")}></i>
)

Icon.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.string,
    tagtype: PropTypes.string,
    onclick: PropTypes.func
}

export default Icon;