import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../../assets/images/logo_unitec.png';
import PropTypes from 'prop-types';
import HeaderInfo from './../HeaderInfo/HeaderInfo';
import HeaderLinks from '../HeaderLinks/HeaderLinks';
import SearchBar from '../../molecules/SearchBar/SearchBar';

const Nav = props => {
    const { isNitCustomer, setNitCustomer } = props
    const [isMenuActive, setMenuActive] = useState(false);
    const SwitchModal = type => {
        props.switchmodal(type);
    }

    const openMenu = () => {
        //document.body.classList.add('freeze-page');
        setMenuActive(true);
    }

    const closeMenu = () => {
        //document.body.classList.remove('freeze-page');
        setMenuActive(false);
    }

    return (
        <>
            <div className={isMenuActive ? 'burguer-menu burguer-menu--active' : 'burguer-menu'}>
                <HeaderLinks setMenuView={setMenuActive} type="burguer" />
                <HeaderInfo type="burguer" isNitCustomer={isNitCustomer} setNitCustomer={setNitCustomer} />
            </div>
            <button className={isMenuActive ? 'header-burguer header-burguer--active' : 'header-burguer'} type='button' onClick={() => { isMenuActive ? closeMenu() : openMenu() }}>
                <span className={
                    isMenuActive
                        ? 'header-burguer__line header-burguer__line--active'
                        : 'header-burguer__line'
                }></span>
                <span className={
                    isMenuActive
                        ? 'header-burguer__line header-burguer__line--active'
                        : 'header-burguer__line'
                }></span>
                <span className={
                    isMenuActive
                        ? 'header-burguer__line header-burguer__line--active'
                        : 'header-burguer__line'
                }></span>
            </button>
            <nav className='m__Nav'>
                <Link to="/" className="gtmHomeSupLogo"><img  src={logo} alt='logo Unitec USA' /></Link>
                <SearchBar type="searchButton" />
                <HeaderLinks type="header" switchmodal={SwitchModal} />
            </nav>
        </>
    );
}

Nav.propTypes = {
    showmodal: PropTypes.func,
    hidemodal: PropTypes.func
}

export default Nav;