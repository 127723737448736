import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Stepper from "../../molecules/Stepper/Stepper";
import { useHistory } from "react-router-dom";
import Spacer from "../../atoms/Spacer/Spacer";
import InputPrimary from "../../atoms/InputPrimary/InputPrimary";
import Button from "../../atoms/Button/Button";
import OrderSummary from "../../molecules/OrderSummary/OrderSummary";
import NumberFormat from "react-number-format";
import Switch from "react-switch";
import CountryServices from "../../../services/CountryServices/CountryServices";
import CheckoutServices from "../../../services/CheckoutServices/CheckoutServices";
import PaymentServices from "../../../services/PaymentServices/PaymentServices";
import ProductServices from '../../../services/Products/ProductServices';
import { useTranslation } from "react-i18next";
import Loader from "../../atoms/Loader/Loader";
import Currency from '../../../common/LocalStorage/Currency';
import UserInfo from '../../../common/LocalStorage/UserInfo';
import Token from '../../../common/LocalStorage/Token';
import Cart from '../../../common/LocalStorage/Cart';
import CartRender from '../../molecules/Cart/Cart'
import dataLayer from "../../../common/GTM/dataLayer";
import * as constants from "./../../../state/constants/appConstants";
import preLoaderIcon from "../../../assets/images/preloader.gif";

const CheckoutTemplate = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';
  const [clientCodify, setClientCodify] = useState(false);
  const [currentStep, setCurrentStep] = useState(parseInt(props.match.params.step));
  const [defaultAmount, setDefaultAmount] = useState(1);
  const [creditChecked, setCreditChecked] = useState(false);
  const { rdc_interface } = props;
  const [totalPrice, setTotalP] = useState(0);
  const ivaPercent = 1.19;
  const defaultEnvio = {
    pedido: {
      COP: 0,
      USD: 0
    },
    envio: {
      COP: 0,
      USD: 0
    },
    faltanteParaEnvioGratis: {
      COP: 0,
      USD: 0
    }
  };
  const [calculoEnvio, setCalculoEnvio] = useState(defaultEnvio);
  const [order, setOrder] = useState(Cart.getCart() ? JSON.parse(Cart.getCart()) : []);
  const currency = Currency.getCurrency() ? Currency.getCurrency() : 'COP';
  const [creditValDefault, setCreditValDefault] = useState(0);;
  const userInfo = JSON.parse(UserInfo.getUser()) || [];

  //console.log(userInfo);
  const defaultFormData = {
    name: userInfo ? userInfo.nombre : "",
    surname: userInfo ? userInfo.apellido : "",
    email: userInfo ? userInfo.email : "",
    typedocument: userInfo ? userInfo.tipodocumento : "",
    identification: userInfo ? userInfo.document : "",
    celphone: userInfo ? userInfo.celular : "",
    country: userInfo ? userInfo.pais : "",
    department: userInfo ? userInfo.departamento : "",
    city: userInfo ? userInfo.ciudad : "",
    address: (userInfo) ? userInfo.direccionDeEnvio : "",
    observation: ""
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [listCity, setListCity] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);

  const [typeDocumentError, setTypeDocumentError] = useState(false);
  const [identificationError, setIdentificationError] = useState(false);
  const [celphoneError, setCelphoneError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [observationError, setObservationError] = useState(false);
  const [isErrorinForm, setIsErrorinForm] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [alertDoublePay, setAlertDoublePay] = useState(<></>);

  const setSummary = (shouldIncrement, value) => {
    if (value) {
      setDefaultAmount(value);
    } else {
      if (shouldIncrement) {
        setDefaultAmount(defaultAmount);
      } else {
        setDefaultAmount(defaultAmount);
      }
    }
  };
  useEffect(() => {
    if (rdc_interface.valorTotal === 0 && currentStep !== 1) {

      window.location = '/checkout/1';
    }
    console.log(rdc_interface)
  }, [currentStep, rdc_interface])

  const setTotalPrice = () => {
    if (currency === 'USD') {
      setTotalP(calculoEnvio.pedido.USD + calculoEnvio.envio.USD);
    } else {
      setTotalP(calculoEnvio.pedido.COP + calculoEnvio.envio.COP);
    }
  };
  const goNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
    window.scrollTo(0, 0);
    history.push(`/checkout/${currentStep + 1}`)
  };
  const totalPriceVSQuota = () => {
    //totalPrice vs totalPrice >= creditValDefault 
    //console.log(totalPrice >= creditValDefault, totalPrice, creditValDefault, calculoEnvio);

    if (totalPrice >= creditValDefault) {
      return false;
    } else {
      return true;
    }
  }
  const goBackStep = () => {
    if (currentStep > 2) {
      setCurrentStep(currentStep - 1);
      history.push(`/checkout/${currentStep - 1}`)
    } else {
      window.location = `/checkout/${currentStep - 1}`
    }
    window.scrollTo(0, 0);
  };

  const SwitchModal = (type) => {
    props.handleswitchmodal(type);
  };

  const handleCreditChange = () => {
    dataLayer.pushEvent("Pago", "ClicEnvio", "AplicarCupo");
    setCreditChecked(!creditChecked);
  };

  const notifyPaymentCredit = () => {
    if (!validateErrors()) {
      setLoadingCart(true);
      let descuento = 0;
      let cloneItems = [];
      order.map((item) => {
        cloneItems.push({ ...item });
      });

      let cartOrder = cloneItems.map((item) => {
        delete item.title
        delete item.srcImg
        item.precioUnitario = item.descuento > 0 ? item.descuento * item.iva : item.precioUnitario.COP;
        item.porcentaje_descuento = item.divisor > 0 ? rdc_interface.scaleSelected.descuento / item.divisor : 0;
        descuento += item.precioUnitario - item.descuento;
        return item
      });

      try {
        /*  const needed = ["idProducto", "cantidad", "precioUnitario"];
         const newObjs = order.map(o => {
           let tmp = {};
           for (let [key, val] of Object.entries(o)) {
             if (needed.includes(key)) {
               //console.log(key);
               tmp[key] = key === 'precioUnitario' ? val.COP : val;
             }
           }
           return tmp;
         }); */
        let payload = {
          pedido: cartOrder,
          datosEnvio: {
            pais: formData.country,
            departamento: formData.department,
            ciudad: formData.city,
            direccion: formData.address,
            observaciones: formData.observation
          },
          datosComprador: {
            nombre: formData.name,
            apellido: formData.surname,
            email: formData.email,
            tipoDocumento: formData.typedocument,
            numeroDocumento: formData.identification,
            numeroTelefonoMovil: formData.celphone,
          },
          moneda: currency,
          idUsuario: userInfo.id,
          direccion: formData.address,
          token: Token.getToken(),
          escala: rdc_interface.scaleSelected.idescala || 0,
          descuento: Math.floor(descuento) || 0,
          porcentaje_descuento: rdc_interface.scaleSelected.descuento,
        };

        console.log(payload);
        CheckoutServices.notifyPaymentCredit(payload).then(res => {
          // console.log(res);
          if (res.estadoRespuesta === 0) {
            //console.log("Peticion realizada correctamente", res);
            userInfo.cupo = res.cupoRestante;
            UserInfo.setUserInfo(JSON.stringify(userInfo));
            goNextStep();
          } else {
            SwitchModal('login-modal');
            UserInfo.deleteUser();
            Token.deleteToken();
            console.warn("Error en la respuesta", res);
          }
          setLoadingCart(false);
        }).catch(err => console.log(err));
      } catch (error) {
        console.error(error);
        setLoadingCart(false);
      }
    } else {
      window.scrollTo(0, 300);
      setIsErrorinForm(true);
    }
  };

  const handlePayment = async () => {
    if (validateErrors() === false) {
      setLoadingCart(true);
      let descuento = 0;
      let cloneItems = []
      try {
        order.map((item) => {
          cloneItems.push({ ...item });
        });

        let cartOrder = cloneItems.map((item) => {
          delete item.title
          delete item.srcImg
          item.precioUnitario = item.descuento > 0 ? item.descuento * item.iva : item.precioUnitario.COP;
          item.porcentaje_descuento = item.divisor > 0 ? rdc_interface.scaleSelected.descuento / item.divisor : 0;
          descuento += item.precioUnitario - item.descuento;
          return item
        });

        let payload = {
          pedido: cartOrder,
          datosEnvio: {
            pais: formData.country,
            departamento: formData.department,
            ciudad: formData.city,
            direccion: formData.address,
            observaciones: formData.observation
          },
          datosComprador: {
            nombre: formData.name,
            apellido: formData.surname,
            email: formData.email,
            tipoDocumento: formData.typedocument,
            numeroDocumento: formData.identification,
            numeroTelefonoMovil: formData.celphone,

          },
          moneda: currency,
          escala: rdc_interface.scaleSelected.idescala || 0,
          descuento: Math.floor(descuento) || 0,
          token: Token.getToken(),
          porcentaje_descuento: rdc_interface.scaleSelected.descuento,
        };

        if (creditChecked) {
          payload.idUsuario = userInfo.id;
          payload.valorAPagarConCupo = creditValDefault;
          console.log(payload);
          const result = await PaymentServices.processPaymentWithCredit(payload);
          console.log("Resultado Pago Mixto: ", result);
          if (result.estadoRespuesta === 0) {
            window.location = result.datosProcesoPago.urlProcesamiento;
          } else {
            console.warn("Error en la respuesta: ", result);
          }
        } else {
          // console.log(payload, ' :::::::::::::::>>>>>>>>>>>>><<<<<<<<<<<<<<<>>>');
          // return;
          const result = await PaymentServices.processPayment(payload);
          // console.log("Resultado pago placetopay: ", result);
          if (result.estadoRespuesta === 0) {
            window.location = result.datosProcesoPago.urlProcesamiento;
          } else if (result.estadoRespuesta === 2) {
            setAlertDoublePay(<p className="p_uni_alert">{`En estos momentos hay un pago pendiente con número de referencia: ${result.mensajeDeError} por favor intente más tarde`}</p>)
          } else {
            console.warn("Error en la respuesta: ", result);

          }
        }
        setLoadingCart(false);
      } catch (error) {
        console.error(error);
        setLoadingCart(false);
      }
    } else {
      window.scrollTo(0, 300);
      setIsErrorinForm(true);
    }
  };

  const getCalculateShipping = (city = null, address = null) => {
    setLoadingCart(true);
    let totalOrder = 0;
    const needed = [
      "idProducto",
      "cantidad",
      "precioUnitario",
      "srcImg",
      "title",
      "divisor",
      "porcentajeIva",
      "descuento",
      "IVA"
    ];
    /* const newObjs = order.map(o => {
      let tmp = {};
      for (let [key, val] of Object.entries(o)) {
        if (needed.includes(key)) {
          tmp[key] = val;
        }
      }
    }); */
    let payload = {
      ciudadEnvio: userInfo ? userInfo.ciudad : 1,
      direccion: userInfo ? userInfo.direccionDeEnvio : "",
      pedido: order,
      valorTotalPedido: rdc_interface.valorTotal
    };
    if (city !== null || address !== null) {
      payload.direccion = address ? address : formData.address;
      payload.ciudadEnvio = city ? city : formData.city;
    }
    CheckoutServices.calculateShipping(payload).then(res => {
      switch (res.estadoRespuesta) {
        case 0:
          setCalculoEnvio(res.costo);
          setTotalPrice();
          break;
        case 2:
          console.error("Error calculating shipping: ", res);
          break;

        default:
          console.log("Respuesta calculateShipping: ", res);
          break;
      }
      setLoadingCart(false);
    }).catch(err => console.log(err));
  }
  const loadDepartments = (country) => {
    let payload = {
      params: {
        idPais: country
      }
    };
    CountryServices.getListDepartment(payload).then(res => {
      setListDepartment(res.departamentos);
    }).catch(err => console.log(err));
  };

  const loadCity = (department) => {
    let payload = {
      params: {
        idPais: formData.country || userInfo.pais,
      }
    };
    CountryServices.getListCity(payload).then(res => {
      let cities = [];
      for (const city of res.ciudades) {
        if (city.codigoDepartamento === department) {
          cities.push(city)
        }
      }
      setListCity(cities);
    }).catch(err => console.log(err));
  };
  const loadTypeDocuments = (value) => {
    let payload = {
      params: {
        idPais: value,
      }
    };
    CountryServices.getListIdCountry(payload).then(res => {
      console.log(res);
      setDocumentTypes(res.tiposId);
    })
  }
  const regexByTypeId = () => {
    let obj = {
      regex: null,
      minLength: 0,
      maxLength: 0,
    }
    let i = parseInt();
    switch (formData.typedocument) {
      case 'CC':
        obj.regex = /^[1-9][0-9]{6,9}$/;
        obj.minLength = 6;
        obj.maxLength = 9;
        return obj;
      case '13':
        obj.regex = /^[1-9][0-9]{6,9}$/;
        obj.minLength = 6;
        obj.maxLength = 9;
        return obj;

      case 'NIT':
        obj.regex = /^[1-9]\d{6,9}$/;
        obj.minLength = 3;
        obj.maxLength = 7;
        return obj;
      case '31':
        obj.regex = /^[1-9]\d{6,9}$/;
        obj.minLength = 3;
        obj.maxLength = 7;
        return obj;


      case 'CEDULA EXTRANJERIA':
        obj.regex = /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/;
        obj.minLength = 4;
        obj.maxLength = 11;
        return obj;
      case '22':
        obj.regex = /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/;
        obj.minLength = 4;
        obj.maxLength = 11;
        return obj;

      default:
        return obj
        break;
    }
  }
  const validateErrors = () => {
    if (formData.name === "" || !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.name)) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (formData.surname === "" || !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.surname)) {
      setSurnameError(true);
    } else {
      setSurnameError(false);
    }

    if (formData.email === "" || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (formData.typedocument === "") {
      setTypeDocumentError(true);
    } else {
      setTypeDocumentError(false);
    }
    if (!userInfo) {
      if (formData.identification === "" || !regexByTypeId().regex.test(formData.identification)) {
        setIdentificationError(true);
      } else {
        setIdentificationError(false);
      }
    }

    if (formData.celphone === "") {
      setCelphoneError(true);
    } else {
      setCelphoneError(false);
    }

    if (formData.country === "") {
      setCountryError(true);
    } else {
      setCountryError(false);
    }

    if (formData.department === "") {
      setDepartmentError(true);
    } else {
      setDepartmentError(false);
    }

    if (formData.city === "") {
      setCityError(true);
    } else {
      setCityError(false);
    }

    if (formData.address === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    /*  if (formData.observation === "") {
       setObservationError(true);
     } else {
       setObservationError(false);
     } */

    return (
      (formData.name === "") || (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.name)) ||
      (formData.surname === "") || (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.surname)) ||
      (formData.email === "") || (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) ||
      (formData.typedocument === "") ||
      (formData.identification === "") || (!/^[1-9][0-9]{3,9}$/.test(formData.identification)) ||
      (formData.celphone === "") ||
      (formData.country === "") ||
      (formData.department === "") ||
      (formData.city === "") ||
      (formData.address === "") /* ||
      (formData.observation === "") */
    );
  };
  const eliminateLastChart = (text) => {
    let obj = {
      rest: text.slice(0, -1),
      last: text.slice(-1)
    }
    return obj;
  }
  const flickerError = (method) => {
    method(true);
    setTimeout(() => {
      method(false)
    }, 1000);
  }
  /* Eliminate no numerics charts */
  const purgueNumerics = (text) => {
    var r = /\d+/g;
    var m;
    let ret = "";
    while ((m = r.exec(text)) != null) {
      ret += m[0];
    }
    return ret;
  }
  const isNumeric = (text) => {
    return /^[0-9]\d*$/.test(text);
  }
  const handleOnChange = (node, event) => {
    switch (node) {
      case "name":
        setFormData({ ...formData, name: event.target.value });
        if (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(event.target.value)) {
          setNameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({ ...formData, name: w.rest });
        } else {
          setNameError(false);
        }
        break;
      case "surname":
        setFormData({ ...formData, surname: event.target.value });
        if (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(event.target.value)) {
          setSurnameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({ ...formData, surname: w.rest });
        } else {
          setSurnameError(false);
        }
        break;
      case "email":
        setFormData({ ...formData, email: event.target.value });
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
          setEmailError(true);

        } else {
          setEmailError(false);
        }
        break;
      case "typedocument":
        setFormData({ ...formData, typedocument: event.target.value });
        setTypeDocumentError(false)
        break;
      case "identification":
        if (formData.typedocument) {
          let obj = regexByTypeId();
          let textPurgued = purgueNumerics(event.target.value);
          //return
          if (!obj.regex.test(event.target.value)) {
            let w = eliminateLastChart(event.target.value);
            setFormData({ ...formData, identification: (textPurgued && obj.maxLength >= event.target.value.length) ? textPurgued : w.rest });
            if (!isNumeric(w.last)) {
              flickerError(setIdentificationError);
            }
          } else {
            setFormData({ ...formData, identification: event.target.value });
            setIdentificationError(false)
          }
        } else {
          setTypeDocumentError(true)
        }
        break;
      case "celphone":
        setFormData({ ...formData, celphone: event.target.value });
        break;
      case "country":
        setFormData({ ...formData, country: event.target.value });
        loadDepartments(event.target.value);
        loadTypeDocuments(event.target.value);
        break;
      case "department":
        setFormData({ ...formData, department: event.target.value.replace(/ /g, " ") });
        loadCity(event.target.value);
        break;
      case "city":
        setFormData({ ...formData, city: event.target.value });
        getCalculateShipping(event.target.value, null);
        break;
      case "address":
        setFormData({ ...formData, address: event.target.value.replace(/ /g, " ") });
        break;
      case "observation":
        setFormData({ ...formData, observation: event.target.value.replace(/ /g, " ") });
        break;
      default:
        setFormData({ ...formData });
        break;
    }
  };

  const handleAddressBlur = event => {
    getCalculateShipping(null, event.target.value);
  };

  const handleLastStep = event => {
    Cart.deleteCart();
    history.push("/");
    window.location.reload();
  };

  useEffect(() => {
    const loadCountry = () => {
      let payload = {
        params: {
          idioma: getCurrentLng()
        }
      };
      CountryServices.getListCountry(payload).then(res => {
        setListCountry(res.paises);
        if (res.paises.length === 1) {
          setFormData({ ...formData, country: res.paises[0].codigo });
          loadDepartments(res.paises[0].codigo);
        }
      }).catch(err => console.log(err));
    };
    loadCountry();
    if (userInfo.pais) {
      loadDepartments(userInfo.pais);
      loadCity(userInfo.departamento);
      loadTypeDocuments(userInfo.pais)
    }
  }, []);

  useEffect(() => {
    let userInfo = JSON.parse(UserInfo.getUser());
    let order = Cart.getCart() ? JSON.parse(Cart.getCart()) : [];
    /*   if (userInfo) {
        setCreditValDefault(userInfo.cupo);
      } */
    if (Token.getToken()) {
      if (userInfo.rol === "2" || userInfo.rol === 2) {
        setClientCodify(true);
      }
    }
    if (order.length > 0 && userInfo && rdc_interface.valorTotal > 0) {
      getCalculateShipping();
    }
  }, [rdc_interface.valorTotal]);

  useEffect(() => {
    const getBalanace = async () => {
      let payload = {
        nit: userInfo?.document
      }
      let res = await CheckoutServices.getBalance(payload);
      setCreditValDefault(res.cupodisponible)
    }
    if (!creditValDefault && userInfo?.document) {
      getBalanace();
    }
  }, [])
  return (
    <div className="container">
      {loadingCart === true &&
        <Loader />
      }
      {(order.length > 0) ?
        <>
          <Spacer px={35} />
          <h1>{t("pages.checkout.title")}</h1>
          <Spacer px={10} hideon="small" />
          <Stepper
            steps={[
              { key: 1, title: "", icon: "icon-shopping-cart" },
              { key: 2, title: "", icon: "icon-truck" },
              { key: 3, title: "", icon: "icon-credit-card" }
            ]}
            activeStep={currentStep}
          />
          <Spacer px={10} hideon="small" />
          {currentStep === 1 ? (
            <section id="listProductsCheckOut">
              <h2>{t("pages.checkout.step-1.title")}</h2>
              <CartRender />
              {/* <OrderSummary
                discountPromptPayment={(calculoEnvio.valorDescuentoProntoPago && clientCodify) ? (currency === 'USD' ? calculoEnvio.valorDescuentoProntoPago.USD : calculoEnvio.valorDescuentoProntoPago.COP) : 0}
                partialPrice={(currency === 'USD' ? calculoEnvio.pedido.USD : calculoEnvio.pedido.COP) / ivaPercent}
                shippingPrice={calculoEnvio.faltanteParaEnvioGratis.COP > 0 ? + (currency === 'USD' ? calculoEnvio.envio.USD : calculoEnvio.envio.COP) : 0}
                iva={(currency === 'USD' ? calculoEnvio.pedido.USD : calculoEnvio.pedido.COP) - ((currency === 'USD' ? calculoEnvio.pedido.USD : calculoEnvio.pedido.COP) / ivaPercent)}
                currency={currency}
              /> */}
              {rdc_interface.valorTotal > 0 &&
                <div className="t__checkout-btns">
                  <Button
                    classnames="primary t__right-btn gtmPagoCarritoConfirmar"
                    text={t("pages.checkout.step-1.btn-confirm")}
                    type="button"
                    onclick={() => goNextStep()}
                  ></Button>
                </div>
              }
              <Spacer px={50} hideon="small" />
            </section>
          ) : currentStep === 2 ? (
            <section>
              <div className="white-card-bkg">
                <div className="max-width-800">
                  <h2>{t("pages.checkout.step-2.title")}</h2>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.name")}
                        type={"text"}
                        value={formData.name}
                        onchange={(e) => handleOnChange("name", e)}
                        placeholder={t("pages.checkout.step-2.name-placeholder")}
                        isUserError={nameError}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.surname")}
                        type={"text"}
                        value={formData.surname}
                        onchange={(e) => handleOnChange("surname", e)}
                        placeholder={t("pages.checkout.step-2.surname-placeholder")}
                        isUserError={surnameError}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.email")}
                        type={"text"}
                        value={formData.email}
                        onchange={(e) => handleOnChange("email", e)}
                        placeholder={t("pages.checkout.step-2.email-placeholder")}
                        isUserError={emailError}
                      />
                    </div>
                    <div className="col-sm-6 col-12 ">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.country")} </span>
                        <select
                          className={countryError ? `field-error` : undefined}
                          defaultValue={userInfo.length > 0 ? userInfo.pais : "DEFAULT"}
                          onChange={e => {
                            handleOnChange('country', e);
                          }} >
                          <option value={"DEFAULT"} disabled>
                            {t("pages.checkout.step-2.country-placeholder")}
                          </option>
                          {listCountry.map((country, index) => (
                            <option key={index} value={country.codigo}>
                              {country.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 ">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.department")} </span>
                        <select
                          className={departmentError ? `field-error` : undefined}
                          onChange={e => {
                            handleOnChange('department', e);
                          }}
                          defaultValue={userInfo.length > 0 ? userInfo.departamento : "DEFAULT"}
                          disabled={listDepartment.length === 0}>
                          <option value={"DEFAULT"} disabled>
                            {t("pages.checkout.step-2.department-placeholder")}
                          </option>
                          {listDepartment.map((department, index) => (
                            <option key={index} value={department.codigo}>
                              {department.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 ">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.city")} </span>
                        <select
                          className={cityError ? `field-error` : undefined}
                          defaultValue={userInfo.length > 0 ? userInfo.ciudad : "DEFAULT"}
                          onChange={e => {
                            handleOnChange('city', e);
                          }}
                          disabled={listCity.length === 0}>
                          <option value="DEFAULT" disabled>
                            {t("pages.checkout.step-2.city-placeholder")}
                          </option>
                          {listCity.map((city, index) => (
                            <option key={index} value={city.id}>
                              {city.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.document-type")} </span>
                        <select
                          className={typeDocumentError ? `field-error` : undefined}
                          defaultValue={userInfo.length > 0 ? userInfo.tipodocumento : "DEFAULT"}
                          onChange={e => {
                            handleOnChange('typedocument', e);
                          }}
                        >
                          <option value={"DEFAULT"} disabled>
                            {t("pages.checkout.step-2.document-type-placeholder")}
                          </option>
                          {documentTypes.map((type, index) => (
                            <option key={index} value={type.codigo}>
                              {type.descripcion}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.identification")}
                        type={"text"}
                        value={formData.identification}
                        onchange={(e) => handleOnChange("identification", e)}
                        placeholder={t("pages.checkout.step-2.identification-placeholder")}
                        isUserError={identificationError}
                        disabled={formData.typedocument ? false : true}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.celphone")}
                        type={"text"}
                        value={formData.celphone}
                        onchange={(e) => handleOnChange("celphone", e)}
                        placeholder={t("pages.checkout.step-2.celphone-placeholder")}
                        isUserError={celphoneError}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.address")}
                        type={"text"}
                        value={formData.address}
                        onchange={(e) => handleOnChange("address", e)}
                        placeholder={t("pages.checkout.step-2.address-placeholder")}
                        disabled={formData.city === ""}
                        onblur={(e) => handleAddressBlur(e)}
                        isUserError={addressError}
                      />
                    </div>
                    <div className="col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.observation")}
                        type={"text"}
                        value={formData.observation}
                        onchange={(e) => handleOnChange("observation", e)}
                        placeholder={t("pages.checkout.step-2.observation-placeholder")}
                        isUserError={observationError}
                      />
                    </div>
                  </div>
                  {isErrorinForm &&
                    <div>
                      <p className='msj-error'>*{t("pages.checkout.step-2.verify-fields")}</p>
                    </div>
                  }
                </div>
                <Spacer px={20} />
              </div>
              <Spacer px={10} />
              {alertDoublePay}
              <Spacer px={20} />
              {clientCodify ? (
                <div className="bottom-cont">
                  <div className="white-card-bkg bottom-cont__discunt">
                    <h3>{t("pages.checkout.step-2.available-credit")}</h3>
                    <div
                      className={
                        creditChecked
                          ? "txt-wrapper txt-wrapper--active"
                          : "txt-wrapper"
                      }
                    >
                      <div>
                        <p>
                          {(currency === 'COP') ?
                            <NumberFormat
                              value={creditChecked ? totalPrice >= creditValDefault ? 0
                                : creditValDefault - totalPrice
                                : creditValDefault
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            /> :
                            <NumberFormat
                              value={creditChecked ? totalPrice >= creditValDefault ? 0
                                : creditValDefault - totalPrice
                                : creditValDefault
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          }
                        </p>
                      </div>
                      <div className="flex-wrapper">
                        <p>{t("pages.checkout.step-2.apply-discount")}</p>
                        <Switch
                          checked={creditChecked}
                          onChange={handleCreditChange}
                          onColor="#86d3ff"
                          onHandleColor="#00B3F8"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                  <OrderSummary
                    discountPromptPayment={(calculoEnvio.valorDescuentoProntoPago && clientCodify) ? (currency === 'USD' ? calculoEnvio.valorDescuentoProntoPago.USD : calculoEnvio.valorDescuentoProntoPago.COP) : 0}
                    partialPrice={(currency === 'USD' ? calculoEnvio.pedido.USD : calculoEnvio.pedido.COP) / ivaPercent}
                    shippingPrice={calculoEnvio.faltanteParaEnvioGratis.COP > 0 ? + (currency === 'USD' ? calculoEnvio.envio.USD : calculoEnvio.envio.COP) : 0}
                    setTotalPrice={setTotalPrice}
                    totalPrice={totalPrice}
                    isOff={creditChecked}
                    creditValue={creditValDefault}
                    iva={rdc_interface.totalIVA}
                    currency={currency}
                  />
                </div>
              ) : (
                <>
                  {formData.city > 0 ?
                    <OrderSummary
                      discountPromptPayment={(calculoEnvio.valorDescuentoProntoPago && clientCodify) ? (currency === 'USD' ? calculoEnvio.valorDescuentoProntoPago.USD : calculoEnvio.valorDescuentoProntoPago.COP) : 0}
                      partialPrice={(currency === 'USD' ? calculoEnvio.pedido.USD : calculoEnvio.pedido.COP) / ivaPercent}
                      shippingPrice={calculoEnvio.faltanteParaEnvioGratis.COP > 0 ? + (currency === 'USD' ? calculoEnvio.envio.USD : calculoEnvio.envio.COP) : 0}
                      iva={rdc_interface.totalIVA}
                      currency={currency}
                    />
                    :
                    <div>
                      <p>Por favor complete los datos de envío para calcular los totales</p>
                    </div>
                  }
                </>
              )}
              <div className="t__checkout-btns">
                <Button
                  classnames="secondary gtmPagoEnvioAtras"
                  text={t("pages.checkout.step-2.btn-back")}
                  type="button"
                  onclick={() => goBackStep()}
                ></Button>
                {clientCodify && (
                  <Button
                    classnames="secondary gtmPagoEnvioPagarCredito"
                    text={t("pages.checkout.step-2.btn-pay-credit")}
                    type="button"
                    onclick={() => notifyPaymentCredit()}
                    disabled={((creditValDefault <= ((currency === 'USD' ? calculoEnvio.pedido.USD + calculoEnvio.envio.USD : calculoEnvio.pedido.COP + calculoEnvio.envio.COP))) || !creditChecked)}
                  ></Button>
                )}
                <div className="t__logo-place-to-pay" >
                  <a href="https://www.placetopay.com/" target="_blank" ><img src='https://static.placetopay.com/placetopay-logo.svg' /> </a>
                  <a href="/palce-to-pay-faqs" target="_self" >Placetopay - Preguntas frecuentes  </a>
                </div>
                {/*  <div className="t__checkout-btns-container">
                  <Button
                    classnames="primary gtmPagoEnvioPagarConPlacetopay"
                    text="Solicitud de crédito EPM"
                    type="button"
                    onclick={() => window.location = '/credito-epm'}
                    disabled={(creditChecked && creditValDefault >= ((currency === 'USD' ? calculoEnvio.pedido.USD + calculoEnvio.envio.USD : calculoEnvio.pedido.COP + calculoEnvio.envio.COP)))}
                  ></Button>
                </div> */}
                {/* {((creditChecked && !totalPriceVSQuota()) || !userInfo) && */}
                {rdc_interface.scaleSelected &&
                  <div className="t__checkout-btns-container">
                    <Button
                      classnames="primary gtmPagoEnvioPagarConPlacetopay"
                      text={t("pages.checkout.step-2.btn-placetopay")}
                      type="button"
                      onclick={() => handlePayment()}
                      disabled={(creditChecked && creditValDefault >= calculoEnvio.pedido.COP + calculoEnvio.envio.COP)}
                    ></Button>
                  </div>
                }
              </div>
              <Spacer px={50} />
            </section>
          ) : (
            <section>
              <h2>{t("pages.checkout.step-3.title")}</h2>
              <p>{t("pages.checkout.step-3.description-pay")}</p>
              <Spacer px={30} />
              <div className="white-card-bkg">
                <div className="max-width-800">
                  <div className="t__purchase">
                    <div className="t__purchase-item">
                      <span>{t("pages.checkout.step-3.transaction-status")} </span>
                      <p>{(getCurrentLng() === 'en') ? 'Sent and in process' : 'Enviado y en proceso'}</p>
                    </div>
                    <div className="t__purchase-item">
                      <span>{t("pages.checkout.step-3.name")} </span> <p>{userInfo.nombre}</p>
                    </div>
                    <div className="t__purchase-item">
                      <span>{t("pages.checkout.step-3.email")} </span>
                      <p>{userInfo.email}</p>
                    </div>
                    <div className="t__purchase-item">
                      <span>{t("pages.checkout.step-3.quota-left")} </span> <p>{userInfo.cupo}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer px={50} />
              <div className="t__checkout-btns">
                <Button
                  classnames="primary t__center-btn"
                  text={t("pages.checkout.step-3.btn-accept")}
                  type="button"
                  onclick={() => handleLastStep()}
                ></Button>
              </div>
              <Spacer px={50} />
            </section>
          )}

          <Spacer px={50} />
        </>
        : <h3 className="checkout-no-products">{t("pages.shopping-cart.no-products")}</h3>
      }
    </div >
  );

};

const mapStateToProps = ({
  rdc_interface,
  rdc_cart
}) => ({
  rdc_interface
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTemplate);
