import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PaymentServices from "../../../services/PaymentServices/PaymentServices";
import ProductServices from '../../../services/Products/ProductServices';
import Spacer from "../../atoms/Spacer/Spacer";
import FormatNumber from "../../../utils/FormatNumber";
import UserInfo from '../../../common/LocalStorage/UserInfo';
import Loader from "../../atoms/Loader/Loader";


import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal } from "react-bootstrap";
import * as constants from "./../../../state/constants/appConstants";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const PaymentHistory = () => {
  //const { t } = useTranslation();
  const classes = useStyles();
  const [payments, setPayments] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [modalStarted, setModalStarted] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const userInfo = JSON.parse(UserInfo.getUser());
  const [loading, setLoading] = useState(false);


  const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || 'es';

  useEffect(() => {
    const getPaymentHistory = async () => {
      let payload = {
        params: {
          idUsuario: userInfo ? userInfo.id : null,
          idioma: getCurrentLng(),
          pagina: 1
        }
      };
      console.log(':::::::::::::::::::::::::::::::::::::::>>>>>>>>> ', payload);
      const result = await PaymentServices.getPaymentHistory(payload);
      if (result.estadoRespuesta === 0) {
        console.log("Payment history:", result);
        setPayments(result.pagos);
      } else {
        console.warn("Error products home: ", result);
      }
      let payloadOrderDetail = {
        usuario: userInfo.document || null
      };
      const detail = await PaymentServices.getOrderDetail(payloadOrderDetail);
      setPedidos(detail.pedidos);
      console.log(detail);
    };

    getPaymentHistory();
  }, []);
  const handleClose = () => {
    setModalStarted(false);
  }
  const getOrder = async (pedido) => {
    setOrderDetails([]);
    let arr = [];
    setLoading(true);
    for (let index = 0; index < pedidos.length; index++) {
      const element = pedidos[index];
      if (element.numero === pedido) {
        let newElement = { ...element };
        let obj = await getProductByID(element.producto);
        newElement.productName = obj.name;
        newElement.url = obj.url;
        arr.push(newElement);
      }
    }
    setOrderDetails(arr);
    setLoading(false);
    setModalStarted(true);

  }
  const getProductByID = async (id) => {
    let payload = {
      params: {
        idProducto: id,
        idioma: 'es',
        rolUsuario: constants.rolUsuario,
        listaPrecio: userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
      }
    };
    //  console.log(cant);
    let res = await ProductServices.getProductById(payload);
    console.log(res);
    let obj = {
      url: `/product${res.detalleProducto.slug}/${res.detalleProducto.id}`,
      name: res.detalleProducto.nombre
    }
    return obj;
  }
  const existPedido = (pedido) => {
    for (let index = 0; index < pedidos.length; index++) {
      const element = pedidos[index];
      if (element.numero === pedido) {
        return true;
      }
    }
    return false;
  }
  return (
    <section className="u-payment-history">
      <Spacer px={50} hideon="small" />
      {loading === true &&
        <Loader />
      }
      <div className="container">
        <Spacer px={20} hideon="small" />
        <h1>Historial de pedidos</h1>
        <Spacer px={20} hideon="small" />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>N° Pedido</TableCell>
                <TableCell align="center">Ref Pedido</TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Hora</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments && payments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {existPedido(payment.idPedido) ?
                      <h6 onClick={() => getOrder(payment.idPedido)} >{payment.idPedido}</h6>
                      :
                      payment.idPedido
                    }
                  </TableCell>
                  <TableCell align="center"><a href={`/pago-procesado/${payment.referenciaDePago}`}>{payment.referenciaDePago}</a></TableCell>
                  <TableCell align="center">{payment.fechaPedido}</TableCell>
                  <TableCell align="center">{payment.horaPedido}</TableCell>
                  <TableCell align="center">{`$${FormatNumber(payment.valorPedido)}`}</TableCell>
                  <TableCell align="center">{payment.tipo}</TableCell>
                  <TableCell align="center">{payment.estado}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Spacer px={50} hideon="small" />
      </div>
      <Modal show={modalStarted} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton className="t__closeButton">
        </Modal.Header>
        <Modal.Body>
          <div className="row t__modal-container">
            <div className="col-12" style={{ textAlign: 'center' }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Fecha</TableCell>
                      <TableCell align="center">Hora</TableCell>
                      <TableCell align="center">Producto</TableCell>
                      <TableCell align="center">Cantidad</TableCell>
                      <TableCell align="center">Tipo</TableCell>
                      <TableCell align="center">Valor Unidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails.map((element, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{element.fecha}</TableCell>
                        <TableCell align="center">{element.hora}</TableCell>
                        <TableCell align="center"><a href={element.url} target="_blank">{element.productName}</a></TableCell>
                        <TableCell align="center">{element.cantidad}</TableCell>
                        <TableCell align="center">{element.tipo}</TableCell>
                        <TableCell align="center">{`$${FormatNumber(element.valor)}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section >
  );
};

export default PaymentHistory;
