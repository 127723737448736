import React from "react";
import PropTypes from "prop-types";

const InputPrimary = props => {
  const {
    type,
    label,
    placeholder,
    options,
    onchange,
    onblur,
    isUserError,
    value,
    element,
    handleOnChange,
    id,
    disabled
  } = props;
  return type === "select" ? (
    <div className="a__input-primary a__select-primary">
      <span> {label} </span>
      <select
        className={isUserError ? `field-error` : undefined}
        defaultValue={"DEFAULT"}
        onChange={e => {
          handleOnChange(element, e);
        }}
        disabled={disabled}>
        <option value="DEFAULT" disabled>
          {placeholder}
        </option> 
        {options.map((element, index) => (
          <option key={index} value={element.codigo}>
            {element.nombre}
          </option>
        ))}
      </select>
    </div>

  ) : type === "textarea" ? (
    <div className="a__input-primary">
      <span> {label} </span>
      <textarea
        className={isUserError ? `field-error` : undefined}
        name=""
        id={id}
        cols="30"
        rows="10"
        placeholder={placeholder}
        onChange={onchange}
      ></textarea>
    </div>
  ) : type === "number" ? (
    <div className="a__input-primary">
      <span> {label} </span>
      <input
        className={"p__inputText " + (isUserError ? `field-error` : undefined)}
        placeholder={placeholder}
        type={type}
        value={value}
        // onBlur={ onblur }
        onChange={onchange}
      />
    </div>
  ) : (
          <div className="a__input-primary">
            <span> {label} </span>
            <input
              className={isUserError ? `field-error` : undefined}
              placeholder={placeholder}
              type={type}
              value={value}
              onBlur={onblur}
              onChange={onchange}
              disabled={disabled}
            />
          </div>
        );
};

InputPrimary.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array,
  onblur: PropTypes.func,
  onchange: PropTypes.func,
  isUserError: PropTypes.bool,
  value: PropTypes.string
};

export default InputPrimary;
