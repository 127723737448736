import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class CategoryServices {
  static getCategories(payload) {
    return axios.get(endpoints.getCategories, payload).then(res => res.data);
  }
  static getCategory(payload) {
    return axios.get(endpoints.getCategories, payload).then(res => res.data);
  }
  static getSubcategory(payload) {
    return axios.get(endpoints.getCategories, payload).then(res => res.data);
  }
}

export default CategoryServices;