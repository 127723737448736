const FormatNumber = (num) => {
    num += '';
    var parsenum = num.split('.');
    var whole_number = parsenum[0];
    var decimal = parsenum.length > 1 ? '.' + parsenum[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while(rgx.test(whole_number)) {
      whole_number = whole_number.replace(rgx, '$1.$2')
    }
    return whole_number+decimal
  }
  
  export default FormatNumber