import React, { useEffect } from "react";
import { connect } from "react-redux";
import Spacer from "./../atoms/Spacer/Spacer";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content-page-container">
      <h1> {t("pages.frequent-questions.title")}</h1>

      <h3>{t("pages.frequent-questions.questions-1")}</h3>
      <p>{t("pages.frequent-questions.answer-1")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-2")}</h3>
      <p>{t("pages.frequent-questions.answer-2")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-3")}</h3>
      <p>{t("pages.frequent-questions.answer-3")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-4")}</h3>
      <p>{t("pages.frequent-questions.answer-4")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-5")}</h3>
      <p>{t("pages.frequent-questions.answer-5")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-6")}</h3>
      <p>{t("pages.frequent-questions.answer-6")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-7")}</h3>
      <p>{t("pages.frequent-questions.answer-7")}</p>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-8")}</h3>

      <div
        dangerouslySetInnerHTML={{
          __html: t("pages.frequent-questions.answer-8")
        }}
      ></div>
      <Spacer px={5} />

      <h3>{t("pages.frequent-questions.questions-9")}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t("pages.frequent-questions.answer-9")
        }}
      ></div>
      <Spacer px={5} />
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
