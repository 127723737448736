import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";

const PlaceToPayFaqs = props => {
  const { t } = useTranslation();
  const paymentInsRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const { state } = props.location;

  useEffect(() => {
    if (typeof state != "undefined") {
      switch (state.scrollTo) {
        case 'payment-instructions':
          scrollToRef(paymentInsRef);
          break;
        default:
          window.scrollTo(0, 0);
          break;
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [state]);

  return (
    <section className="p__foreignSales">
      <div className="container">
        <Button
          classnames="gtmCarritoDeCompraMonedaConfirmar primary"
          text="Volver"
          type="link"
          href={`/checkout/2`}
        ></Button>
        <h2>PREGUNTAS FRECUENTES SOBRE PAGOS ELECTRÓNICOS</h2>
        <p>En este documento, usted encontrará una sección de preguntas y respuestas que le ayudará a sus usuarios a aclarar sus dudas sobre los pagos electrónicos procesados a través de Placetopay.  Recuerde que debe incluirlas en su sitio web. </p>
        <ol>
          <li><strong>&iquest;Qu&eacute; es Placetopay?</strong>
            <p>Placetopay es la plataforma de pagos electr&oacute;nicos que usa UNITEC U.S.A para procesar en l&iacute;nea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.</p>
          </li>
          <li><strong>&iquest;C&oacute;mo puedo pagar?</strong>
            <p>En la tienda virtual de <strong>UNITEC U.S.A</strong> usted podr&aacute; realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo a las opciones de pago escogidas por el comercio, podr&aacute; pagar a trav&eacute;s de tarjetas de cr&eacute;dito Visa, MasterCard, American Express, Credencial y Diners.</p>
          </li>
          <li><strong>&iquest;Es seguro ingresar mis datos bancarios en este sitio web?</strong>
            <p>Para proteger tus datos<strong>&ensp;UNITEC U.S.A</strong> delega en Placetopay la captura de la informaci&oacute;n sensible. Nuestra plataforma de pagos cumple con los m&aacute;s altos est&aacute;ndares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de cr&eacute;dito. Adem&aacute;s tiene certificado de seguridad SSL expedido por GeoTrust una compa&ntilde;&iacute;a Verisign, el cual garantiza comunicaciones seguras mediante la encriptaci&oacute;n de todos los datos hacia y desde el sitio; de esta manera te podr&aacute;s sentir seguro a la hora de ingresar la informaci&oacute;n de su tarjeta. <br /><br /> Durante el proceso de pago, en el navegador se muestra el nombre de la organizaci&oacute;n autenticada, la autoridad que lo certifica y la barra de direcci&oacute;n cambia a color verde. Estas caracter&iacute;sticas son visibles de inmediato y dan garant&iacute;a y confianza para completar la transacci&oacute;n en Placetopay. <br /><br /> Placetopay tambi&eacute;n cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electr&oacute;nicos con Certic&aacute;mara.</p>
          </li>
          <li><strong>&iquest;Puedo realizar el pago cualquier d&iacute;a y a cualquier hora?</strong>
            <p>S&iacute;, en <strong>UNITEC U.S.A</strong> podr&aacute;s realizar tus compras en l&iacute;nea los 7 d&iacute;as de la semana, las 24 horas del d&iacute;a a s&oacute;lo un clic de distancia.</p>
          </li>
          <li><strong>&iquest;Puedo cambiar la forma de pago?</strong>
            <p>Si a&uacute;n no has finalizado tu pago, podr&aacute;s volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago. <br /><br /> <strong>ESTABLECIMIENTO DE COMERCIO</strong>: el punto anterior aplica a la forma de pago, pero deber&aacute;n mencionar las pol&iacute;ticas de devoluci&oacute;n que tenga la tienda para dar cumplimiento al art&iacute;culo 51 de la Ley del Estatuto del Consumidor.</p>
          </li>
          <li><strong>&iquest;Pagar electr&oacute;nicamente tiene alg&uacute;n valor para m&iacute; como comprador?</strong>
            <p>No, los pagos electr&oacute;nicos realizados a trav&eacute;s de Placetopay no generan costos adicionales para el comprador.</p>
          </li>
          <li><strong>&iquest;Qu&eacute; debo hacer si mi transacci&oacute;n no concluy&oacute;?</strong>
            <p>En primera instancia, revisar si lleg&oacute; un email de confirmaci&oacute;n de la transacci&oacute;n a la cuenta de correo electr&oacute;nico inscrita en el momento de realizar el pago, en caso de no haberlo recibido, deber&aacute;s contactar a Gabriel De Los R&iacute;os para confirmar el estado de la transacci&oacute;n.</p>
          </li>
          <li><strong>&iquest;Qu&eacute; debo hacer si no recib&iacute; el comprobante de pago?</strong>
            <p>Por cada transacci&oacute;n aprobada a trav&eacute;s de Placetopay, recibir&aacute;s un comprobante del pago con la referencia de compra en la direcci&oacute;n de correo electr&oacute;nico que indicaste al momento de pagar. <br /><br /> Si no lo recibes, podr&aacute;s contactar a Gabriel De Los Rios o a la l&iacute;nea 3155927996 o al correo electr&oacute;nico info@unitecusa.com.co, para solicitar el reenv&iacute;o del comprobante a la misma direcci&oacute;n de correo electr&oacute;nico registrada al momento de pagar.</p>
          </li>
        </ol>
        <br></br>
        <Button
          classnames="gtmCarritoDeCompraMonedaConfirmar primary"
          text="Volver"
          type="link"
          href={`/checkout/2`}
        ></Button>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaceToPayFaqs);
