import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useTranslation } from "react-i18next";

const OrderSummary = props => {
    const { t } = useTranslation();
    const { discountPromptPayment, partialPrice, shippingPrice, isOff, creditValue, setTotalPrice, iva, currency } = props;
    const updateTotalPrice = () => {
        if (isOff) {
            setTotalPrice((partialPrice + iva + shippingPrice) - creditValue);
        } else {
            setTotalPrice((partialPrice + iva + shippingPrice) - discountPromptPayment);
        }
    };

    return (
        <div className="m__summary">
            {discountPromptPayment > 0 &&
                <div className={(isOff) ? "m__summary-item m__summary-item--stroke" : "m__summary-item"}>
                    <span>{t("pages.checkout.purchase-information.discount-prompt-payment")}</span>
                    <div className="m__number-format">
                        {currency === "USD" ?
                            <p className="m__currency_osummary"><NumberFormat value={discountPromptPayment.toFixed(2)} displayType={'text'} thousandSeparator={true} /> <span className="m_currency-simbol-osummary"> USD </span> </p>
                            :
                            <NumberFormat value={Number((discountPromptPayment).toFixed(0)) * -1} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        }
                    </div>
                </div>
            }
            <div className="m__summary-item">
                <span>{t("pages.checkout.purchase-information.subtotal")}</span>
                <div className="m__number-format">
                    {currency === "USD" ?
                        <p className="m__currency_osummary"><NumberFormat value={partialPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} /> <span className="m_currency-simbol-osummary"> USD </span> </p>
                        :
                        <NumberFormat value={Number((partialPrice).toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    }
                </div>
            </div>
            <div className="m__summary-item">
                <span>{t("pages.checkout.purchase-information.iva")}</span>
                <div className="m__number-format">
                    {currency === "USD" ?
                        <p className="m__currency_osummary"><NumberFormat value={iva.toFixed(2)} displayType={'text'} thousandSeparator={true} /> <span className="m_currency-simbol-osummary"> USD </span></p>
                        :
                        <NumberFormat value={Number((iva).toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    }
                </div>
            </div>
            <div className="m__summary-item">
                <span>{t("pages.checkout.purchase-information.shipping-value")}</span>
                <div className="m__number-format">
                    {currency === "USD" ?
                        <p className="m__currency_osummary"><NumberFormat value={shippingPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} /> <span className="m_currency-simbol-osummary"> USD </span></p>
                        :
                        <NumberFormat value={Number((shippingPrice).toFixed(1))} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    }
                </div>
            </div>
            <div className={isOff ? "m__summary-item m__summary-item--stroke" : "m__summary-item"}>
                <span>{t("pages.checkout.purchase-information.total")}</span>
                <div className="m__number-format">
                    {currency === "USD" ?
                        <p className="m__currency_osummary"><NumberFormat value={((partialPrice + shippingPrice + iva) - discountPromptPayment).toFixed(2)} displayType={'text'} thousandSeparator={true} /> <span className="m_currency-simbol-osummary"> USD </span></p>
                        :
                        <NumberFormat value={Number(((partialPrice + shippingPrice + iva) - discountPromptPayment).toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    }
                </div>
            </div>
            {isOff &&
                <div className="m__summary-item">
                    <span>{t("pages.checkout.purchase-information.total")}</span>
                    <div className="m__number-format new-color">
                        {updateTotalPrice()}
                        {currency === "USD" ?
                            <p className="m__currency_osummary">
                                <NumberFormat value={(partialPrice + iva + shippingPrice) - creditValue >= 0 ? ((partialPrice + iva + shippingPrice) - creditValue).toFixed(2) : 0} displayType={'text'} thousandSeparator={true} /> <span className="m_currency-simbol-osummary"> USD </span>
                            </p>
                            :
                            <NumberFormat
                                value={((partialPrice + iva + shippingPrice) - creditValue >= 0 ? (partialPrice + iva + shippingPrice) - creditValue : 0).toFixed(0)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

OrderSummary.propTypes = {
    partialPrice: PropTypes.number.isRequired,
    shippingPrice: PropTypes.number.isRequired,
    isOff: PropTypes.bool,
    creditValue: PropTypes.number,
    setTotalPrice: PropTypes.func,
    totalPrice: PropTypes.number,
    iva: PropTypes.number
}

export default OrderSummary;