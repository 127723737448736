import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CustomSectionTwo = ({ data }) => {
  const [infoBannerOne, setInfoBannerOne] = useState();
  const [infoBannerTwo, setInfoBannerTwo] = useState();

  useEffect(() => {
    if (data.banner) {
      let content = data.banner[0] || null;
      if (content && content.infobanner[0]) {
        setInfoBannerOne(content.infobanner[0]);
      }
      let contentTwo = data.banner[1] || null;
      if (contentTwo && contentTwo.infobanner[0]) {
        setInfoBannerTwo(contentTwo.infobanner[0]);
      }
    } 
   // console.log(data.banner[0].infobanner[0].url);
  }, []);

  return (
    <>
      <div className="ContainerSectionTwo container">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            {infoBannerOne && (
              <Link to={infoBannerOne.url}>
                <img src={infoBannerOne.imagen} />
              </Link>
            )}
          </div> 
          <div className="col-xl-6 col-md-12">
            {infoBannerTwo && (
              <Link to={infoBannerTwo.url}>
                <img src={infoBannerTwo.imagen} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
CustomSectionTwo.propTypes = {
  data: PropTypes.object.isRequired,
};
export default CustomSectionTwo;
