import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import SubCategoryProducts from '../../organisms/SubCategoryProducts/SubCategoryProducts';
import ProductServices from '../../../services/Products/ProductServices';
import UserInfo from "../../../common/LocalStorage/UserInfo";
import Loader from "../../atoms/Loader/Loader";
import Spacer from '../../atoms/Spacer/Spacer';
import * as constants from "./../../../state/constants/appConstants";

const SubCategoryTemplate = (props) => {
    const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';
    const [isLoading, setIsLoading] = useState(true);
    const [subCategoryName, setSubCategoryName] = useState(null);
    const [products, setProducts] = useState([]);
    const { catid, subcatid, subcategory, page } = props.match.params;
    useEffect(() => {
        const userInfo = JSON.parse(UserInfo.getUser());
        const getSubCategoryProducts = (catid, subcatid) => {
            setIsLoading(true);
            let payload = {
                params: {
                    idioma: getCurrentLng(),
                    categoria: catid,
                    subcategoria: subcatid,
                    pagina: 1,
                    rolUsuario: constants.rolUsuario,
                    listaPrecio: (userInfo && userInfo.listaPrecio) ? userInfo.listaPrecio : 20
                }
            };
            ProductServices.getProductsByCategoryAndSubCategory(payload).then(res => {
                if (res.estadoRespuesta === 0) {
                    setProducts(res.productos);
                } else {
                    console.warn("Alerta en el servicio: ", res);
                }
                setIsLoading(false);
            }).catch(err => console.error(err));
        };
        setSubCategoryName(subcategory);
        getSubCategoryProducts(catid, subcatid);

    }, [catid, subcategory, subcatid]);

    return ((isLoading === false) ?
        <section className="t__subcategory">
            <Spacer px={40} />
            <div className="container-xxl">
                <SubCategoryProducts
                    subcatid={subcatid}
                    subcategoryname={subCategoryName}
                    products={products}
                    page={page} />
            </div>
        </section> : <Loader />
    )
};

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubCategoryTemplate);
