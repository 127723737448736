import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import { useTranslation } from 'react-i18next';
import Token from '../../../common/LocalStorage/Token';
import Currency from '../../../common/LocalStorage/Currency';

const HeaderInfo = props => {
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const [clientCodify, setclientCodify] = useState(false)
    const [isNitCustomer, setNitCustomer] = useState(false)
    const userInfo = UserInfo.getUser();
    const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';
    const SwitchModal = type => props.switchmodal(type);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);

        if (lng === 'en') {
            Currency.setCurrency('USD');
        } else {
            Currency.setCurrency('COP');
        }

        window.location.reload();
    };

    useEffect(() => {
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);

            if (lng === 'en') {
                Currency.setCurrency('USD');
            } else {
                Currency.setCurrency('COP');
            }

            window.location.reload();
        };

        if (userInfo && JSON.parse(userInfo).rol === "2") {
            setclientCodify(true)
        }
        if (userInfo) {
            setNitCustomer(true)
        }
        if (getCurrentLng() === '' || getCurrentLng() !== 'es' || (typeof getCurrentLng() === 'undefined')) {
            changeLanguage('es');
        }
        if (!Currency.getCurrency()) {
            Currency.setCurrency('COP');
        }
    }, [i18n, userInfo]);

    const login = () => {
        if (!userInfo) {
            SwitchModal('login-modal')
        }
    }

    const logout = () => {
        setNitCustomer(false);
        UserInfo.deleteUser();
        Token.deleteToken();
        history.push("/");
        window.location.reload();

    };
    return (
        <div className={props.type === 'header' ? 'm__header-info' : 'm__burguer-info'}>
            <a className="gtmHomeSupCerrarSesion" href="/credito-epm" >Solicitud crédito EPM</a>
            {/* <div className="m__nit-customer">
                {clientCodify === true &&
                    <div className="m__nit-customer--indicator"></div>
                }
                <button className="gtmHomeSupClienteCodif" onClick={login}>{t('nav.client')}</button>
            </div> */}
            {isNitCustomer &&
                <a className="gtmHomeSupCerrarSesion" href="/payment-history" >Historial de pagos</a>
            }
           {/*  {i18n.language.includes("en") &&
                <button className="gtmHomeSupIdiomaIngles" onClick={() => changeLanguage('es')}>Spanish | COL</button>
            }
            {i18n.language.includes("es") &&
                <button className="gtmHomeSupIdiomaSpanish" onClick={() => changeLanguage('en')}>Inglés | USD</button>
            } */}

            {isNitCustomer &&
                <button className="gtmHomeSupCerrarSesion" onClick={(e) => { e.preventDefault(); logout() }}>{t('nav.log-out')}</button>
            }
        </div>
    )
}

HeaderInfo.propTypes = {
    type: PropTypes.string.isRequired,
    switchmodal: PropTypes.func
}

export default HeaderInfo;