import React from "react";
import { connect } from "react-redux";
import QCCTemplate from "../templates/QCCTemplate/QCCTemplate";

class QCC extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return <QCCTemplate />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QCC);