import * as types from './../constants/actionConstants';

const initialState = {
  auth: false,
  recover: false,
  userInfo: {}
};

const rdc_account = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.ACCOUNT_LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        // userInfo: {
        //   name: payload.userInfo.Nombre,
        //   points: payload.userInfo.Puntos,
        //   token: payload.userInfo.Token.Token
        // }
      };
    case types.ACCOUNT_LOGIN_FAILED:
      return state;
    case types.ACCOUNT_RECOVER_SUCCESS:
      return { ...state, recover: true };
    case types.ACCOUNT_RECOVER_FAILED:
      return { ...state, recover: false };
    case types.ACCOUNT_LOGIN_LOGOUT:
      return { ...initialState };
    case types.ACCOUNT_UPDATE_POINTS:
      return { ...state, userInfo: { ...state.userInfo, points: payload } };
    default:
      return state;
  }
};

export default rdc_account;