import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CountDown from "./../../molecules/CountDown/CountDown";

const CustomSectionThree = ({ data }) => {
  const [infoBanner, setInfoBanner] = useState();

  useEffect(() => {
    if (data.banner) {
      if (data.banner[0].infobanner[0].conteoinicio.length > 0) {
        setInfoBanner(data.banner[0].infobanner[0]);
      } else if (data.banner[1].infobanner[0].conteoinicio.length > 0) {
        setInfoBanner(data.banner[1].infobanner[0]);
      }
    }
    //console.log(data.banner[1].infobanner[0]);
  }, []);
  return (
    <>
      <div className="ContainerSectionThree container">
        <div className="row">
          <div className="col-xl-12">
            {infoBanner && (
              <Link to={infoBanner.url}>
                <CountDown
                  start={infoBanner.conteoinicio}
                  end={infoBanner.conteofinal}
                />
                <img src={infoBanner.imagen} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
CustomSectionThree.propTypes = {
  data: PropTypes.object.isRequired,
};
export default CustomSectionThree;
