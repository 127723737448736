import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Icon from "../../atoms/Icon/Icon";
import linkedinLogo from "./../../../assets/images/linkedin-icon.png";
import twitterLogo from "./../../../assets/images/twitter-icon.png";
import CatalogSection from "../../organisms/CatalogSection/CatalogSection";
import { useTranslation } from "react-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const Footer = () => {
  const { t } = useTranslation();
  const url =
    "https://unitecusa.us1.list-manage.com/subscribe?u=e323c4e9117d87b6bd4d982a0&id=e21ae5f8b3";
  return (
    <footer className="o__footer">
      <CatalogSection />
      <nav>
        <div className="container-xxl containerFooterNav">
          <div className="row">
            <div
              className="col-xl-4 col-md-12"
              style={{ position: "relative" }}
            >
              <div className="newsletterForm">
                {/* <MailchimpSubscribe url={url} /> */}
                <iframe
                  scrolling="no"
                  src="https://unitecusa.us1.list-manage.com/subscribe?u=e323c4e9117d87b6bd4d982a0&id=e21ae5f8b3"
                ></iframe>
              </div>
              <div className="socialLinks">
                <a
                  className="gtmHomeLinkedin"
                  target="_blank"
                  href="https://www.linkedin.com/in/unitec-u-s-a-3151a6142"
                  rel="noopener noreferrer"
                >
                  <img
                    className="gtmHomeLinkedin icon-icon-linkedin"
                    src={linkedinLogo}
                    alt="LinkedIn Unitec"
                  />
                </a>
                <a
                  className="gtmHomeFacebook"
                  target="_blank"
                  href="https://www.facebook.com/UnitecUsa/"
                  rel="noopener noreferrer"
                >
                  <Icon
                    gtm="gtmHomeFacebook"
                    icon="icon-icon-facebook"
                    size="1-2"
                    tagtype="i"
                  />
                </a>
                <a
                  className="gtmHomeInstagram"
                  target="_blank"
                  href="https://www.instagram.com/unitec_usa/"
                  rel="noopener noreferrer"
                >
                  <Icon
                    gtm="gtmHomeInstagram"
                    icon="icon-icon-instagram"
                    size="1-2"
                    tagtype="i"
                  />
                </a>
                <a
                  className="gtmHomeTwitter"
                  target="_blank"
                  href="https://twitter.com/UsaUnitec/"
                  rel="noopener noreferrer"
                >
                  <img
                    className="gtmHomeTwitter icon-icon-twitter"
                    src={twitterLogo}
                    alt="Twitter Unitec"
                  />
                </a>
                <a
                  className="gtmHomeYoutube"
                  target="_blank"
                  href="https://www.youtube.com/channel/UC6Bf45lfjX0WbgSZK2FqQCg"
                  rel="noopener noreferrer"
                >
                  <Icon
                    gtm="gtmHomeYoutube"
                    icon="icon-icon-youtube"
                    size="1-2"
                    tagtype="i"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-8 col-md-12">
              <ul className="containerFooterLinks">
                <div className="row">
                  <div className="col-md-6 col-xl-4">
                    <h6>Mi Cuenta</h6>
                    <Link className="gtmHomeNosotros" to="/about-us">
                      {t("footer.aboutUs")}
                    </Link>
                    <Link className="gtmHomePreguntasFrecuentes" to="/faq">
                      {t("footer.frequentQuestions")}
                    </Link>
                    <Link
                      className="gtmHomePreguntasQuejasReclamos"
                      to="/questions-and-claims"
                    >
                      {t("footer.PQRS")}
                    </Link>
                    <Link className="gtmHomeTrabjaConNosotros" to="/contact-us">
                      {t("footer.workWithUs")}
                    </Link>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <h6>Políticas</h6>
                    <Link
                      className="gtmHomePoliticaVentasAlExterior"
                      to="/foreign-sales-policy"
                    >
                      {t("footer.foreignSalesPolicy")}
                    </Link>
                    <Link
                      className="gtmHomePoliticaCambiosGarantia"
                      to="/warranty-policy"
                    >
                      {t("footer.productChangePolicyAndWarranty")}
                    </Link>
                    <Link
                      className="gtmHomePoliticaTratDatos"
                      to="/personal-info-policy"
                    >
                      {t("footer.personalDataProcessingPolicy")}
                    </Link>
                    <Link
                      className="gtmHomePoliticaDePrecios"
                      to="/prices-policy"
                    >
                      {t("footer.pricingPolicy")}
                    </Link>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <h6>Categorías</h6>
                    <Link
                      className="gtmHomePoliticaVentasAlExterior"
                      to="/cat/0001/tecnologia/subcat/0001/tecnologia/"
                    >
                      Tecnología
                    </Link>
                    <Link
                      className="gtmHomePoliticaCambiosGarantia"
                      to="/cat/0002/ferreteria/subcat/0002/ferreteria/"
                    >
                      Ferretería
                    </Link>
                    <Link
                      className="gtmHomePoliticaTratDatos"
                      to="/cat/0003/iluminacion/subcat/0003/iluminacion/"
                    >
                      Iluminación
                    </Link>
                    <Link
                      className="gtmHomePoliticaDePrecios"
                      to="/cat/0004/movilidad-electrica/subcat/0004/movilidad-electrica/"
                    >
                      Movilidad Eléctrica
                    </Link>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="o__footer--copyright">
        <small>
          Copyright © {new Date().getFullYear()} Unitec USA.{" "}
          {t("footer.allRightsReserved")}
        </small>
      </div>
    </footer>
  );
};

export default Footer;
