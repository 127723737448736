import React from "react";
import ButtonBar from "./../../molecules/ButtonBar/ButtonBar";
import Button from "./../../atoms/Button/Button";
import IcoCosto from "../../../assets/images/icon-costo-envio.png";
import IcoTiempo from "../../../assets/images/icon-tiempo-entrega.png";
import IcoFormas from "../../../assets/images/icon-formas-pago.png";
import IcoCambio from "../../../assets/images/icon-politicas.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "../../atoms/Icon/Icon";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";

const CatalogSection = () => {
  const { t } = useTranslation();
  const getCurrentLng = () =>
    useTranslation.language || localStorage.getItem("i18nextLng") || "";

  const downloadFileCatalogue = () => {
    /* let payload = {
            params: {
                idioma: getCurrentLng(),
                catalogo: "documento_de_prueba_para_simular_un_catalogo"
            }
        };

        HomeServices.downloadCatalogue(payload).then(res => {
            if (res.estadoRespuesta === 0) {
                // console.log("Entro al if - se descargo el catalogo: ", res)
                window.open(res.catalogo.url, '_blank')
               
            }
        }).catch(err => console.log(err)) */
    window.open(
      "https://drive.google.com/drive/folders/1pBBkIhbQPPVqzx7IqJsvxxrK_oIoqALJ",
      "_blank"
    );
  };

  return (
    <>
      <div className="o__catalog--head">
        <ButtonBar
          classnames="gtmHomeCostoDeEnvió"
          png={IcoCosto}
          icon="icon-truck"
          href="/prices-policy"
          text={t("pages.home.btn-cost-shipping")}
          scroll={"cost-shipping"}
        />
        <ButtonBar
          classnames="gtmHomeTiempoDeEntrega"
          png={IcoTiempo}
          icon="icon-clock"
          href="/prices-policy"
          text={t("pages.home.btn-delivery-time")}
          scroll={"delivery-times"}
        />
        <ButtonBar
          classnames="gtmHomeFormasDePago"
          png={IcoFormas}
          icon="icon-image"
          href="/foreign-sales-policy"
          text={t("pages.home.btn-payment-methods")}
          scroll={"payment-instructions"}
        />
        <ButtonBar
          classnames="gtmHomePoliticasDeCambio"
          png={IcoCambio}
          icon="icon-file-text"
          href="/warranty-policy"
          text={t("pages.home.btn-change-polices")}
        />
      </div>
      <div className="o__catalog--info">
        <div>
          <h4>{t("pages.home.subtitle-catalogs")}</h4>
          <p>{t("pages.home.description-catalogs")}</p>
        </div>
        <div>
          <Button
            text={t("pages.home.btn-download")}
            classnames="primary gtmHomeCatalogosDescargar"
            type="button"
            onclick={downloadFileCatalogue}
          />
        </div>
        <div className="containerFooterContactInfo">
          <a
            className="gtmHomeLlamanos"
            target="_blank"
            href="http://api.whatsapp.com/send?phone=573174050346"
            rel="noopener noreferrer"
          >
            <FiPhone /> {t("footer.callUs")}
          </a>
          <Link to="/questions-and-claims" className="gtmHomeEscribenos">
            <HiOutlineMail />{" "}
            {t("footer.writeUs")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default CatalogSection;
