import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class PaymentServices {
    static getPaymentHistory(payload) {
        return axios.get(endpoints.paymentHistory, payload).then(res => res.data);
    }
    static getPaymentStatus(payload) {
        return axios.get(endpoints.paymentStatus, payload).then(res => res.data);
    }
    static processPayment(payload) {
        return axios.post(endpoints.processPayment, payload).then(res => res.data);
    }
    static processPaymentWithCredit(payload) {
        return axios.post(endpoints.processPaymentWithCredit, payload).then(res => res.data);
    }
    static getOrderDetail(payload) {
        return axios.post(endpoints.getOrderDetail, payload).then(res => res.data);
    }
    static payDebs(payload) {
        return axios.post(endpoints.payDebs, payload).then(res => res.data);
    }
}

export default PaymentServices;