import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomSlider from "../../organisms/CustomSlider/CustomSlider";
import { getProductsRecommendedHome } from "../../../services/Products/ProductsActions";
import { ToastContainer, toast, Slide } from "react-toastify";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import NumberFormat from "react-number-format";
import Icon from "../../atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import ProductServices from "../../../services/Products/ProductServices";
import noimage from "./../../../assets/images/no-image-available.png";
import Loader from "../../atoms/Loader/Loader";
import { getCart } from "../../../state/actions/act_cart";
import dataLayer from "../../../common/GTM/dataLayer";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import Currency from "../../../common/LocalStorage/Currency";
import Cart from "../../../common/LocalStorage/Cart";
import * as constants from "./../../../state/constants/appConstants";

const ProductTemplate = (props) => {
  //  console.log(props);
  const { t } = useTranslation();
  const getCurrentLng = () =>
    useTranslation.language || localStorage.getItem("i18nextLng") || "";
  const currency = Currency.getCurrency() ? Currency.getCurrency() : "COP";
  const { productid } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [prodList, setProductsList] = useState([]);
  //const { catid, subcatid, subcategory } = props.match.params;
  const _responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ];
  const defaultProductData = {
    id: 0,
    descripcion: null,
    nombre: null,
    precio: null,
    imagenes: [],
    atributos: [],
  };
  const [productData, setProductData] = useState(defaultProductData);
  const addToCart = async () => {
    let item = {
      idProducto: productData.id,
      //srcImg: productData.imagenes[0] ? "data:image/png;base64, " + productData.imagenes[0] : noimage,
      srcImg: productData.imagenes[0] ? productData.imagenes[0] : noimage,
      title: productData.nombre,
      divisor: productData.divisor,
      precioUnitario:
        getPriceOutlet() !== 0 ? productData.precioOutlet : productData.precio,
      cantidad: counter,
      porcentajeIva: productData.porcentajeIva,
    };
    await Cart.addToCart(item);
    let addProductText =
      getCurrentLng() === "en"
        ? "Product added to the cart!"
        : "¡Producto agregado al carrito!";
    toast(addProductText, {
      className: "toast-u-container",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      transition: Slide,
    });
    props.getCart();
    dataLayer.pushEvent(
      "Productos",
      "ClicAnadirAlCarritoProductos",
      productData.nombre
    );
    dataLayer.pushEvent(
      "Productos",
      "ClicAnadirAlCarritoUrl",
      window.location.href
    );
  };

  const getPrice = () => {
    if (productData.precio) {
      if (currency === "USD") {
        return productData.precio.USD;
      } else {
        return productData.precio.COP;
      }
    } else {
      return 0;
    }
  };

  const getPriceOutlet = () => {
    if (productData.precioOutlet) {
      if (currency === "USD") {
        return productData.precioOutlet.USD;
      } else {
        return productData.precioOutlet.COP;
      }
    } else {
      return 0;
    }
  };

  const discount = () => {
    if (productData.disponibleEnOutlet === "si") {
      let diff = getPrice() - getPriceOutlet();
      return Number(((diff / getPrice()) * 100).toFixed(1));
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(UserInfo.getUser());
    window.scrollTo(0, 0);
    if (isLoading) {
      const getProduct = (idprod) => {
        let payload = {
          params: {
            idProducto: idprod,
            idioma: getCurrentLng(),
            rolUsuario: constants.rolUsuario,
            listaPrecio:
              userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
          },
        };
        //console.log(payload);
        ProductServices.getProductById(payload)
          .then((res) => {
            if (res.estadoRespuesta === 0) {
              setProductData(res.detalleProducto);
              //  console.log(res.detalleProducto);
            } else {
              console.warn("Error en la respuesta: ", res);
            }
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      };
      getProduct(productid);
    }
    if (prodList.length === 0) {
      let payload = {
        idpro: props.match.params.productid,
        listaprecio:
          userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
        rolUsuario: constants.rolUsuario,
      };
      //console.log(payload)
      ProductServices.getProctsRelated(payload).then((result) => {
        //console.log(result);
        // setProductsList(result);

        getProductosRelated(result.productos);
      });
    } else {
      //  console.log(prodList);
    }
  }, [productid, prodList]);
  const getProductosRelated = (prods) => {
    const userInfo = JSON.parse(UserInfo.getUser());
    prods.map((id) => {
      let payload = {
        params: {
          idProducto: id,
          idioma: getCurrentLng(),
          rolUsuario: constants.rolUsuario,
          listaPrecio:
            userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
        },
      };
      ProductServices.getProductById(payload)
        .then((res) => {
          if (res.estadoRespuesta === 0) {
            let obj = {
              ...res.detalleProducto,
              img: res.detalleProducto.imagenes[0],
            };
            setProductsList((oldArray) => [...oldArray, obj]);
          } else {
            console.warn("Error en la respuesta: ", res);
          }
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    });
  };
  return isLoading === false ? (
    <section className="t__category">
      <Spacer px={40} />
      <div className="container">
        <ToastContainer limit={3} />
        <section className="row o__detail-product">
          <div className="col-md-6 col-12">
            <CustomSlider
              slider_class="o__slider--product w-100"
              slider_slidestoshow={1}
              slider_dots={true}
              slider_dotclass="slick-dots slick-dots--img"
              image_class="o__banner-image"
              array={productData.imagenes}
              type="detail-product"
              autoplay={true}
              autoplaySpeed={4000}
            />
            {discount() > 0 && (
              <div className="discount-label"></div>
            )}
          </div>
          <div className="col-md-6 col-12">
            <h2>{productData.nombre}</h2>
            {discount() > 0 ? (
              <div className="product-price-outlet product-price">
                <div className="row">
                  <div className="col-12 bbottom">
                    <div className="product-price-outlet-strikethrough">
                      <span className="strikethrough-price">
                        <b>Valor sin descuento: </b>
                        <NumberFormat
                          className="t__product-price"
                          value={productData.precio.COP * counter}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        {/*  <small className="t__currency-converte"> / {productData.precio.USD * counter} {t("pages.products.dollars")}</small> */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 bbottom">
                    <div className="product-price-outlet-discount">
                      <b>Valor con descuento antes de IVA</b>
                      <NumberFormat
                        className="t__product-price"
                        value={
                          (productData.precioOutlet.COP -
                            productData.valorIvaOutlet.COP) *
                          counter
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                      {/*  <small className="t__currency-converte"> / {productData.precioOutlet.USD * counter} {t("pages.products.dollars")}</small> */}
                    </div>
                  </div>
                  <div className="col-12 bbottom">
                    <b>IVA<sup>{productData.porcentajeIva}%</sup>: </b>
                    <NumberFormat
                      className="t__product-price"
                      value={productData.valorIvaOutlet.COP * counter}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    {/*   <small className="t__currency-converte"> / {(productData.valorIvaOutlet.USD) * counter} {t("pages.products.dollars")}</small> */}
                  </div>
                  <div className="col-12 lastItemPP">
                    <b>Valor total: </b>
                    <NumberFormat
                      className="t__product-price"
                      value={productData.precioOutlet.COP * counter}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="product-price">
                <div className="row">
                  <div className="col-12 bbottom">
                    {productData.precio && (
                      <>
                        <b>Valor antes de IVA: </b>
                        <NumberFormat
                          className="t__product-price"
                          value={
                            (productData.precio.COP -
                              productData.valorIva.COP) *
                            counter
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </>
                    )}
                    {/* <small className="t__currency-converte"> / {(productData.precio.USD - productData.valorIva.USD) * counter} {t("pages.products.dollars")}</small> */}
                  </div>
                  <div className="col-12 bbottom">
                    {productData.valorIva && (
                      <>
                        <b>IVA<strong>({productData.porcentajeIva}%)</strong>: </b>
                        <NumberFormat
                          className="t__product-price"
                          value={productData.valorIva.COP * counter}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        {/* <small className="t__currency-converte"> / {productData.valorIva.USD * counter} {t("pages.products.dollars")}</small> */}
                      </>
                    )}
                  </div>
                  {productData.precio && (
                    <div className="col-12 lastItemPP">
                      <b>Valor total: </b>
                      <NumberFormat
                        className="t__product-price"
                        value={productData.precio.COP * counter}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="t__id-product">
              <Icon icon={`icon-tag hit-gray`} tagtype="i" size="1-5" />
              <p>{productData.id}</p>
            </div>
            <Spacer px={40} />
            {parseInt(productData.inventarioDisponible) > 0 && (
              <>
                <div className="m__amount">
                  <button
                    className="btn-sus"
                    onClick={() => counter > 1 && setCounter(counter - 1)}
                  >
                    {" "}
                    -{" "}
                  </button>
                  <input
                    name="amount"
                    className="num-amount"
                    type="number"
                    value={counter}
                    onChange={(e) => {
                      if (e.target.value) {
                        e.target.value <= productData.inventarioDisponible
                          ? setCounter(e.target.value)
                          : setCounter(productData.inventarioDisponible);
                      } else {
                        setCounter(1);
                      }
                    }}
                  />
                  <button
                    className="btn-sum"
                    onClick={() => {
                      console.log(counter, productData.inventarioDisponible);
                      setCounter(
                        counter < productData.inventarioDisponible
                          ? counter + 1
                          : productData.inventarioDisponible
                      );
                    }}
                  >
                    {" "}
                    +{" "}
                  </button>
                </div>
                <Spacer px={30} />
              </>
            )}
            {parseInt(productData.inventarioDisponible) > 0 ? (
              <Button
                classnames={`gtmProductosAnadirCarrito${productData.nombre.replace(
                  /\s/g,
                  ""
                )} primary`}
                text={t("pages.products.btn-add-to-cart")}
                type="button"
                onclick={() => addToCart()}
              ></Button>
            ) : (
              /* { <h2>{t("pages.products.out-stock")}</h2> }*/
              <h2 className="gp-color-red">No Disponible</h2>
            )}
            <Spacer px={20} />
            <h2>{t("pages.products.label-description")}</h2>
            <p>
              {productData.descripcion
                ? productData.descripcion
                : getCurrentLng() === "en"
                  ? "No description"
                  : "Sin descrpción"}
            </p>
            <Spacer px={30} />
          </div>

          <div className="container d-flex flex-column mb-2">
            {prodList.length > 3 && (
              <CustomSlider
                slider_class="o__featured-slider"
                title={"También te puede interesar"}
                slider_dots={false}
                slider_slidestoshow={4}
                slider_dotclass="slider-dots--banner"
                image_class="o__featured-image"
                array={prodList}
                type="img"
                autoplay={true}
                autoplaySpeed={4000}
                reload={true}
                responsive={_responsive}
              />
            )}
          </div>
        </section>
      </div>
    </section>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  cart: state.cart,
});

const mapDispatchToProps = {
  getProductsRecommendedHome,
  getCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTemplate);
