import React from "react";
import PropTypes from "prop-types";
import Icon from "../../atoms/Icon/Icon";
import "./ButtonBar.scss";
import { Link } from "react-router-dom";

const ButtonBar = (props) =>
  props.href ? (
    props.scroll ? (
      <span>
        <Link
          to={{
            pathname: props.href,
            state: {
              scrollTo: props.scroll,
            },
          }}
          className={props.classnames && props.classnames}
        >
          <img src={props.png} className="icons-bar" />
          {/* <Icon icon={`${props.icon} cerulean`} tagtype="i" size="2" /> */}
          {props.text}
        </Link>
      </span>
    ) : (
      <span>
        <Link to={props.href} className={props.classnames && props.classnames}>
          <img src={props.png} className="icons-bar" />
          {/*  <Icon icon={`${props.icon} cerulean`} tagtype="i" size="2" /> */}
          {props.text}
        </Link>
      </span>
    )
  ) : (
    <span>
      <img src={props.png} />
      {/* <Icon icon={`${props.icon} cerulean`} tagtype="i" size="2" /> */}
      {props.text}
    </span>
  );

ButtonBar.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ButtonBar;
