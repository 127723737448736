import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CustomSectionFour = ({ data }) => {
  const [infoBanner, setInfoBanner] = useState();
  const [view, setView] = useState(true);

  useEffect(() => {
    if (data.banner) {
      let content = data.banner[0] || null;
      if (content) {
        setInfoBanner(content.infobanner[0]);
      }
    }
  }, []);
  const closeModal = () => {
          setView(false);
  };
  return (
    <>
      {view && (
        <div className="ContainerSectionFour container modalBanner">
          <div className="row">
            <div className="col-xl-12">
              <div className="btnClose" onClick={() => closeModal()}>
                Cerrar
              </div>
              {infoBanner && (
                <Link to={infoBanner.url}>
                  <img src={infoBanner.imagen} />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
CustomSectionFour.propTypes = {
  data: PropTypes.object.isRequired,
};
export default CustomSectionFour;
