import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "./../../atoms/Button/Button";
import InputPrimary from "./../../atoms/InputPrimary/InputPrimary";
import Spacer from "../../atoms/Spacer/Spacer";
import { Link } from "react-router-dom";
import Loader from "../../atoms/Loader/Loader";
import { ToastContainer, toast, Slide } from "react-toastify";
import dataLayer from "../../../common/GTM/dataLayer";
import { useTranslation } from "react-i18next";

//Service
import CountryServices from "../../../services/CountryServices/CountryServices";
import RegisterServices from "../../../services/RegisterServices/RegisterServices";

const ContactUsTemplate = (props) => {
  const { t } = useTranslation();
  const getCurrentLng = () =>
    useTranslation.language || localStorage.getItem("i18nextLng") || "";
  const [listCountry, setListCountry] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listCity, setListCity] = useState([]);
  const defaultForm = {
    firstName: "",
    lastName: "",
    documentType: "",
    document: "",
    telephone: "",
    celphone: "",
    email: "",
    address: "",
    country: "",
    department: "",
    city: "",
    pwd: "",
    pwdConfirm: "",
  };
  const [formData, setFormData] = useState(defaultForm);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);
  const [celphoneError, setCelphoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [pwdConfirmError, setPwdConfirmError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isErrorinForm, setIsErrorinForm] = useState(false);
  const [isRegisterOk, setIsRegisterOk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  //const [msgError, setMsgError] = useState([]);
  //const [documentType, setDocumentType] = useState([]);

  /* const documentType = [{
    name: "Tarjeta de Identidad (TI)",
    value: 12
  }, {
    name: "Cédula de ciudadanía (CC)",
    value: 13
  }, {
    name: "Cédula de extranjería (CE)",
    value: 22
  },
  {
    name: "NIT",
    value: 31
  }]; */

  useEffect(() => {
    const loadCountry = () => {
      let payload = {
        params: {
          idioma: getCurrentLng(),
        },
      };
      CountryServices.getListCountry(payload)
        .then((res) => {
          setListCountry(res.paises);
        })
        .catch((err) => console.log(err));
    };

    loadCountry();
  }, []);

  const reset = () => {
    setFormData(defaultForm);
  };

  const loadDepartment = (country) => {
    let payload = {
      params: {
        idPais: country,
      },
    };
    CountryServices.getListDepartment(payload)
      .then((res) => {
        setListDepartment(res.departamentos);
      })
      .catch((err) => console.log(err));
  };

  const loadCity = (department) => {
    let payload = {
      params: {
        idPais: formData.country,
      },
    };
    CountryServices.getListCity(payload)
      .then((res) => {
        let cities = [];
        for (const city of res.ciudades) {
          if (city.codigoDepartamento === department) {
            cities.push(city);
          }
        }
        setListCity(cities);
      })
      .catch((err) => console.log(err));
  };

  const registerAccount = () => {
    setIsLoading(true);
    let payload = {
      nombre: formData.firstName,
      apellido: formData.lastName,
      usuario: formData.document,
      clave: formData.pwd,
      tipoDeDocumento: formData.documentType,
      numeroDeDocumento: formData.document,
      telefono: formData.telephone,
      celular: formData.celphone,
      email: formData.email,
      departamento: formData.department,
      ciudad: formData.city,
      direccionDeEnvio: formData.address,
    };
    RegisterServices.register(payload)
      .then((res) => {
        if (res.data.estadoRespuesta === 2) {
          toast.error(
            t("pages.register.error-register") + res.data.mensajeDeError,
            {
              className: "toast-u-container",
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              transition: Slide,
            }
          );
        } else if (res.data.estadoRespuesta === 0) {
          reset();
          setIsRegisterOk(true);
          toast.success(t("pages.register.succefull-register"), {
            className: "toast-u-container",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide,
          });
          dataLayer.pushEvent("Registro", "ClicFormulario", "EnviarExitoso");
          dataLayer.pushEvent(
            "Registro",
            "ClicFormularioCorreo",
            formData.email
          );
          setTimeout(() => {
            openLoginModal();
          }, 500);
        } else {
          toast.error(t("pages.register.service-error"), {
            className: "toast-u-container",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide,
          });
          console.error(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const loadDs = (value) => {
    let payload = {
      params: {
        idPais: value,
      },
    };
    CountryServices.getListIdCountry(payload).then((res) => {
      setDocumentTypes(res.tiposId);
    });
  };
  const openLoginModal = () => {
    props.handleswitchmodal("login-modal");
  };
  const eliminateLastChart = (text) => {
    let obj = {
      rest: text.slice(0, -1),
      last: text.slice(-1),
    };
    return obj;
  };
  const validateErrors = () => {
    if (formData.firstName === "" || formData.firstName === " ") {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (formData.lastName === "" || formData.lastName === " ") {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }

    if (formData.documentType === "") {
      setDocumentTypeError(true);
    } else {
      setDocumentTypeError(false);
    }

    if (formData.document === "") {
      setDocumentError(true);
    } else {
      setDocumentError(false);
    }

    if (formData.department === "") {
      setDepartmentError(true);
    } else {
      setDepartmentError(false);
    }

    if (formData.city === "") {
      setCityError(true);
    } else {
      setCityError(false);
    }

    /*  if (formData.telephone === "") {
      setTelephoneError(true);
    } else {
      setTelephoneError(false);
    } */

    if (
      formData.celphone === "" ||
      formData.celphone > 9999999999 ||
      formData.celphone < 1000000000
    ) {
      setCelphoneError(true);
    } else {
      setCelphoneError(false);
    }

    if (
      formData.email === "" ||
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (formData.address === "" || formData.address === " ") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    if (formData.country === "") {
      setCountryError(true);
    } else {
      setCountryError(false);
    }

    if (
      formData.pwd === "" ||
      formData.pwd.length < 6
      //  formData.pwd === formData.pwd.toLowerCase()
    ) {
      setPwdError(true);
    } else {
      setPwdError(false);
    }

    if (formData.pwd !== formData.pwdConfirm || formData.pwdConfirm === "") {
      setPwdConfirmError(true);
    } else {
      setPwdConfirmError(false);
    }

    return (
      !isChecked ||
      formData.firstName === "" ||
      formData.firstName === " " ||
      formData.documentType === "" ||
      formData.document === "" ||
      formData.city === "" ||
      formData.celphone === "" ||
      formData.celphone > 9999999999 ||
      formData.celphone < 1000000000 ||
      formData.email === "" ||
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email) ||
      formData.address === "" ||
      formData.address === " " ||
      formData.country === "" ||
      formData.deparment === "" ||
      formData.pwd === "" ||
      formData.pwd.length < 6 ||
      // (formData.pwd === formData.pwd.toLowerCase()) ||
      formData.pwd !== formData.pwdConfirm ||
      formData.pwdConfirm === ""
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateErrors()) {
      setIsErrorinForm(false);
      registerAccount();
    } else {
      setIsErrorinForm(true);
    }
  };
  const flickerError = (method) => {
    method(true);
    setTimeout(() => {
      method(false);
    }, 1000);
  };
  /* Eliminate no numerics charts */
  const purgueNumerics = (text) => {
    var r = /\d+/g;
    var m;
    let ret = "";
    while ((m = r.exec(text)) != null) {
      ret += m[0];
    }
    return ret;
  };
  const isNumeric = (text) => {
    return /^[0-9]\d*$/.test(text);
  };
  const regexByTypeId = () => {
    let obj = {
      regex: null,
      minLength: 0,
      maxLength: 0,
    };
    let i = parseInt(formData.documentType);
    switch (i) {
      case 13:
        obj.regex = /^[1-9][0-9]{6,9}$/;
        obj.minLength = 6;
        obj.maxLength = 9;
        return obj;

      case 22:
        obj.regex = /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/;
        obj.minLength = 3;
        obj.maxLength = 7;
        return obj;

      case 31:
        obj.regex = /^[1-9][0-9]{4,11}$/;
        obj.minLength = 4;
        obj.maxLength = 11;
        return obj;

      default:
        return obj;
        break;
    }
  };
  const handleOnChange = (node, event) => {
    switch (node) {
      case "firtName":
        setFormData({ ...formData, firstName: event.target.value });
        if (
          !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(
            event.target.value
          )
        ) {
          setFirstNameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({
            ...formData,
            firstName: w.rest.length > 0 ? w.rest : "",
          });
          setTimeout(() => {
            setFirstNameError(false);
          }, 2000);
        } else {
          setFirstNameError(false);
        }
        break;
      /* case "firtName":
        setFormData({ ...formData, firstName: event.target.value.replace(/ /g, " ") });
        break; */
      /*   case "lastName":
          setFormData({ ...formData, lastName: event.target.value.replace(/ /g, " ") });
          break; */
      case "lastName":
        setFormData({ ...formData, lastName: event.target.value });
        if (
          !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(
            event.target.value
          )
        ) {
          setLastNameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({
            ...formData,
            lastName: w.rest.length > 0 ? w.rest : "",
          });
          setTimeout(() => {
            setLastNameError(false);
          }, 2000);
        } else {
          setLastNameError(false);
        }
        break;
      case "documentType":
        setFormData({ ...formData, documentType: event.target.value });
        break;
      case "document":
        if (formData.documentType) {
          let obj = regexByTypeId();
          let textPurgued = purgueNumerics(event.target.value);
          if (!obj.regex.test(event.target.value)) {
            let w = eliminateLastChart(event.target.value);
            setFormData({
              ...formData,
              document:
                textPurgued && obj.maxLength >= event.target.value.length
                  ? textPurgued
                  : w.rest,
            });
            if (!isNumeric(w.last)) {
              flickerError(setDocumentError);
            }
          } else {
            setFormData({ ...formData, document: event.target.value });
            setDocumentError(false);
          }
        } else {
          // setDError(true)
        }
        break;
      /*   case "document":
          setFormData({ ...formData, document: event.target.value.replace(/ /g, "") });
          break; */
      case "telephone":
        setFormData({
          ...formData,
          telephone: event.target.value.replace(/ /g, ""),
        });
        break;
      case "celphone":
        setFormData({
          ...formData,
          celphone: event.target.value.replace(/ /g, ""),
        });
        break;
      case "email":
        setFormData({
          ...formData,
          email: event.target.value.replace(/ /g, ""),
        });
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
            event.target.value
          )
        ) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }
        break;
      case "address":
        setFormData({
          ...formData,
          address: event.target.value.replace(/ /g, " "),
        });
        break;
      case "country":
        setFormData({ ...formData, country: event.target.value });
        loadDepartment(event.target.value);
        loadDs(event.target.value);
        break;
      case "department":
        setFormData({ ...formData, department: event.target.value });
        loadCity(event.target.value);
        break;
      case "city":
        setFormData({ ...formData, city: event.target.value });
        break;
      case "pwd":
        setFormData({ ...formData, pwd: event.target.value.replace(/ /g, "") });
        break;
      case "pwdConfirm":
        setFormData({
          ...formData,
          pwdConfirm: event.target.value.replace(/ /g, ""),
        });
        break;

      default:
        setFormData({ ...formData });
        break;
    }
  };

  const handleOnChangeCheck = () => {
    setIsChecked(!isChecked);
  };
  const printErrors = () => {
    let msj = "";
    if (firstNameError) msj.concat("El campo Nombre es Obligatorio \n");
    if (lastNameError) msj.concat("El campo Apellido es Obligatorio \n");
    return msj;
  };
  return (
    <section className="p_contactUs">
      <Spacer px={40} />
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="container">
          <ToastContainer />
          <Spacer px={30} />
          <h1 className="max-width-800">{t("pages.register.title")}</h1>
          <Spacer px={20} />
          <div className="white-card-bkg">
            <form className="max-width-800">
              <Spacer px={30} />
              <div className="row">
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.checkout.step-2.name")}`}
                    value={formData.firstName}
                    type={"text"}
                    placeholder={t("pages.checkout.step-2.name-placeholder")}
                    onchange={(e) => {
                      handleOnChange("firtName", e);
                    }}
                    isUserError={firstNameError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.checkout.step-2.surname")}`}
                    value={formData.lastName}
                    type={"text"}
                    placeholder={t("pages.checkout.step-2.surname-placeholder")}
                    onchange={(e) => {
                      handleOnChange("lastName", e);
                    }}
                    isUserError={lastNameError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <div className="a__input-primary a__select-primary">
                    <span> {`* ${t("pages.register.country")}`} </span>
                    <select
                      className={countryError ? `field-error` : undefined}
                      defaultValue={"DEFAULT"}
                      onChange={(e) => {
                        handleOnChange("country", e);
                      }}
                    >
                      <option value="DEFAULT" disabled>
                        {t("pages.register.country-placeholder")}
                      </option>
                      {listCountry.map((country, index) => (
                        <option key={index} value={country.codigo}>
                          {country.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {
                  <div className="col-sm-6 col-12">
                    <div className="a__input-primary a__select-primary">
                      <span> {`* ${t("pages.register.department")}`} </span>
                      <select
                        className={departmentError ? `field-error` : undefined}
                        defaultValue={"DEFAULT"}
                        onChange={(e) => {
                          handleOnChange("department", e);
                        }}
                        disabled={listDepartment.length === 0}
                      >
                        <option value="DEFAULT" disabled>
                          {t("pages.register.department-placeholder")}
                        </option>
                        {listDepartment.map((department, index) => (
                          <option key={index} value={department.codigo}>
                            {department.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                }
                <div className="col-sm-6 col-12">
                  <div className="a__input-primary a__select-primary">
                    <span> {`* ${t("pages.register.city")}`} </span>
                    <select
                      className={cityError ? `field-error` : undefined}
                      defaultValue={"DEFAULT"}
                      onChange={(e) => {
                        handleOnChange("city", e);
                      }}
                      disabled={listCity.length === 0}
                    >
                      <option value="DEFAULT" disabled>
                        {t("pages.register.city-placeholder")}
                      </option>
                      {listCity.map((city, index) => (
                        <option key={index} value={city.id}>
                          {city.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="a__input-primary a__select-primary">
                    <span> {`* ${t("pages.register.document-type")}`} </span>
                    <select
                      className={documentTypeError ? `field-error` : undefined}
                      defaultValue={"DEFAULT"}
                      onChange={(e) => {
                        handleOnChange("documentType", e);
                      }}
                      disabled={formData.country.length > 0 ? false : true}
                    >
                      <option value="DEFAULT" disabled>
                        {t("pages.register.document-type-placeholder")}
                      </option>
                      {documentTypes.map((type, index) => (
                        <option key={index} value={type.codigo}>
                          {type.descripcion}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.document-number")}`}
                    value={formData.document}
                    type={"text"}
                    placeholder={t(
                      "pages.register.document-number-placeholder"
                    )}
                    onchange={(e) => {
                      handleOnChange("document", e);
                    }}
                    isUserError={documentError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`${t("pages.register.phone")}`} 
                    value={formData.telephone}
                    type={"number"}
                    placeholder={t("pages.register.phone-placeholder")}
                    onchange={(e) => {
                      handleOnChange("telephone", e);
                    }}
                    isUserError={telephoneError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.celphone")}`}
                    value={formData.celphone}
                    type={"number"}
                    placeholder={t("pages.register.celphone-placeholder")}
                    onchange={(e) => {
                      handleOnChange("celphone", e);
                    }}
                    isUserError={celphoneError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.email")}`}
                    value={formData.email}
                    type={"text"}
                    placeholder={t("pages.register.email-placeholder")}
                    onchange={(e) => {
                      handleOnChange("email", e);
                    }}
                    isUserError={emailError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.address")}`}
                    value={formData.address}
                    type={"text"}
                    placeholder={t("pages.register.address-placeholder")}
                    onchange={(e) => {
                      handleOnChange("address", e);
                    }}
                    isUserError={addressError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.password")}`}
                    value={formData.pwd}
                    type={"password"}
                    placeholder={t("pages.register.password-placeholder")}
                    onchange={(e) => {
                      handleOnChange("pwd", e);
                    }}
                    isUserError={pwdError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.confirm-password")}`}
                    value={formData.pwdConfirmError}
                    type={"password"}
                    placeholder={t(
                      "pages.register.confirm-password-placeholder"
                    )}
                    onchange={(e) => {
                      handleOnChange("pwdConfirm", e);
                    }}
                    isUserError={pwdConfirmError}
                  />
                </div>
              </div>
            </form>
            <Spacer px={35} />
            <div className="center-content">
              <div className="a__check-box">
                <label className="">
                  {t("pages.register.accept")}{" "}
                  <Link to="/terms-and-conditions">
                    {t("pages.register.terms-and-conditions")}
                  </Link>
                  <input
                    className="gtmRegistroTerminosCondicionesAceptar"
                    type="checkbox"
                    onClick={(e) => handleOnChangeCheck()}
                  ></input>
                  <span></span>
                </label>
              </div>
              <Spacer px={15} />
              <Button
                classnames="gtmRegistroFormularioEnviar primary"
                text="enviar"
                type="button"
                onclick={(e) => handleSubmit(e)}
              ></Button>
              {isErrorinForm && (
                <div>
                  <p className="msj-error">
                    Todos los campos con * son necesarios
                  </p>
                  {!isChecked && (
                    <p className="msj-error">
                      Por favor acepte los Términos y Condiciones
                    </p>
                  )}
                </div>
              )}
              {isRegisterOk && (
                <div>
                  <p className="msj-success">
                    {t("pages.register.succefull-register")}
                  </p>
                </div>
              )}
              <Spacer px={50} />
            </div>
          </div>
          <Spacer px={70} />
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsTemplate);
