import * as types from './../constants/actionConstants';

/* Lightbox Actions */
export const openModal = payload => (
    document.body.classList.add('freeze-page'),
    { type: types.UI_MODAL_OPEN, payload });
export const closeModal = () => (
    document.body.classList.remove('freeze-page'),
    { type: types.UI_MODAL_CLOSE });
export const showLoader = () => ({ type: types.UI_LOADER_SHOW });
export const hideLoader = () => ({ type: types.UI_LOADER_HIDE });
export const setValorTotal = (payload) => ({ type: types.VALOR_TOTAL, payload });
export const setTotalIVA = (payload) => ({ type: types.VALOR_TOTAL_IVA, payload });
export const setTotalFull = (payload) => ({ type: types.VALOR_TOTAL_FULL, payload });
export const setScaleSelected = (payload) => ({ type: types.SCALE_SELECTED, payload });