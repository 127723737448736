import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const ForeignSalesTemplate = props => {
  const { t } = useTranslation();
  const paymentInsRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const { state } = props.location;

  useEffect(() => {
    if (typeof state != "undefined") {
      switch (state.scrollTo) {
        case 'payment-instructions':
          scrollToRef(paymentInsRef);
          break;
        default:
          window.scrollTo(0, 0);
          break;
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [state]);

  return (
    <section className="p__foreignSales">
      <div className="container">
        <h2>{t("pages.foreign-sales-policy.title")}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: t("pages.foreign-sales-policy.description")
          }}
        ></div>
        <h3>
          {t("pages.foreign-sales-policy.subtitle-dispatch-instructions")}
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: t("pages.foreign-sales-policy.description-dispatch-instructions")
          }}
        ></div>
        <h3 ref={paymentInsRef}>
          {t("pages.foreign-sales-policy.subtitle-payment-instructions")}{" "}
        </h3>
        <p>{t("pages.foreign-sales-policy.description-payment-instructions")}</p>
        <h3>{t("pages.foreign-sales-policy.subtitle-note")} </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: t("pages.foreign-sales-policy.description-note")
          }}
        ></div>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForeignSalesTemplate);
