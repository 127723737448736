import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class CheckoutServices {
  static calculateShipping(payload) {
    return axios.post(endpoints.calculateShipping, payload).then(res => res.data);
  }

  static notifyPaymentCredit(payload) {
    return axios.post(endpoints.reportPaymentCredit, payload).then(res => res.data);
  }
  static getDebsToPay(payload) {
    return axios.post(endpoints.solicitarCartera, payload).then(res => res.data);
  }
  static async getBalance(payload) {
    let res = await axios.post(endpoints.cupoDisponible, payload);
    return res.data
  }
}

export default CheckoutServices;
