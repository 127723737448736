import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/Icon/Icon';

import { useTranslation } from "react-i18next";

const FreeShippingBar = props => {
    const { t } = useTranslation();
    const { missingPrice, freeShippingPrice } = props;
    const diff = freeShippingPrice.COP - missingPrice.COP;
    const progressPercent = (diff / freeShippingPrice.COP) * 100;

    return (
        <div className="m__free-shipping">
            <div className="m__progress-bar">
                <div className="m__progress-bar-percent" style={{ width: progressPercent + '%' }}></div>
            </div>
            <div className="m__free-shipping-cont">
                <Icon icon={`icon-truck cerulean`} tagtype="i" size="1-5" />
                <p>
                    {missingPrice.COP > 0 &&
                            <span>{t("pages.shopping-cart.free-shipping.fs-1")} ${missingPrice.COP} {t("pages.shopping-cart.free-shipping.fs-2")}</span>
                    }
                    <span> {t("pages.shopping-cart.free-shipping.fs-3")}</span>
                </p>
            </div>
        </div>
    )
}

FreeShippingBar.propTypes = {
    missingPrice: PropTypes.object
}

export default FreeShippingBar;