import React from "react";
import { connect } from "react-redux";
import ResetPasswordTemplate from "../templates/ResetPasswordTemplate/ResetPasswordTemplate";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <ResetPasswordTemplate handleswitchmodal={this.props.handleswitchmodal} />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);