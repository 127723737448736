import * as types from './../constants/actionConstants';

const initialState = {
    category: [],
    subcategories: [],
    subcategoryproducts: []
};

const rdc_category = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.CATEGORY_ITEMS_SUCCESS:
            return {
                ...state,
                category: payload.categorias,
                subcategories: payload.categorias[0].subcategorias
            };
        case types.CATEGORY_ITEMS_FAILED:
            return state;
        case types.SUBCATEGORY_PRODUCTS_SUCCESS:
            return {
                ...state,
                subcategoryproducts: payload.Productos
            };
        case types.SUBCATEGORY_PRODUCTS_FAILED:
            return state;
        default:
            return state;
    }
};

export default rdc_category;