import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "./../../atoms/Button/Button";
import InputPrimary from "./../../atoms/InputPrimary/InputPrimary";
import Spacer from "../../atoms/Spacer/Spacer";
import { ToastContainer, toast, Slide } from 'react-toastify';
import Loader from "../../atoms/Loader/Loader";
import RecoverPassServices from "../../../services/RecoverPassServices/RecoverPassServices";
import { useTranslation } from "react-i18next";

const RecoverPasswordTemplate = props => {
  const defaultForm = {
    cedula: 0
  };
  const defaultForm2 = {
    email: "",
    celular: ""
  };
  const [formData, setformData] = useState(defaultForm);
  const [formData2, setformData2] = useState(defaultForm2);
  const [emailError, setEmailError] = useState(false);
  const [cedulaError, setCedulaError] = useState(false);
  const [isErrorinForm, setIsErrorinForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [dataUser, setDataUser] = useState({
    email: "",
    celular: "no registrado",
    usuario: "",
  });

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataUser.email != "" || dataUser.celular != "no registrado") {
      setStep(2);
      console.log(dataUser);
    }
  }, [dataUser]);
  const validateError = () => {
    if (formData.cedula === "") {
      setCedulaError(true);
      return true;
    }
    return false;
  }
  const validateError2 = () => {
    if (formData.email === "" || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    return (
      (formData.email === "") ||
      (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email))
    );
  };

  const reset = () => {
    setformData(defaultForm);
    setTimeout(() => {
      window.location.reload();
    }, 5300);
  };
  const replaceCharts = (str) => {
    if (str) {
      let ret = '';
      let section1 = str.split('@')[0];
      let section2 = str.split('@')[1];
      let percentSection1 = 50;
      let percentSection2 = 20;
      for (let index = 0; index < section1.length; index++) {
        const element = section1[index];
        let percent = (index * 100) / section1.length;
        if (percent > percentSection1 && percent <= percentSection1 + 50) {
          ret += '*';
        } else {
          ret += element;
        }
      }
      ret += '@';
      for (let index = 0; index < section2.length; index++) {
        const element = section2[index];
        let percent = (index * 100) / section2.length
        if (percent > percentSection2 && percent <= percentSection2 + 30) {
          ret += '*';
        } else {
          ret += element;
        }
      }
      return ret;
    } else {
      return "no registrado"
    }
  }
  const sendPassword = () => {
    setIsLoading(true);
    let payload = {
      celular: dataUser.celular,
      email: dataUser.email,
      usuario: parseInt(formData.cedula)
    }
    console.log(payload);
    RecoverPassServices.recover(payload).then(res => {
    //  console.log(res.data);
      if (res.status === 200) {
        if (res.data.estadoRespuesta === 0) {
          toast.success(t('pages.recovery-password.success-message'), {
            className: "toast-u-container",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide
          });
          reset();
          setIsLoading(false);
        }
      }
    })

  }
  const recoverPassword = () => {
    setIsLoading(true);
    let payload = {
      cedula: formData.cedula
    }

    RecoverPassServices.remember(payload).then(res => {
     // console.log(res);
      setDataUser(res.data);

      console.log(dataUser)
      if (res.status === 200) {
        if (res.data.estadoRespuesta === 0) {
          toast.success(t('pages.recovery-password.success-message'), {
            className: "toast-u-container",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide
          });
          reset();
        } else if (res.data.estadoRespuesta === 2) {
          toast.error(t('pages.recovery-password.error-message'), {
            className: "toast-u-container",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide
          });
          console.log("Response error: ", res);
        } else {
          console.warn("Response data: ", res.data);
        }
      } else {
        console.error("Response error: ", res);
      }
      setIsLoading(false);
    }).catch(err => {
      console.error(err);
      setIsLoading(false);
    });
  }

  const handleSubmit = event => {
    event.preventDefault();
    setIsErrorinForm(false);
    if (!validateError()) {
      recoverPassword();
    } else {
      setIsErrorinForm(true);
    }
  };
  /*   const handleSubmit2 = event => {
      event.preventDefault();
      setIsErrorinForm(false);
      if (!validateError()) {
        sendPassword();
      } else {
        setIsErrorinForm(true);
      }
    }; */

  const handleOnChange = (node, event) => {
    setformData({
      ...formData,
      [node]: event.target.value.replace(/ /g, ""),
    });
  };
  const handleOnChange2 = (node, event) => {
    setformData2({
      ...formData2,
      [node]: event.target.value.replace(/ /g, ""),
    })
  };

  return (
    <section className="p__recover-pwd">
      {isLoading === true &&
        <Loader />
      }
      <ToastContainer />
      <div className="container">
        <Spacer px={30} hideon="small" />
        <h1>{t('pages.recovery-password.title')}</h1>
        <Spacer px={30} hideon="small" />
        <div className="white-card-bkg">
          {/* <Spacer px={30} hideon="small" /> */}
          <Spacer px={20} hideon="small" />
          {step === 1 ?
            <form>
              <div className="row">
                <div className="col-12">
                  <InputPrimary label="Documento de identidad NIT / Cédula" type={"text"} placeholder='Escriba su documento' onchange={e => {
                    handleOnChange("cedula", e);
                  }}
                    isUserError={emailError}></InputPrimary>
                </div>
              </div>
              <div className="center-content">
                <Spacer px={50} hideon="small" />
                <Button classnames="gtmIniciaSesionRestableceTuContrasenaContinuar primary" text="Continuar" type="button" onclick={(e) => { handleSubmit(e) }}></Button>
                <Spacer px={50} hideon="small" />
              </div>
            </form>
            :
            <>
              {dataUser.email || dataUser.celular ?
                <>
                  <div className="row">
                    <div className="col-12">
                      <h3 className="recover-pass-message-h3">El sistema enviará a este correo electrónico<span>{replaceCharts(dataUser.email)}</span> y por SMS a este celular <span>{!dataUser.celular || dataUser.celular.length === 0 ? "no registrado" : dataUser.celular}</span>su nueva contraseña</h3>
                      <div className="center-content">
                        <Spacer px={50} hideon="small" />
                        <Button classnames="gtmIniciaSesionRestableceTuContrasena primary" text="Recuperar contraseña" type="button" onclick={e => sendPassword(e)}></Button>
                        <Spacer px={50} hideon="small" />
                      </div>
                      <h6><strong>Si desea actualizar esta información por favor comuníquese con nosotros al <a href="http://api.whatsapp.com/send?phone=573182164642">+57 3182164642</a></strong></h6>
                    </div>
                  </div>

                </>
                :
                <div className="row">
                  <div className="col-12">
                    <h3><strong>El sistema no tiene registrados sus datos principales(Correo electrónico ó Celular) para enviarle su contraseña. Por favor comuníquese con nosotros al <a href="http://api.whatsapp.com/send?phone=573182164642">+57 3182164642</a> para ayudarle con su información de acceso</strong></h3>
                  </div>
                </div>
              }
            </>
          }
          {isErrorinForm &&
            <div>
              <p className='msj-error'>*{t("pages.checkout.step-2.verify-fields")}</p>
            </div>
          }
        </div>
        <Spacer px={150} hideon="small" />
      </div>
    </section >
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPasswordTemplate);
