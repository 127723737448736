import React from "react";
import { connect } from "react-redux";
import RecoverPasswordTemplate from "./../templates/RecoverPasswordTemplate/RecoverPasswordTemplate";

class RecoverPassword extends React.Component {
  render() {
    return <RecoverPasswordTemplate />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPassword);