import React from "react";
import { connect } from "react-redux";
import Spacer from "./../atoms/Spacer/Spacer";

class WorkWithUs extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
        <div className="content-page-container">
            <h1>-</h1>
            <Spacer px={30}/>            
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur qui et dolorum culpa, quod nesciunt quibusdam nemo cum a! Quia reiciendis asperiores aperiam repudiandae aspernatur debitis nostrum reprehenderit! Eligendi, excepturi.</p>   
            <Spacer px={30}/>
        </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkWithUs);
