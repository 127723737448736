import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = props => {

    if (props.type === "link") {
        return (
            <Link className={`a__button ${props.classnames}`} to={props.href} onClick={props.onclick}>{props.text}</Link>
        )
    } else if (props.type === "submit" ) {
        return(
            <input type="submit" value="Submit" />      
        )
    } else {
        return (
            <button className={`a__button ${props.classnames} ${(props.disabled) ? 'disabled': ''}`} onClick={props.onclick} disabled={props.disabled}>
                {props.type === 'button'
                    ?
                    props.text
                    :
                    props.children}
            </button>
            
        )
    }
}

Button.propTypes = {
    onclick: PropTypes.func,
    text: PropTypes.string,
    classnames: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node,
    href: PropTypes.string
};

export default Button; 