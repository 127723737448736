import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PublicRoute = ({
  component: Component,
  auth,
  path,
  rest,
  handleswitchmodal,
}) => (
  <Route
    {...rest}
    path={path}
    render={
      (props) =>
        auth === false ? (
          <Component
            {...props}
            auth={auth}
            handleswitchmodal={handleswitchmodal}
          />
        ) : (
          <Component
          {...props}
          auth={auth}
          handleswitchmodal={handleswitchmodal}
        />
        )
    }
  />
);
export const PrivateRoute = ({ component: Component, auth, path, rest }) => (
  <Route
    {...rest}
    path={path}
    render={(props) =>
      auth === true ? (
        <Component {...rest} {...props} auth={auth} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);
