import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Routes from "./components/organisms/Routes";
import LightBox from "./components/organisms/LightBox/LightBox";
import WppButton from "./components/atoms/WppButton/WppButton";
import RecargasButton from "./components/atoms/RecargasButton/RecargasButton";
import SolicitudEpmButton from "./components/atoms/SolicitudEpmButton/SolicitudEpmButton";
import {
  closeModal,
  openModal,
  showLoader,
} from "./state/actions/act_interface";
import { updateAuth } from "./state/actions/act_account";
import Header from "./components/organisms/Header/Header";
import Footer from "./components/organisms/Footer/Footer";
import * as constants from "./state/constants/appConstants";
import "react-toastify/dist/ReactToastify.css";

const App = (props) => {
  const { rdc_interface, rdc_account } = props;

  const handleShowModal = (new_type) => {
    props.openModal(new_type);
  };

  const handleHideModal = () => {
    props.closeModal();
  };

  const handleSwitchModal = (type) => {
    if (rdc_interface.modalIsOpen) {
      closeModal();
    } else {
      handleShowModal(type);
      /* switch (type) {
        case "login-modal":
          handleShowModal("login-modal")
          break;
        case "cart-modal":
          handleShowModal("login-modal")
          break;

        default:
          break;
      } */
    }
    /* type === "login-modal"
      ? handleShowModal("login-modal")
      : type === "cart-modal"
        ? handleShowModal("cart-modal")
        : handleShowModal("success-modal"); */
  };
  if (process.env['The_env_of_your_project_that_defines_environment'] === "production")
  console.log = function no_console() {};

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <WppButton />
        <RecargasButton class={constants.rolUsuario === 3 ? 'rcgs-button-topT' : 'rcgs-button-topO'} />
        {constants.rolUsuario === 3 && <SolicitudEpmButton />}
        <Header
          modalType={rdc_interface.modalType}
          handleswitchmodal={handleSwitchModal}
          handleshowmodal={handleShowModal}
          handlehidemodal={handleHideModal}
        />
        <Routes
          modalType={rdc_interface.modalType}
          handleswitchmodal={handleSwitchModal}
          handleshowmodal={handleShowModal}
          handlehidemodal={handleHideModal}
          auth={rdc_account.auth}
        />
        <Footer />
        {rdc_interface.modalIsOpen && <LightBox />}
      </Router>
    </Suspense>
  );
};

const mapDispatchToProps = {
  showLoader,
  openModal,
  closeModal,
  updateAuth,
};

const mapStateToProps = ({ rdc_interface, rdc_account }) => ({
  rdc_interface,
  rdc_account,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
