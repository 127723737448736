import React, { useEffect, useState } from 'react';
import Cart from "../../../common/LocalStorage/Cart";
import ProductServices from "../../../services/Products/ProductServices";
import * as constants from "./../../../state/constants/appConstants";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import CheckoutServices from "../../../services/CheckoutServices/CheckoutServices";
import CartCardProduct from "../../molecules/CartCardProduct/CartCardProduct";
import dataLayer from "../../../common/GTM/dataLayer";
import { useTranslation } from "react-i18next";
import FormatNumber from "../../../utils/FormatNumber";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import FreeShippingBar from "../../molecules/FreeShippingBar/FreeShippingBar";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getCart } from "../../../state/actions/act_cart";
import { setValorTotal, setTotalIVA, setTotalFull, setScaleSelected } from "../../../state/actions/act_interface";
import { closeModal } from "../../../state/actions/act_interface";
import preLoaderIcon from "../../../assets/images/preloader.gif";


const CartRender = (props) => {
    const { t } = useTranslation();
    const [cartItems, setCartItems] = useState();
    const userInfo = JSON.parse(UserInfo.getUser());
    const [totalOrder, setTotalOrder] = constants.useStateCallback(0);
    const [totalOrderLessIVA, setTotalOrderLessIVA] = constants.useStateCallback(0);
    const [escala, setEscala] = useState();
    const [scaleSelected, setScaleSelected] = constants.useStateCallback();
    const [loadingCart, setLoadingCart] = useState(false);
    const [cost, setCost] = useState([]);
    const history = useHistory();
    const { rdc_interface, handleHideModal, saveValorTotal, saveTotalIVA, saveTotalFull, saveScaleSelected } = props;
    const [preLoader, setPreLoader] = useState(true)


    useEffect(() => {
        // console.log('rdc_interface:::::::::: ', rdc_interface);
        if (!cartItems) {
            setCartItems(Cart.getCart() ? JSON.parse(Cart.getCart()) : []);
        }
    }, [cartItems, rdc_interface]);


    useEffect(() => {
        let payload = {
            params: {
                rol: constants.rolUsuario,
                listaprecio:
                    parseInt(userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20),
            }
        }
        // //console.log(payload);
        ProductServices.getEscalas(payload).then((res) => {
            //console.log(res.escala);
            setEscala(res.escala);
        });

    }, []);

    useEffect(() => {
        if (escala && escala.length > 0 && !scaleSelected && cartItems && cartItems.length > 0) {
            calculateTotals();
        }
    }, [escala, cartItems]);


    const calculateTotals = async () => {
        let _totalOrder = 0;
        let _totalOrderLessIVA = 0;
        const asyncRes = await Promise.all(
            cartItems.map(async (i) => {
                let pr = await getProductById(i.idProducto);
                if (pr.COP !== 0) {
                    let IVA = (i.porcentajeIva / 100) + 1;
                    let precioSinIVA = Math.round(pr.COP / IVA);
                    _totalOrder += pr.COP * i.cantidad;
                    _totalOrderLessIVA += precioSinIVA * i.cantidad;
                }
                return pr;
            })
        );
        setTotalOrder(_totalOrder)
        setTotalOrderLessIVA(_totalOrderLessIVA, l => settingScale(l))
    }
    const settingScale = (_totalOrderLessIVA) => {
        let scaleSetted = false;
        escala.forEach(element => {
            if (parseFloat(element.minimo) < _totalOrderLessIVA && _totalOrderLessIVA <= parseFloat(element.maximo)) {
                //  console.log(convertToFloat(element))
                setScaleSelected(convertToFloat(element), r => getCalculateShipping(r));
                scaleSetted = true;
                saveScaleSelected(convertToFloat(element));

            }
        });
        if (!scaleSetted) {
            let scale = {
                descuento: 0,
                idescala: 0,
                maximo: 0,
                minimo: 0,
            }
            scaleSetted = true;
            setScaleSelected(scale, r => getCalculateShipping(r));
            saveScaleSelected(scale);
        }
    }
    const getProductById = async (productID, cant) => {
        let payload = {
            params: {
                idProducto: productID,
                idioma: "es",
                rolUsuario: constants.rolUsuario,
                listaPrecio:
                    userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
            },
        };
        let res = await ProductServices.getProductById(payload);
        return res.detalleProducto.precio;
    };
    const getCalculateShipping = async (_scaleSelected = null, city = null, address = null) => {
        // console.log(_scaleSelected);
        // setLoadingCart(true);
        setPreLoader(true);
        if (!_scaleSelected) {
            calculateTotals();
            return;
        }
        let _totalOrder = 0;
        let _totalOrderFullPrice = 0;
        let _totalIVA = 0;
        const needed = [
            "idProducto",
            "cantidad",
            "precioUnitario",
            "srcImg",
            "title",
            "divisor",
            "porcentajeIva",
            "descuento",
            "IVA"
        ];
        let newObjs = cartItems.map((o) => {
            let tmp = {};
            for (let [key, val] of Object.entries(o)) {
                if (needed.includes(key)) {
                    tmp[key] = val;
                }
            }
            ////console.log(o)

            //  totalOrder += o.precioUnitario.COP * o.cantidad;
            return tmp;
        });
        let toDelete = [];
        const asyncRes = await Promise.all(
            cartItems.map(async (i, index) => {
                let pr = await getProductById(i.idProducto);
                if (pr.COP === 0) {
                    toDelete.push(i);
                    delete newObjs[index];
                    return;
                }
                let IVA = (i.porcentajeIva / 100) + 1;
                let precioSinIVA = Math.round(pr.COP / IVA);
                let _descuentoEscalaProducto = parseInt(i.divisor) > 0 ? parseInt(i.divisor) : _scaleSelected.descuento;
                let porcentajeDescuento = _descuentoEscalaProducto === _scaleSelected.descuento && _scaleSelected.descuento !== 2 ? 0 : _scaleSelected.descuento / _descuentoEscalaProducto;
                console.log(porcentajeDescuento, ' :porcentajeDescuento')
                let descuento = precioSinIVA - (precioSinIVA * (porcentajeDescuento / 100));
                newObjs[index].descuento = _scaleSelected.descuento === 0 ? 0 : descuento;
                newObjs[index].iva = IVA;
                newObjs[index].precioUnitario = pr;
                let tot = _scaleSelected.descuento === 0 ? pr.COP : (descuento * IVA);
                _totalIVA += (tot - (tot / IVA)) * i.cantidad;
                _totalOrder += tot * i.cantidad;
                _totalOrderFullPrice = pr.COP * i.cantidad;
                //console.log(_totalIVA, '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
                return pr;
            })
        );
        if (Object.entries(newObjs).length === 0) {
            newObjs = [];
            Cart.deleteCart();
        }
        setCartItems(newObjs);
        Cart.setCart(JSON.stringify(newObjs));
        let payload = {
            ciudadEnvio: userInfo ? userInfo.ciudad : 1,
            direccion: userInfo ? userInfo.direccionDeEnvio : "",
            pedido: newObjs,
            valorTotalPedido: _totalOrder,
        };
        saveValorTotal(_totalOrder);
        saveTotalIVA(_totalIVA);
        saveTotalFull(_totalOrderFullPrice);
        if (city !== null || address !== null) {
            payload.direccion = address;
            payload.ciudadEnvio = city;
        }
        CheckoutServices.calculateShipping(payload)
            .then((res) => {
                //console.log("Peticion realizada correctamente", res);
                switch (res.estadoRespuesta) {
                    case 0:
                        setCost(res.costo);
                        break;

                    case 2:
                        console.error("Error calculating shipping: ", res);
                        break;

                    default:
                        //console.log("Respuesta calculateShipping: ", res);
                        break;
                }
                setLoadingCart(false);
                setPreLoader(false);
            })
            .catch((err) => {
                //console.log(err);
                setLoadingCart(false);
            });
    }
    const updateAmount = async (id, value) => {
        if ((await chequearDiponibilidad(id, value)) === true) {
            let order_aux = cartItems;
            let i = order_aux.findIndex((item) => {
                return item.idProducto === id;
            });
            order_aux[i].cantidad = value;
            setCartItems(order_aux);
            Cart.setCart(JSON.stringify(order_aux));
            getCalculateShipping();
        } else {
            alert(
                "Ya no hay más unidades disponibles de este producto en el inventario"
            );
        }
    };
    const chequearDiponibilidad = async (productID, cant) => {
        let payload = {
            params: {
                idProducto: productID,
                idioma: "es",
                rolUsuario: constants.rolUsuario,
                listaPrecio:
                    userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
            },
        };
        let res = await ProductServices.getProductById(payload);
        //console.log(res, res.detalleProducto.inventarioDisponible >= cant);
        return res.detalleProducto.inventarioDisponible >= cant;
    };
    /*  const handleGTMGoToPay = () => {
         cartItems.forEach((item) => {
             dataLayer.pushEvent(
                 "CarritoDeCompra",
                 "ClicSeleccionProductoIrAPagar",
                 item.title
             );
         });
     }; */
    const convertToFloat = (element) => {
        let _element = { ...element }
        for (const key in _element) {
            if (Object.hasOwnProperty.call(_element, key)) {
                _element[key] = parseFloat(_element[key]);
            }
        }
        return _element;
    }

    const updateProductList = (_id, cant) => {
        let indexof = cartItems.findIndex((item) => {
            return item.idProducto === _id;
        });
        cartItems.splice(indexof, 1);
        setCartItems(cartItems);
        Cart.setCart(JSON.stringify(cartItems));
        //console.log(cartItems);
        getCalculateShipping();
    };
    return <div className="o__products">
        <div className={props.showButton ? "o__products-cont" : ""}>
            {cartItems && cartItems.map((item, index) => (
                <CartCardProduct
                    key={index}
                    idProduct={item.idProducto}
                    srcImg={item.srcImg}
                    title={item.title}
                    fullPrice={item.precioUnitario}
                    getProductById={getProductById}
                    discount={item.descuento * item.iva}
                    amount={parseInt(item.cantidad)}
                    iva={item.iva}
                    updateValue={updateAmount}
                    updateProductList={updateProductList}
                />
            ))}
        </div>
        <div className="o__info-purchase">
            {!preLoader ?
                <div className="total-txt">
                    <span>{t("pages.shopping-cart.total-value")} </span>{" "}
                    <p>
                        ${cost.pedido ? FormatNumber(cost.pedido.COP) : "0"}
                        <small className="o__currency_usd">
                            {" "}
                            {/* 
                        / {Number(cost.pedido ? cost.pedido.USD : "0")} USD */}
                        </small>
                    </p>
                </div>
                :
                <img src={preLoaderIcon} style={{ maxWidth: 50 }} />

            }
            {props.showButton &&
                <>
                    {!preLoader ?
                        <Button
                            classnames="gtmCarritoDeCompraIrAPagar primary"
                            text={t("pages.shopping-cart.btn-go-to-pay")}
                            type="button"
                            href={`/checkout/1`}
                            onclick={() => {
                                handleHideModal();
                                history.push(`/checkout/1`)
                            }}
                        ></Button>
                        :
                        <img src={preLoaderIcon} style={{ maxWidth: 50 }} />
                    }
                </>
            }
            <Spacer px={15} />
        </div>
        <FreeShippingBar
            freeShippingPrice={
                cost.valorParaEnvioGratis ? cost.valorParaEnvioGratis : {}
            }
            missingPrice={
                cost.faltanteParaEnvioGratis
                    ? cost.faltanteParaEnvioGratis
                    : {}
            }
        />
    </div>
}
const mapStateToProps = ({
    rdc_interface,
    rdc_account,
    rdc_product,
    rdc_cart,
}) => ({
    rdc_interface,
    rdc_account,
    rdc_product,
    rdc_cart,
});

const mapDispatchToProps = (dispatch) => ({
    handleHideModal() {
        dispatch(closeModal());
    },
    getCart,
    saveValorTotal(payload) {
        dispatch(setValorTotal(payload))
    },
    saveTotalIVA(payload) {
        dispatch(setTotalIVA(payload))
    },
    saveTotalFull(payload) {
        dispatch(setTotalFull(payload))
    },
    saveScaleSelected(payload) {
        dispatch(setScaleSelected(payload))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CartRender);