import React from "react";
import PropTypes from "prop-types";
import Image from "../../atoms/Image/Image";
import dataLayer from "../../../common/GTM/dataLayer";
import Currency from "../../../common/LocalStorage/Currency";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Zoom from "react-img-zoom";
const Card = (props) => {
  const {
    imagetype,
    image_src,
    alt,
    classname,
    text,
    cardtype,
    id,
    precio,
    precioAnterior,
    precioConDescuento,
    href,
    reload,
  } = props;
  const currency = Currency.getCurrency() ? Currency.getCurrency() : "COP";
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);
  const getPrice = () => {
    //  console.log(precio);
    if (precio) {
      if (currency === "USD") {
        return precio.USD;
      } else {
        return precio.COP;
      }
    } else {
      return 0;
    }
  };

  const getBeforePrice = () => {
    if (precioAnterior) {
      if (currency === "USD") {
        return precioAnterior.USD;
      } else {
        return precioAnterior.COP;
      }
    } else {
      return 0;
    }
  };

  const getPriceWithDiscount = () => {
    if (precioConDescuento) {
      if (currency === "USD") {
        return precioConDescuento.USD;
      } else {
        return precioConDescuento.COP;
      }
    } else {
      return 0;
    }
  };

  const discount = () => {
    let diff = getBeforePrice() - getPriceWithDiscount();
    return Number(((diff / getBeforePrice()) * 100).toFixed(1));
  };

  const finalprice = new Intl.NumberFormat("csn", {
    style: "currency",
    currency: currency,
  }).format(Number(getPrice()));

  const outletprice = new Intl.NumberFormat("csn", {
    style: "currency",
    currency: currency,
  }).format(Number(getPriceWithDiscount()));

  const handleGTM = (value, url, _reload = null) => {
    if (value && url) {
      dataLayer.pushEvent("Productos", "ClicVerDetalleProductos", value);
      dataLayer.pushEvent(
        "Productos",
        "ClicVerDetalleUrl",
        "https://unitecusa.com.co/" + url
      );
    }
    if (_reload) {
      setTimeout(() => {
        window.location = url;
      }, 1);
    }
  };

  return cardtype === "outlet" && !loading ? (
    <figure id={id} className={"m__card m__card--outlet"}>
      <Link
        onClick={() => handleGTM(text, href)}
        className={`gtmProductosVerDetalle${text.replace(
          /\s/g,
          ""
        )} m__cardlink`}
        to={href ? href : "#"}
      >
        <Image
          type={imagetype}
          src={image_src}
          alt={alt}
          classname={`gtmProductosVerDetalle${text.replace(
            /\s/g,
            ""
          )} ${classname}`}
        />
        {cardtype !== "image" && (
          <figcaption>
            <p>{text}</p>
            <>
              <span className="strikethrough-price">
                {" "}
                {`$${getBeforePrice()}`}
              </span>
              {currency === "COP" ? (
                <span> {`$${outletprice}`}</span>
              ) : (
                <span> {`$${getPriceWithDiscount()} ${currency}`}</span>
              )}
            </>
          </figcaption>
        )}
              <div className="discount-label"></div>
      </Link> 
    </figure>
  ) : cardtype === "subcategory" && !loading ? (
    <figure id={id} className="m__card m__card--outlet">
      <Link
        onClick={() => handleGTM(text, href)}
        className={`gtmProductosVerDetalle${text.replace(
          /\s/g,
          ""
        )} m__cardlink`}
        to={href ? href : "#"}
      >
        <Image
          type={imagetype}
          src={image_src}
          alt={alt}
          classname={`gtmProductosVerDetalle${text.replace(
            /\s/g,
            ""
          )} ${classname}`}
        />
        {cardtype !== "image" && (
          <figcaption>
            <p>{text}</p>
            {currency === "COP" ? (
              <span> {`$${finalprice}`}</span>
            ) : (
              <span> {`$${getPrice()} ${currency}`}</span>
            )}
          </figcaption>
        )}
      </Link>
    </figure>
  ) : (
    <figure id={id} className="m__card">
      <Link
        onClick={() => text && handleGTM(text, href, reload)}
        className={`${
          text
            ? `gtmProductosVerDetalle${text.replace(/\s/g, "")} m__cardlink`
            : "m__cardlink"
        }`}
        to={href ? href : "#"}
      >
        {cardtype === "product-detail" ? (
          <>
            
            <Zoom img={image_src} zoomScale={2} width={window.innerWidth > 1024 ? 450 : 250} height={window.innerWidth > 1024 ? 450 : 250} />
          </>
        ) : (
          <>
            <Image
              type={imagetype}
              src={image_src}
              alt={alt}
              classname={`${
                text
                  ? `gtmProductosVerDetalle${text.replace(
                      /\s/g,
                      ""
                    )} ${classname}`
                  : classname
              }`}
            />
          </>
        )}

        {/* <Magnifier src={image_src} zoomFactor={1.8} /> */}
        {cardtype !== "image" && (
          <figcaption>
            <p>{text}</p>
            {cardtype === "subcategory" ? <span> {`$${precio}`}</span> : null}
          </figcaption>
        )}
      </Link>
      {cardtype === "image" && (
        <Link className="m__cardlink-text" to={href ? href : "#"}>
          {text ? text : "SIN NOMBRE"}
          {precio && (
            <>
              {currency === "COP" ? (
                <h4 className="t__price-number"> {`$${finalprice}`}</h4>
              ) : (
                <h4 className="t__price-number">
                  {" "}
                  {`$${getPrice()} ${currency}`}
                </h4>
              )}
            </>
          )}
        </Link>
      )}
    </figure>
  );
};

Card.propTypes = {
  image_src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string,
  imagetype: PropTypes.string.isRequired,
  cardtype: PropTypes.string.isRequired,
  classname: PropTypes.string,
  precio: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  descuento: PropTypes.number,
};
export default Card;
