import * as types from './../constants/actionConstants';
import Cart from "../../common/LocalStorage/Cart";

export const getCart = () => {
  return dispatch => {
    return dispatch({
      type: types.GET_CART,
      payload: Cart.getCart() ? JSON.parse(Cart.getCart()) : null
    })
  }
};