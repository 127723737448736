export class Currency {

  getCurrency() {
    return localStorage.getItem('u-currency');
  }

  setCurrency(currency) {
    return localStorage.setItem('u-currency', currency);
  }
}

export default new Currency();