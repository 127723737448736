import { combineReducers } from 'redux'

import products from '../services/Products/ProductsReducer';
import rdc_interface from '../state/reducers/rdc_interface';
import rdc_account from '../state/reducers/rdc_account';
import rdc_product from '../state/reducers/rdc_product';
import rdc_category from '../state/reducers/rdc_category';
import rdc_cart from '../state/reducers/rdc_cart';

const appReducer = combineReducers({
    products,
    rdc_interface,
    rdc_account,
    rdc_product,
    rdc_category,
    rdc_cart
})

const rootReducer = (state, action) => {
    /*  if (action.type === Types.LOGOUT) state = undefined; */
    return appReducer(state, action);
};

export default rootReducer;
