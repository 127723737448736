const url = 'https://servicios.unitecusaonline.com.co/'

//const url = 'https://prueba-servicios.unitecusaonline.com.co/'

//Home
export const getProducts = url + 'obtenerproductos.php';
export const getDetailProduct = url + 'obtenerdetalleproducto.php';
export const getProductsHome = url + 'obtenerproductoshome.php';
export const getSearch = url + 'obtenerresultados.php';
export const getFeaturedProducts = url + 'obtenerproductosdestacados.php';
export const getTrendingProducts = url + 'obtenerproductostendencias.php';
export const getOutletProducts = url + 'obtenerproductosoutlet.php';
export const getCategories = url + 'obtenercategorias.php';
export const downloadCatalogue = url + 'descargarcatalogos.php';
export const processPayment = url + 'requerimientodepago.php';
export const processPaymentWithCredit = url + 'pagomixto.php';
export const paymentStatus = url + 'consultarestadopago.php';
export const paymentHistory = url + 'obtenerhistorialdepagos.php';
export const solicitudCredito = url + 'solicitudcredito.php';
export const carritosAbandonados = url + 'carritosabandonados.php';
export const productosRelacionados = url + 'productosrelacionados.php';
export const getOrderDetail = url + 'solicitarpedidos.php';
export const payDebs = url + 'pagocartera.php';
export const newSections = url + 'obtenersecciones.php';
/* las escalas reciben el rolUsuario y lista de precios */
export const getScalas = url + 'solicitarescalas.php';

//Register
export const postRegister = url + 'registrarusuariofinal.php';
export const registerReferral = url + 'clientereferido.php';
export const getReferral = url + 'solicitarreferidos.php';

// Login
export const postLogin = url + 'logueo.php';

// Recover password
export const postSendPassword = url + 'reiniciarcontrasena.php';
export const postRecoverPass = url + 'modificarcontrasena.php';
export const verificarToken = url + 'verificartoken.php';
export const modificarPass = url + 'guardarcontrasena.php';
export const recordarmail = url + 'recordarmail.php';
export const recuperarcontrasena = url + 'recuperarcontrasena.php';



//Country, department, city, and city and type of document
export const getCountry = url + 'obtenerpais.php';
export const getDepartment = url + 'obtenerdepartamento.php';
export const getCity = url + 'obtenerciudad.php';
export const getIdPais = url + 'obtenertipoidpais.php';

// CPQRS
export const cpqrs = url + 'cpqrs.php';

// Checkout
export const calculateShipping = url + 'calcularenvio.php';
export const reportPaymentCredit = url + 'notificarpagocredito.php';

//Cartera
export const solicitarCartera = url + 'solicitarcartera.php';
export const cupoDisponible = url + 'cupodisponible.php';

//Manifiestos
export const manifiestos = url + 'manifiestos.php';