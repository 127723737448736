import React from "react";
import { connect } from "react-redux";
import ForeignSalesTemplate from "./../templates/ForeignSalesTemplate/ForeignSalesTemplate";

class ForeignSales extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <ForeignSalesTemplate location={this.props.location}/>
    );
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForeignSales);
