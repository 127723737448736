import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../atoms/Button/Button";
import InputPrimary from "../../atoms/InputPrimary/InputPrimary";
import Spacer from "../../atoms/Spacer/Spacer";
import { Link } from "react-router-dom";
import Loader from "../../atoms/Loader/Loader";
import { ToastContainer, toast, Slide } from "react-toastify";
import dataLayer from "../../../common/GTM/dataLayer";
import { useTranslation } from "react-i18next";
import Token from "../../../common/LocalStorage/Token";
import { useHistory } from "react-router-dom";
import * as constants from "../../../state/constants/appConstants";
//Service
import RegisterServices from "../../../services/RegisterServices/RegisterServices";

const ReferralFormTemplate = (props) => {
  const { t } = useTranslation();
  let token = Token.getToken();
  const getCurrentLng = () =>
    useTranslation.language || localStorage.getItem("i18nextLng") || "";

  const defaultForm = {
    firstName: "",
    lastName: "",
    celphone: "",
    email: "",
  };
  const [formData, setFormData] = useState(defaultForm);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [celphoneError, setCelphoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isErrorinForm, setIsErrorinForm] = useState(false);
  const [isRegisterOk, setIsRegisterOk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push("/login");
    } else {
      setUserInfo(
        JSON.parse(localStorage.getItem(`userInfo_${constants.rolUsuario}`))
      );
    }
  }, []);
  const reset = () => {
    setFormData(defaultForm);
  };
  const registerAccount = async () => {
    setIsLoading(true);
    let payload = {
      nombres: formData.firstName,
      apellidos: formData.lastName,
      celular: formData.celphone,
      mail: formData.email,
      rol: constants.rolUsuario,
      vendedor: userInfo.document,
    };
    // console.log(payload);
    let res = await RegisterServices.registerReferral(payload);
    console.log(res);
    if (res.data.estado[0] === "no") {
      toast.error(
        t("pages.register.error-register") + res.data.mensajeDeError,
        {
          className: "toast-u-container",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          transition: Slide,
        }
      );
    } else if (res.data.estado[0] === "si") {
      reset();
      setIsRegisterOk(true);
      toast.success(t("pages.register.succefull-register"), {
        className: "toast-u-container",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        transition: Slide,
      });
      dataLayer.pushEvent("Registro", "ClicFormulario", "EnviarExitoso");
      dataLayer.pushEvent("Registro", "ClicFormularioCorreo", formData.email);
      /* setTimeout(() => {
        openLoginModal();
      }, 500); */
    } else {
      toast.error(t("pages.register.service-error"), {
        className: "toast-u-container",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        transition: Slide,
      });
      console.error(res.data);
    }
    setIsLoading(false);
    /* })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      }); */
  };

  const openLoginModal = () => {
    props.handleswitchmodal("login-modal");
  };
  const eliminateLastChart = (text) => {
    let obj = {
      rest: text.slice(0, -1),
      last: text.slice(-1),
    };
    return obj;
  };
  const validateErrors = () => {
    if (formData.firstName === "" || formData.firstName === " ") {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (formData.lastName === "" || formData.lastName === " ") {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }

    if (
      formData.celphone === "" ||
      formData.celphone > 9999999999 ||
      formData.celphone < 1000000000
    ) {
      setCelphoneError(true);
    } else {
      setCelphoneError(false);
    }

    if (
      formData.email === "" ||
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    return (
      !isChecked ||
      formData.firstName === "" ||
      formData.firstName === " " ||
      formData.celphone === "" ||
      formData.celphone > 9999999999 ||
      formData.celphone < 1000000000 ||
      formData.email === "" ||
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateErrors()) {
      setIsErrorinForm(false);
      registerAccount();
    } else {
      setIsErrorinForm(true);
    }
  };

  /* Eliminate no numerics charts */

  const handleOnChange = (node, event) => {
    console.log(node);
    switch (node) {
      case "firstName":
        setFormData({ ...formData, firstName: event.target.value });
        if (
          !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(
            event.target.value
          )
        ) {
          setFirstNameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({
            ...formData,
            firstName: w.rest.length > 0 ? w.rest : "",
          });
          setTimeout(() => {
            setFirstNameError(false);
          }, 2000);
        } else {
          setFirstNameError(false);
        }
        break;

      case "lastName":
        setFormData({ ...formData, lastName: event.target.value });
        if (
          !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(
            event.target.value
          )
        ) {
          setLastNameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({
            ...formData,
            lastName: w.rest.length > 0 ? w.rest : "",
          });
          setTimeout(() => {
            setLastNameError(false);
          }, 2000);
        } else {
          setLastNameError(false);
        }
        break;

      case "celphone":
        setFormData({
          ...formData,
          celphone: event.target.value.replace(/ /g, ""),
        });
        break;
      case "email":
        setFormData({
          ...formData,
          email: event.target.value.replace(/ /g, ""),
        });
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
            event.target.value
          )
        ) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }
        break;

      default:
        setFormData({ ...formData });
        break;
    }
  };

  const handleOnChangeCheck = () => {
    setIsChecked(!isChecked);
  };
  return (
    <section className="p_contactUs">
      <Spacer px={40} />
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="container">
          <ToastContainer />
          <Spacer px={30} />
          <h1 className="max-width-800">Registro de referidos</h1>
          <Spacer px={20} />
          <div className="white-card-bkg">
            {userInfo && (
              <h3 style={{ textAlign: "center" }}>
                Su Código de vendedor es:{" "}
                <span
                  style={{
                    color: "#00B3F8",
                  }}
                >
                  {userInfo.document}
                </span>
              </h3>
            )}
            <form className="max-width-800">
              <Spacer px={30} />
              <div className="row">
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.checkout.step-2.name")}`}
                    value={formData.firstName}
                    type={"text"}
                    placeholder={t("pages.checkout.step-2.name-placeholder")}
                    onchange={(e) => {
                      handleOnChange("firstName", e);
                    }}
                    isUserError={firstNameError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.checkout.step-2.surname")}`}
                    value={formData.lastName}
                    type={"text"}
                    placeholder={t("pages.checkout.step-2.surname-placeholder")}
                    onchange={(e) => {
                      handleOnChange("lastName", e);
                    }}
                    isUserError={lastNameError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.celphone")}`}
                    value={formData.celphone}
                    type={"number"}
                    placeholder={t("pages.register.celphone-placeholder")}
                    onchange={(e) => {
                      handleOnChange("celphone", e);
                    }}
                    isUserError={celphoneError}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InputPrimary
                    label={`* ${t("pages.register.email")}`}
                    value={formData.email}
                    type={"text"}
                    placeholder={t("pages.register.email-placeholder")}
                    onchange={(e) => {
                      handleOnChange("email", e);
                    }}
                    isUserError={emailError}
                  />
                </div>
              </div>
            </form>
            <Spacer px={35} />
            <div className="center-content">
              <div className="a__check-box">
                <label className="">
                  {t("pages.register.accept")}{" "}
                  <Link to="/terms-and-conditions">
                    {t("pages.register.terms-and-conditions")}
                  </Link>
                  <input
                    className="gtmRegistroTerminosCondicionesAceptar"
                    type="checkbox"
                    onClick={(e) => handleOnChangeCheck()}
                  ></input>
                  <span></span>
                </label>
              </div>
              <Spacer px={15} />
              <Button
                classnames="gtmRegistroFormularioEnviar primary"
                text="enviar"
                type="button"
                onclick={(e) => handleSubmit(e)}
              ></Button>
              {isErrorinForm && (
                <div>
                  <p className="msj-error">
                    Todos los campos con * son necesarios
                  </p>
                  {!isChecked && (
                    <p className="msj-error">
                      Por favor acepte los Términos y Condiciones
                    </p>
                  )}
                </div>
              )}
              {isRegisterOk && (
                <div>
                  <p className="msj-success">
                    {t("pages.register.succefull-register")}
                  </p>
                </div>
              )}
              <Spacer px={50} />
            </div>
          </div>
          <Spacer px={70} />
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralFormTemplate);
