import React, { useState, useEffect, useRef } from "react";
//import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductServices from '../../../services/Products/ProductServices';
import Spacer from "../../atoms/Spacer/Spacer";
import FormatNumber from "../../../utils/FormatNumber";
import UserInfo from '../../../common/LocalStorage/UserInfo';
import Loader from "../../atoms/Loader/Loader";
import Button from "../../atoms/Button/Button";
import { ToastContainer, toast, Slide } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Col, Image, Modal, Row } from "react-bootstrap";
import InputPrimary from "../../atoms/InputPrimary/InputPrimary";
import CountryServices from "../../../services/CountryServices/CountryServices";
import CheckoutServices from "../../../services/CheckoutServices/CheckoutServices";
import Currency from '../../../common/LocalStorage/Currency';
import Cart from '../../../common/LocalStorage/Cart';
import PaymentServices from "../../../services/PaymentServices/PaymentServices";
import Token from '../../../common/LocalStorage/Token';
import * as constants from "./../../../state/constants/appConstants";
import preLoaderIcon from "../../../assets/images/preloader.gif";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const DebsToPay = () => {
  //const { t } = useTranslation();
  const classes = useStyles();
  const [bills, setBills] = useState();
  const [orderDetails, setOrderDetails] = useState([]);
  const [modalStarted, setModalStarted] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const userInfo = JSON.parse(UserInfo.getUser()) || [];
  const [loading, setLoading] = useState(false);
  const [totalBillsToPay, setTotalBillsToPay] = useState([]);
  const [totalToPay, setTotalToPay] = useState(0);
  const { t } = useTranslation();
  const [creditValDefault, setCreditValDefault] = useState(0);;
  const [creditChecked, setCreditChecked] = useState(false);

  //console.log(userInfo);
  const defaultFormData = {
    name: userInfo ? userInfo.nombre : "",
    surname: userInfo ? userInfo.apellido : "",
    email: userInfo ? userInfo.email : "",
    typedocument: userInfo ? userInfo.tipodocumento : "",
    identification: userInfo ? userInfo.document : "",
    celphone: userInfo ? userInfo.celular : "",
    country: userInfo ? userInfo.pais : "",
    department: userInfo ? userInfo.departamento : "",
    city: userInfo ? userInfo.ciudad : "",
    address: (userInfo) ? userInfo.direccionDeEnvio : "",
    observation: ""
  };
  ////////////////
  const [order, setOrder] = useState(Cart.getCart() ? JSON.parse(Cart.getCart()) : []);
  const currency = Currency.getCurrency() ? Currency.getCurrency() : 'COP';
  const [formData, setFormData] = useState(defaultFormData);
  const [listCity, setListCity] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [partialBills, setPartialBills] = useState([]);
  const [actualTarget, setActualTarget] = useState();
  const [documentTypes, setDocumentTypes] = useState([]);
  const defaultEnvio = {
    pedido: {
      COP: 0,
      USD: 0
    },
    envio: {
      COP: 0,
      USD: 0
    },
    faltanteParaEnvioGratis: {
      COP: 0,
      USD: 0
    }
  };
  const [typeDocumentError, setTypeDocumentError] = useState(false);
  const [identificationError, setIdentificationError] = useState(false);
  const [celphoneError, setCelphoneError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [observationError, setObservationError] = useState(false);
  const [isErrorinForm, setIsErrorinForm] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [alertDoublePay, setAlertDoublePay] = useState(<></>);
  const [totalPrice, setTotalP] = useState(0);
  const [calculoEnvio, setCalculoEnvio] = useState(defaultEnvio);
  const [partialModal, setPartialModal] = useState(false);
  const [balance, setBalance] = useState();
  const [smallLoader, setSmallLoader] = useState(false);


  const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || 'es';

  useEffect(() => {
    const getDebsToPay = async () => {

      let payload = {
        usuario: userInfo.document
      };
      // console.log(payload); 
      const result = await CheckoutServices.getDebsToPay(payload);
      setBills(result)
      if (result.estadoRespuesta === 0) {
        console.log("Payment history:", result);
        //    setbills(result.pagos);
      } else {
        console.warn("Error products home: ", result);
      }
    };

    getDebsToPay();
    const loadCountry = () => {
      let payload = {
        params: {
          idioma: getCurrentLng()
        }
      };
      CountryServices.getListCountry(payload).then(res => {
        setListCountry(res.paises);
      }).catch(err => console.log(err));
    };

    loadCountry();
    if (userInfo.pais) {
      loadDepartments(userInfo.pais);
      loadCity(userInfo.departamento);
      loadTypeDocuments(userInfo.pais)
    }
  }, []);
  useEffect(() => {
    const getBalanace = async () => {
      let payload = {
        nit: userInfo?.document
      }
      let res = await CheckoutServices.getBalance(payload);
      setBalance(res.cupodisponible)
    }
    if (!balance && userInfo?.document) {
      getBalanace();
    }
  }, [])

  /* Eliminate no numerics charts */
  const purgueNumerics = (text) => {
    var r = /\d+/g;
    var m;
    let ret = "";
    while ((m = r.exec(text)) != null) {
      ret += m[0];
    }
    return ret;
  }
  const handleAddressBlur = event => {
    getCalculateShipping(null, event.target.value);
  };
  const setTotalPrice = () => {
    if (currency === 'USD') {
      setTotalP(calculoEnvio.pedido.USD + calculoEnvio.envio.USD);
    } else {
      setTotalP(calculoEnvio.pedido.COP + calculoEnvio.envio.COP);
    }
  };
  const getCalculateShipping = (city = null, address = null) => {
    setLoadingCart(true);
    let totalOrder = 0;
    const needed = ["idProducto", "cantidad", "precioUnitario"];
    const newObjs = order.map(o => {
      let tmp = {};
      for (let [key, val] of Object.entries(o)) {
        if (needed.includes(key)) {
          tmp[key] = val;
        }
      }
      totalOrder += o.precioUnitario.COP * o.cantidad;
      return tmp;
    });
    let payload = {
      ciudadEnvio: userInfo ? userInfo.ciudad : 1,
      direccion: userInfo ? userInfo.direccionDeEnvio : "",
      pedido: newObjs,
      valorTotalPedido: totalOrder
    };
    if (city !== null || address !== null) {
      payload.direccion = address ? address : formData.address;
      payload.ciudadEnvio = city ? city : formData.city;
    }
    CheckoutServices.calculateShipping(payload).then(res => {
      //console.log("Peticion realizada correctamente", res);
      switch (res.estadoRespuesta) {
        case 0:
          setCalculoEnvio(res.costo);
          setTotalPrice();
          break;
        case 2:
          console.error("Error calculating shipping: ", res);
          break;

        default:
          console.log("Respuesta calculateShipping: ", res);
          break;
      }
      setLoadingCart(false);
    }).catch(err => console.log(err));
  }
  const regexByTypeId = () => {
    let obj = {
      regex: null,
      minLength: 0,
      maxLength: 0,
    }
    /// console.log(formData.typedocument);
    let i = parseInt();
    switch (formData.typedocument) {
      case 'CC':
        obj.regex = /^[1-9][0-9]{6,9}$/;
        obj.minLength = 6;
        obj.maxLength = 9;
        return obj;

      case 'NIT':
        obj.regex = /^[1-9]\d{6,9}$/;
        obj.minLength = 3;
        obj.maxLength = 7;
        return obj;

      case 'CEDULA EXTRANJERIA':
        obj.regex = /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/;
        obj.minLength = 4;
        obj.maxLength = 11;
        return obj;

      default:
        return obj
        break;
    }
  }
  const handleClose = () => {
    setModalStarted(false);
  }
  const handleClosePartialMode = () => {
    setPartialModal(false);
  }
  const validateErrors = () => {
    if (formData.name === "" || !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.name)) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (formData.surname === "" || !/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.surname)) {
      setSurnameError(true);
    } else {
      setSurnameError(false);
    }

    if (formData.email === "" || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (formData.typedocument === "") {
      setTypeDocumentError(true);
    } else {
      setTypeDocumentError(false);
    }
    if (!userInfo) {
      if (formData.identification === "" || !regexByTypeId().regex.test(formData.identification)) {
        setIdentificationError(true);
      } else {
        setIdentificationError(false);
      }
    }

    if (formData.celphone === "") {
      setCelphoneError(true);
    } else {
      setCelphoneError(false);
    }

    if (formData.country === "") {
      setCountryError(true);
    } else {
      setCountryError(false);
    }

    if (formData.department === "") {
      setDepartmentError(true);
    } else {
      setDepartmentError(false);
    }

    if (formData.city === "") {
      setCityError(true);
    } else {
      setCityError(false);
    }

    if (formData.address === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    return (
      (formData.name === "") || (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.name)) ||
      (formData.surname === "") || (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.surname)) ||
      (formData.email === "") || (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) ||
      (formData.typedocument === "") ||
      (formData.identification === "") || (!/^[1-9][0-9]{3,9}$/.test(formData.identification)) ||
      (formData.celphone === "") ||
      (formData.country === "") ||
      (formData.department === "") ||
      (formData.city === "") ||
      (formData.address === "") /* ||
      (formData.observation === "") */
    );
  };
  const eliminateLastChart = (text) => {
    let obj = {
      rest: text.slice(0, -1),
      last: text.slice(-1)
    }
    return obj;
  }
  const flickerError = (method) => {
    method(true);
    setTimeout(() => {
      method(false)
    }, 1000);
  }
  const isNumeric = (text) => {
    return /^[0-9]\d*$/.test(text);
  }
  const loadDepartments = (country) => {
    let payload = {
      params: {
        idPais: country
      }
    };
    CountryServices.getListDepartment(payload).then(res => {
      setListDepartment(res.departamentos);
    }).catch(err => console.log(err));
  };
  const goHandlePayment = async () => {
    if (validateErrors() === false && !loadingCart) {
      setSmallLoader(true);
      setLoadingCart(true);
      let cloneItems = []
      try {
        /*   order.map((item) => {
            cloneItems.push({ ...item });
          }); */

        let payload = {
          recaudo: totalBillsToPay,
          datosEnvio: {
            pais: formData.country,
            departamento: formData.department,
            ciudad: formData.city,
            direccion: formData.address,
            observaciones: formData.observation
          },
          datosComprador: {
            nombre: formData.name,
            apellido: formData.surname,
            email: formData.email,
            tipoDocumento: formData.typedocument,
            numeroDocumento: formData.identification,
            numeroTelefonoMovil: formData.celphone
          },
          moneda: currency
        };
        // console.log(payload);
        const result = await PaymentServices.payDebs(payload);
        console.log("Resultado pago placetopay: ", result);
        if (result.estadoRespuesta === 0) {
          setSmallLoader(false)
          window.location = result.datosProcesoPago.urlProcesamiento;
        } else if (result.estadoRespuesta === 2) {
          toast.error(`${result.mensajeDeError}, por favor intente más tarde`, {
            className: "toast-u-container",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide
          });
          setSmallLoader(false)
          setModalStarted(false);
          //   setAlertDoublePay(<p className="p_uni_alert">{`En estos momentos hay un pago pendiente con número de referencia: ${result.mensajeDeError.split(':')[1].split(';')[1]} por favor intente más tarde`}</p>)
        } else {
          console.warn("Error en la respuesta: ", result);

        }
        setLoadingCart(false);
      } catch (error) {
        console.error(error);
        setLoadingCart(false);
      }
    } else {
      window.scrollTo(0, 300);
      setIsErrorinForm(true);
    }
  };
  const loadCity = (department) => {
    let payload = {
      params: {
        idPais: formData.country,
      }
    };
    CountryServices.getListCity(payload).then(res => {
      let cities = [];
      for (const city of res.ciudades) {
        if (city.codigoDepartamento === department) {
          cities.push(city)
        }
      }
      setListCity(cities);
    }).catch(err => console.log(err));
  };
  const loadTypeDocuments = (value) => {
    let payload = {
      params: {
        idPais: value,
      }
    };
    CountryServices.getListIdCountry(payload).then(res => {
      setDocumentTypes(res.tiposId);
    })
  }
  const handleOnChange = (node, event) => {
    switch (node) {
      case "name":
        setFormData({ ...formData, name: event.target.value });
        if (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(event.target.value)) {
          setNameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({ ...formData, name: w.rest });
        } else {
          setNameError(false);
        }
        break;
      case "surname":
        setFormData({ ...formData, surname: event.target.value });
        if (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(event.target.value)) {
          setSurnameError(true);
          let w = eliminateLastChart(event.target.value);
          setFormData({ ...formData, surname: w.rest });
        } else {
          setSurnameError(false);
        }
        break;
      case "email":
        setFormData({ ...formData, email: event.target.value });
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
          setEmailError(true);

        } else {
          setEmailError(false);
        }
        break;
      case "typedocument":
        setFormData({ ...formData, typedocument: event.target.value });
        setTypeDocumentError(false)
        break;
      case "identification":
        if (formData.typedocument) {
          let obj = regexByTypeId();
          let textPurgued = purgueNumerics(event.target.value);
          //return
          if (!obj.regex.test(event.target.value)) {
            let w = eliminateLastChart(event.target.value);
            setFormData({ ...formData, identification: (textPurgued && obj.maxLength >= event.target.value.length) ? textPurgued : w.rest });
            if (!isNumeric(w.last)) {
              flickerError(setIdentificationError);
            }
          } else {
            setFormData({ ...formData, identification: event.target.value });
            setIdentificationError(false)
          }
        } else {
          setTypeDocumentError(true)
        }
        break;
      case "celphone":
        setFormData({ ...formData, celphone: event.target.value });
        break;
      case "country":
        setFormData({ ...formData, country: event.target.value });
        loadDepartments(event.target.value);
        loadTypeDocuments(event.target.value);
        break;
      case "department":
        setFormData({ ...formData, department: event.target.value.replace(/ /g, " ") });
        loadCity(event.target.value);
        break;
      case "city":
        setFormData({ ...formData, city: event.target.value });
        getCalculateShipping(event.target.value, null);
        break;
      case "address":
        setFormData({ ...formData, address: event.target.value.replace(/ /g, " ") });
        break;
      case "observation":
        setFormData({ ...formData, observation: event.target.value.replace(/ /g, " ") });
        break;
      default:
        setFormData({ ...formData });
        break;
    }
  };
  const getOrder = async (pedido) => {
    setOrderDetails([]);
    let arr = [];
    setLoading(true);
    for (let index = 0; index < pedidos.length; index++) {
      const element = pedidos[index];
      if (element.numero === pedido) {
        let newElement = { ...element };
        let obj = await getProductByID(element.producto);
        newElement.productName = obj.name;
        newElement.url = obj.url;
        arr.push(newElement);
      }
    }
    setOrderDetails(arr);
    setLoading(false);
    setModalStarted(true);

  }
  const getProductByID = async (id) => {
    let payload = {
      params: {
        idProducto: id,
        idioma: 'es',
        rolUsuario: constants.rolUsuario,
        listaPrecio: (userInfo && userInfo.listaPrecio) ? userInfo.listaPrecio : 20,
      }
    };
    //  console.log(cant);
    let res = await ProductServices.getProductById(payload);
    console.log(res);
    let obj = {
      url: `/product${res.detalleProducto.slug}/${res.detalleProducto.id}`,
      name: res.detalleProducto.nombre
    }
    return obj;
  }

  const sumBilssToPay = (event, factura, valor) => {
    let arr = [...totalBillsToPay];
    let partialValue = document.getElementById(factura).value;
    if (partialValue > 0) {
      valor = parseInt(partialValue);
    }
    if (event.target.checked) {
      arr.push({ factura, valor });
    } else {
      arr = totalBillsToPay.filter(element => element.factura !== factura);
    }
    setTotalBillsToPay(arr);
    let total = 0
    arr.forEach(element => {
      total = total + element.valor;
    });
    setTotalToPay(total);
    // console.log(arr);
  }
  const handlePayment = (e) => {
    if (totalToPay > 0) {
      setModalStarted(true);
    } else {
      toast.warning('Por favor seleccione una o varias facturas a pagar', {
        className: "toast-u-container",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        transition: Slide
      })
    }
  }

  const checkSpecialCharts = (targetID, valor) => {
    let value = document.getElementById(targetID).value;
    if (value.length > 0) {
      if (!/^[0-9]*$/.test(value)) {
        value = value.slice(0, -1);
      }
    }
    //.alert(value)
    if (value <= valor) {
      document.getElementById(targetID).value = value;
    } else {
      toast.warning(`El valor parcial no debe ser mayor al saldo total a pagar que es de: $${valor}`, {
        className: "toast-u-container",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        transition: Slide
      });
      document.getElementById(targetID).value = valor;

    }
    let ch = document.getElementById(`ch_${targetID}`);
    if (ch.checked === true) {
      let arr = [...totalBillsToPay];
      let total = 0
      arr.forEach(element => {
        if (element.factura === targetID) {
          element.valor = parseInt(value);
        }
        total = total + element.valor;
      });
      setTotalToPay(total);
    }
  }
  return (
    <section className="u-bills-history">
      <ToastContainer />
      <Spacer px={50} hideon="small" />
      {loading === true &&
        <Loader />
      }
      <div className="container">
        <Spacer px={20} hideon="small" />
        <Row>
          <Col>
            <h1>Cartera / Cuentas por pagar</h1>
            <p>Por favor seleccione las facturas que desea pagar y luego darle clic en el botón Pagar</p>
          </Col>
          {balance &&
            <Col>
              <h3 style={{ textAlign: 'right' }}>Cupo disponible: {`$${FormatNumber(balance)}`}</h3>
            </Col>
          }
        </Row>
        <Spacer px={20} hideon="small" />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>N° factura</TableCell>
                <TableCell align="center">Fecha factura</TableCell>
                <TableCell align="center">Fecha vencimiento</TableCell>
                <TableCell align="center">Dias de vencida</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Saldo</TableCell>
                <TableCell align="center">Pago parcial</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bills && bills.cartera.map((bill, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <label>
                      <input id={`ch_${bill.factura}`} type="checkbox" onChange={(e) => sumBilssToPay(e, bill.factura, bill.saldo)}></input>
                      <span>{bill.factura}</span>
                    </label>
                  </TableCell>
                  <TableCell align="center">{bill.fechafactura}</TableCell>
                  <TableCell align="center">{bill.fechavencimiento}</TableCell>
                  <TableCell align="center">{bill.diasvencida}</TableCell>
                  <TableCell align="center">{`$${FormatNumber(bill.valor)}`}</TableCell>
                  <TableCell align="center">{`$${FormatNumber(bill.saldo)}`}</TableCell>
                  <TableCell align="center">$ <input type="text" onChange={(e) => checkSpecialCharts(bill.factura, bill.saldo)} className="carteraPagoParcial" id={bill.factura} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="center-content">
          <Spacer px={20} hideon="small" />
          <Button classnames="gtmIniciaSesionRestableceTuContrasena primary" text={`Pagar → $${FormatNumber(totalToPay)}`} type="button" onclick={handlePayment}></Button>
          <Spacer px={50} hideon="small" />
        </div>
      </div>
      <Modal show={modalStarted} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton className="t__closeButton">
        </Modal.Header>
        <Modal.Body>
          <div className="row t__modal-container">
            <div className="col-12">
              <div className="white-card-bkg">
                <div className="max-width-800">
                  <h2>Datos personales</h2>
                  <div className="row" ref={useRef(`${modalStarted}_r`)}>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.name")}
                        type={"text"}
                        value={formData.name}
                        onchange={(e) => handleOnChange("name", e)}
                        placeholder={t("pages.checkout.step-2.name-placeholder")}
                        isUserError={nameError}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.surname")}
                        type={"text"}
                        value={formData.surname}
                        onchange={(e) => handleOnChange("surname", e)}
                        placeholder={t("pages.checkout.step-2.surname-placeholder")}
                        isUserError={surnameError}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.email")}
                        type={"text"}
                        value={formData.email}
                        onchange={(e) => handleOnChange("email", e)}
                        placeholder={t("pages.checkout.step-2.email-placeholder")}
                        isUserError={emailError}
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-6 col-12 ">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.country")} </span>
                        <select
                          className={countryError ? `field-error` : undefined}
                          disabled={true}
                          //defaultValue={"DEFAULT"}
                          onChange={e => {
                            handleOnChange('country', e);
                          }} >
                          <option value={"DEFAULT"} disabled>
                            {t("pages.checkout.step-2.country-placeholder")}
                          </option>
                          {listCountry.map((country, index) => (
                            <option key={index} value={country.codigo} selected={userInfo.pais === country.codigo}>
                              {country.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 ">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.department")} </span>
                        <select
                          className={departmentError ? `field-error` : undefined}
                          disabled={true}
                          //   defaultValue={"DEFAULT"}
                          onChange={e => {
                            handleOnChange('department', e);
                          }}
                          /* disabled={listDepartment.length === 0} */>
                          <option value={"DEFAULT"} disabled>
                            {t("pages.checkout.step-2.department-placeholder")}
                          </option>
                          {listDepartment.map((department, index) => (
                            console.log(userInfo.departamento === department.codigo),
                            <option key={index} value={department.codigo} selected={userInfo.departamento === department.codigo}>
                              {department.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 ">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.city")} </span>
                        <select
                          className={cityError ? `field-error` : undefined}
                          //     defaultValue={"DEFAULT"}
                          disabled={true}
                          onChange={e => {
                            handleOnChange('city', e);
                          }}
                         /*  disabled={listCity.length === 0} */>
                          <option value="DEFAULT" disabled>
                            {t("pages.checkout.step-2.city-placeholder")}
                          </option>
                          {listCity.map((city, index) => (
                            <option key={index} value={city.id} selected={userInfo.ciudad === city.id}>
                              {city.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="a__input-primary a__select-primary">
                        <span> {t("pages.checkout.step-2.document-type")} </span>
                        <select
                          className={typeDocumentError ? `field-error` : undefined}
                          //   defaultValue={"DEFAULT"}
                          onChange={e => {
                            handleOnChange('typedocument', e);
                          }}
                          disabled={true}
                          /* disabled={formData.country.length > 0 || userInfo.tipodocumento ? false : true}  */>
                          <option value={"DEFAULT"} disabled>
                            {t("pages.checkout.step-2.document-type-placeholder")}
                          </option>
                          {documentTypes.map((type, index) => (
                            <option key={index} value={type.codigo} selected={userInfo.tipodocumento === type.codigo}>
                              {type.descripcion}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.identification")}
                        type={"text"}
                        value={formData.identification}
                        onchange={(e) => handleOnChange("identification", e)}
                        placeholder={t("pages.checkout.step-2.identification-placeholder")}
                        isUserError={identificationError}
                        disabled={userInfo ? true : false}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.celphone")}
                        type={"text"}
                        value={formData.celphone}
                        onchange={(e) => handleOnChange("celphone", e)}
                        placeholder={t("pages.checkout.step-2.celphone-placeholder")}
                        isUserError={celphoneError}
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.address")}
                        type={"text"}
                        value={formData.address}
                        disabled={true}
                        onchange={(e) => handleOnChange("address", e)}
                        placeholder={t("pages.checkout.step-2.address-placeholder")}
                        /* disabled={formData.city === ""} */
                        onblur={(e) => handleAddressBlur(e)}
                        isUserError={addressError}
                      />
                    </div>
                    <div className="col-12">
                      <InputPrimary
                        label={t("pages.checkout.step-2.observation")}
                        type={"text"}
                        value={formData.observation}
                        onchange={(e) => handleOnChange("observation", e)}
                        placeholder={t("pages.checkout.step-2.observation-placeholder")}
                        isUserError={observationError}
                      />
                    </div>
                  </div>
                  {isErrorinForm &&
                    <div>
                      <p className='msj-error'>*{t("pages.checkout.step-2.verify-fields")}</p>
                    </div>
                  }
                </div>
                <div className="center-content">
                  <Spacer px={20} hideon="small" />
                  {smallLoader === false ?
                    <Button classnames="gtmIniciaSesionRestableceTuContrasena primary" text={`Pagar con PlaceToPay → $${FormatNumber(totalToPay)}`} type="button" onclick={goHandlePayment}></Button>
                    :
                    <Image src={preLoaderIcon} style={{ maxWidth: 50 }} />
                  }
                  <Spacer px={50} hideon="small" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section >
  );
};

export default DebsToPay;
