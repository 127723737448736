import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class CountryServices {
  static getListCountry(payload) {
    return axios.get(endpoints.getCountry, payload).then(res => res.data);
  }

  static getListDepartment(payload) {
    return axios.get(endpoints.getDepartment, payload).then(res => res.data);
  }

  static getListCity(payload) {
    return axios.get(endpoints.getCity, payload).then(res => res.data);
  }
  static getListIdCountry(payload) {
    return axios.get(endpoints.getIdPais, payload).then(res => res.data);
  }

}

export default CountryServices;
