import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "./../../atoms/Button/Button";
import InputPrimary from "./../../atoms/InputPrimary/InputPrimary";
import FileInput from "./../../atoms/FileInput/FileInput";
import Spacer from "../../atoms/Spacer/Spacer";
import { useTranslation } from 'react-i18next';
import UserInfo from '../../../common/LocalStorage/UserInfo';
import CPQRSServices from "../../../services/Cpqrs/Cpqrs";
import Loader from "../../atoms/Loader/Loader";
import dataLayer from "../../../common/GTM/dataLayer";
//import CheckBox from "./../../atoms/CheckBox/CheckBox";

const ContactUsTemplate = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState([]);
  const defaultForm = {
    fullname: "",
    subject: "",
    celphone: "",
    curriculum: file,
    profile: "",
    terms: false
  };
  const [formData, setFormData] = useState(defaultForm);
  const [fullnameError, setFullnameError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [celphoneError, setCelphoneError] = useState(false);
  const [curriculumError, setCurriculumError] = useState(false);
  const [isErrorinForm, setIsErrorinForm] = useState(false);
  const [profileError, setProfileError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isContactOK, setIsContactOK] = useState(false);
  const userInfo = JSON.parse(UserInfo.getUser());

  const validateErrors = () => {
    if (formData.fullname === "") {
      setFullnameError(true);
    } else {
      setFullnameError(false);
    }

    if (formData.subject === "") {
      setSubjectError(true);
    } else {
      setSubjectError(false);
    }

    if (formData.celphone === "") {
      setCelphoneError(true);
    } else {
      setCelphoneError(false);
    }

    if (formData.curriculum === null) {
      setCurriculumError(true);
    } else {
      setCurriculumError(false);
    }

    if (formData.profile === "") {
      setProfileError(true);
    } else {
      setProfileError(false);
    }

    if (!formData.terms) {
      setTermsError(true);
    } else {
      setTermsError(false);
    }

    return (
      (formData.fullname === "") ||
      (formData.subject === "") ||
      (formData.celphone === "") ||
      (formData.curriculum === null) ||
      (formData.profile === "") ||
      (formData.terms === false)
    );
  };

  const handleOnChange = (node, event) => {
    validateErrors();
    switch (node) {
      case "fullname":
        setFormData({
          ...formData,
          fullname: event.target.value.replace(/ /g, " ")
        });
        break;

      case "subject":
        setFormData({
          ...formData,
          subject: event.target.value.replace(/ /g, " ")
        });
        break;

      case "celphone":
        setFormData({
          ...formData,
          celphone: event.target.value.replace(/ /g, " ")
        });
        break;
      case "profile":
        setFormData({
          ...formData,
          profile: event.target.value.replace(/ /g, " ")
        });
        break;
      default:
        setFormData({ ...formData });
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileChange = (file) => {
    if (file.length > 0) {
      setFile(file);
    } else {
      setFile([]);
    }
    validateErrors();
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
        reject(null);
      };
    })
  };

  const handleTermsCheck = () => {
    setFormData({
      ...formData,
      terms: !formData.terms
    });
  };

  const handleSubmit = async () => {
    dataLayer.pushEvent("TrabajaConNosotros", "ClicFormulario", "EnviarExitoso");

    if (!validateErrors()) {
      setLoading(true);
      setIsErrorinForm(false);
      const base64file = await getBase64(file[0]);

      let payload = {
        email: userInfo.email,
        asunto: formData.subject,
        mensaje: formData.perfil,
        nombre: formData.fullname,
        celular: formData.celphone,
        autorizacion: (formData.terms) ? 1 : 0,
        tipo: 1,
        archivo: base64file
      };

      const result = await CPQRSServices.addCPQRS(payload);
      //console.log("Resultado Contacto: ", result);
      if (result.estadoRespuesta === 0) {
        setIsContactOK(true);
      } else {
        console.warn("Error en la respuesta: ", result);
      }
      setLoading(false);
    } else {
      setIsErrorinForm(true);
    }
  };

  return (
    <section className="p_contactUs">
      <Spacer px={30} />
      <div className="container">
        {loading === true &&
          <Loader />
        }
        <Spacer px={30} />
        <h1>{t('pages.work-with-us.title')}</h1>
        <Spacer px={30} />
        <div className="info">
          <div>
            <p>{t('pages.work-with-us.customer-support')}</p>
            <p>+57 (4) 448 60 25 Ext. 1</p>
          </div>
          <div>
            <p>{t('pages.work-with-us.business-email')}</p>
            <p>Info@unitecusa.com.co</p>
          </div>
        </div>
        <Spacer px={50} />
        <div className="white-card-bkg">
          <div className="center-content">
            {isContactOK &&
              <div>
                <p className='msj-success'>{t("pages.work-with-us.succefull-register")}</p>
              </div>
            }
          </div>
          <form className="max-width-800">
            <Spacer px={30} />
            <div className="row">
              <div className="col-sm-6 col-6">
                <InputPrimary
                  label={t('pages.work-with-us.full-name')}
                  type={"text"}
                  value={formData.fullname}
                  onchange={(e) => handleOnChange("fullname", e)}
                  placeholder={t('pages.work-with-us.full-name-placeholder')}
                  isUserError={fullnameError}
                />
              </div>
              <div className="col-sm-6 col-6">
                <InputPrimary
                  label={t('pages.work-with-us.subject')}
                  type={"text"}
                  value={formData.subject}
                  onchange={(e) => handleOnChange("subject", e)}
                  placeholder={t('pages.work-with-us.subject-placeholder')}
                  isUserError={subjectError}
                />
              </div>
              <div className="col-sm-6 col-6">
                <InputPrimary
                  label={t('pages.work-with-us.phone')}
                  type={"text"}
                  value={formData.celphone}
                  onchange={(e) => handleOnChange("celphone", e)}
                  placeholder={t('pages.work-with-us.phone-placeholder')}
                  isUserError={celphoneError}
                />
              </div>
              <div className="col-sm-6 col-6">
                <FileInput
                  label={t('pages.work-with-us.curriculum-vitae')}
                  placeholder={t('pages.work-with-us.curriculum-vitae-file')}
                  isUserError={curriculumError}
                  value={file}
                  onChange={(file) => { handleFileChange(file) }} />
              </div>
              <div className="col-12">
                <InputPrimary
                  label={t('pages.work-with-us.profile')}
                  type={"textarea"}
                  value={formData.profile}
                  onchange={(e) => handleOnChange("profile", e)}
                  placeholder={t('pages.work-with-us.profile-placeholder')}
                  isUserError={profileError}
                />
              </div>
            </div>
          </form>
          <Spacer px={35} />
          <div className="center-content">
            <div className="a__check-box">
              <label className={(termsError === true) ? "field-error" : ""}>
                {" "}
                {t('pages.work-with-us.terms-and-conditions.accept')}{" "}
                <Link to="/terms-and-conditions">{t('pages.work-with-us.terms-and-conditions.terms-and-conditions')}</Link>
                <input className="gtmRegistroTerminosCondicionesAceptar" type="checkbox" onChange={e => handleTermsCheck(e)} checked={formData.terms}></input>
                <span></span>
              </label>
            </div>
            <Spacer px={15} />
            <Button onclick={() => handleSubmit()} classnames="gtmTrabajaConNosotrosEnviar primary" text={t('pages.work-with-us.btn-submit')} type="button"></Button>
            {isErrorinForm &&
              <div>
                <p className='msj-error'>*{t("pages.checkout.step-2.verify-fields")}</p>
              </div>
            }
            <Spacer px={50} />
          </div>
        </div>
        <Spacer px={70} />
      </div>
    </section>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsTemplate);
