import React, { useEffect } from "react";
import { connect } from "react-redux";
import RegisterTemplate from "./../templates/RegisterTemplate/RegisterTemplate";

const ContactUs = (props) => {
  const { handleswitchmodal } = props;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <RegisterTemplate handleswitchmodal={handleswitchmodal} />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUs);
