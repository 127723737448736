import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Spacer from "../../atoms/Spacer/Spacer";
import InputPrimary from "../../atoms/InputPrimary/InputPrimary";
import Button from "../../atoms/Button/Button";
import CountryServices from "../../../services/CountryServices/CountryServices";
import SolicitudCredito from "../../../services/SolicitudCredito/SolicitudCredito";
import { useTranslation } from "react-i18next";
import UserInfo from '../../../common/LocalStorage/UserInfo';
import fotoFrontal from '../../../assets/images/identity_card_front.png';
import fotoPosterior from '../../../assets/images/identity_card_back.png';
import utilyBillFront from '../../../assets/images/utily-bill-front.png';
import utilyBillBack from '../../../assets/images/utily-bill-back.png';
import Logo from '../../../assets/images/logos_mensaje.png';
import FileInput from "../../atoms/FileInput/FileInput";
import Cart from '../../../common/LocalStorage/Cart';
import { Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "../../atoms/Loader/Loader";


const SomosCreditTemplate = props => {
        const { t } = useTranslation();
        const history = useHistory();
        const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';
        const [nameError, setNameError] = useState(false);
        const [listDepartment, setListDepartment] = useState([]);
        const [listCities, setListCities] = useState([]);
        const [surnameError, setSurnameError] = useState(false);
        const [cedulaError, setCedulaError] = useState(false);
        const [contratoError, setContratoError] = useState(false);
        const [addressError, setAddressError] = useState(false);
        const [barrioError, setBarrioError] = useState(false);
        const [municipioError, setMunicipioError] = useState(false);
        const [departamentoError, setDepartamentoError] = useState(false);
        const [fijoError, setFijoError] = useState(false);
        const [celularError, setCelularError] = useState(false);
        const [referidoError, setReferidoError] = useState(false);
        const [anexo1Error, setAnexo1Error] = useState(false);
        const [anexo2Error, setAnexo2Error] = useState(false);
        const [anexo3Error, setAnexo3Error] = useState(false);
        const [anexo4Error, setAnexo4Error] = useState(false);
        const [modalStarted, setModalStarted] = useState(false);
        const [showLoader, setShowLoader] = useState(false);
        const [emailError, setEmailError] = useState(false);
        /* recaptcha */
        const [val1, setVal1] = useState(Math.floor(Math.random() * (9 - 1 + 1)) + 1);
        const [val2, setVal2] = useState(Math.floor(Math.random() * (9 - 1 + 1)) + 1);
        const [errorRecaptcha, setErrorRecaptcha] = useState(false);
        const [valueRecaptcha, setValueRecaptcha] = useState();

        /* //////////// */

        const userInfo = JSON.parse(UserInfo.getUser());
        const defaultFormData = {
                name: userInfo ? userInfo.nombre : "",
                surname: userInfo ? userInfo.apellido : "",
                //email: (userInfo) ? userInfo.email : "",
                contrato: "",
                cedula: userInfo ? userInfo.document : "",
                cellphone: "",
                phone: "",
                email: "",
                suburb: "",
                referido: "",
                town: "",
                department: "",
                city: "",
                anexo1: [],
                anexo1Base64: [],
                extencion1: "",
                anexo2: [],
                anexo2Base64: [],
                extencion2: "",
                anexo3: [],
                anexo3Base64: [],
                extencion3: "",
                anexo4: [],
                anexo4Base64: [],
                extencion4: "",
                address: (userInfo) ? userInfo.direccionDeEnvio : "",
                observation: "",
                idCart: Cart.getCartId(),
        };
        const [formData, setFormData] = useState(defaultFormData);
        const eliminateLastChart = (text) => {
                let obj = {
                        rest: text.slice(0, -1),
                        last: text.slice(-1)
                }
                return obj;
        }
        const reCaptcha = (value) => {
                return (val1 + val2) === parseInt(value); 
        }
        /* Eliminate no numerics charts */
        const purgueNumerics = (text) => {
                var r = /\d+/g;
                var m;
                let ret = "";
                while ((m = r.exec(text)) != null) {
                        ret += m[0];
                }
                return ret;
        }
        const loadDepartments = (country) => {
                let payload = {
                        params: {
                                idPais: country
                        }
                };
                console.log(payload);
                CountryServices.getListDepartment(payload).then(res => {
                        console.log(res);
                        setListDepartment(res.departamentos);

                }).catch(err => console.log(err));
        };
        const loadCities = (department) => {
                let payload = {
                        params: {
                                idPais: 169,
                        }
                };
                CountryServices.getListCity(payload).then(res => {
                        let cities = [];
                        for (const city of res.ciudades) {
                                if (city.codigoDepartamento === department) {
                                        cities.push(city)
                                }
                        }
                        setListCities(cities);
                }).catch(err => console.log(err));
        };
        const handleSend = async () => {
                if (!validateErrors()) {
                        setShowLoader(true);
                        let payload = {
                                nombres: formData.name,
                                apellidos: formData.surname,
                                cedula: formData.cedula,
                                contrato: formData.contrato,
                                direccion: formData.address,
                                barrio: formData.suburb,
                                municipio: formData.city,
                                departamento: formData.department,
                                telefono: formData.phone,
                                celular: formData.cellphone,
                                referido: formData.referido,
                                anexo1: formData.anexo1Base64,
                                formato1: formData.extencion1,
                                anexo2: formData.anexo2Base64,
                                formato2: formData.extencion2,
                                anexo3: formData.anexo3Base64,
                                formato3: formData.extencion3,
                                anexo4: formData.anexo4Base64,
                                formato4: formData.extencion4,
                                idcarrito: formData.idCart,
                                email: formData.email
                        }

                        SolicitudCredito.send(payload).then(res => {
                                setShowLoader(false);
                                if (res.data.estado === 1) {
                                        handleShow(true);
                                }
                                console.log(res)
                        }
                        );
                }
        }
        const getBase64 = (file) => {
                return new Promise((resolve, reject) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                                resolve(reader.result);
                        };
                        reader.onerror = function (error) {
                                console.log('Error: ', error);
                                reject(null);
                        };
                })
        };
        const validateErrors = () => {
                let ret = false;
                if ((formData.name === "") || (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.name))) {
                        ret = true;
                        setNameError(true);
                }
                if ((formData.surname === "") || (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{2,60}$/i.test(formData.surname))) {
                        ret = true;
                        setSurnameError(true);
                }
                if (formData.email === "" || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
                        setEmailError(true);
                } else {
                        setEmailError(false);
                }
                if ((formData.contrato === "")) {
                        ret = true;
                        setContratoError(true);
                }
                if ((formData.cedula === "")) {
                        ret = true;
                        setCedulaError(true);
                }
                if ((formData.celphone === "")) {
                        ret = true;
                        setCelularError(true);
                }
                if ((formData.phone === "")) {
                        ret = true;
                        setFijoError(true);
                }
                if ((formData.suburb === "")) {
                        ret = true;
                        setBarrioError(true);
                }
                if ((formData.department === "")) {
                        ret = true;
                        setDepartamentoError(true);
                }
                if ((formData.city === "")) {
                        ret = true;
                        setMunicipioError(true);
                }
                if ((formData.address === "")) {
                        ret = true;
                        setAddressError(true);
                }
                if (!reCaptcha(valueRecaptcha)) {
                        ret = true;
                        setErrorRecaptcha(true);
                }
                /*  if ((formData.anexo1.length === 0)) {
                         ret = true;
                         setAnexo1Error(true);
                 }
                 if ((formData.anexo2.length === 0)) {
                         ret = true;
                         setAnexo2Error(true);
                 }
                 if ((formData.anexo3.length === 0)) {
                         ret = true;
                         setAnexo3Error(true);
                 }
                 if ((formData.anexo4.length === 0)) {
                         ret = true;
                         setAnexo4Error(true);
                 } */
                return ret;
        };
        const handleFileChange = async (element, file) => {
                //  console.log(file);
                switch (element) {
                        case 'anexo1':
                                let base64File = await getBase64(file[0]);
                                setFormData({ ...formData, anexo1: file, anexo1Base64: base64File, extencion1: file[0].type });
                                break;
                        case 'anexo2':
                                setFormData({ ...formData, anexo2: file, anexo2Base64: await getBase64(file[0]), extencion2: file[0].type });
                                break;
                        case 'anexo3':
                                setFormData({ ...formData, anexo3: file, anexo3Base64: await getBase64(file[0]), extencion3: file[0].type });
                                break;
                        case 'anexo4':
                                setFormData({ ...formData, anexo4: file, anexo4Base64: await getBase64(file[0]), extencion4: file[0].type });
                                break;

                        default:
                                break;
                }

        };
        const handleOnChange = (element, event) => {
                switch (element) {
                        case "name":
                                setFormData({ ...formData, name: event.target.value });

                                if (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(event.target.value)) {
                                        setNameError(true);
                                        let w = eliminateLastChart(event.target.value);
                                        setFormData({ ...formData, name: w.rest });
                                } else {
                                        setNameError(false);
                                }
                                //   console.log(formData);
                                break;
                        case "surname":
                                setFormData({ ...formData, surname: event.target.value });
                                if (!/^[a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã][a-zñáéíóúäëïöüàèìòùÑÁÉÍÓÚÄËÏÖÜÀÈÌÒÙÇçÃã'\.\&\-\d ]{0,60}$/i.test(event.target.value)) {
                                        setSurnameError(true);
                                        let w = eliminateLastChart(event.target.value);
                                        setFormData({ ...formData, surname: w.rest });
                                } else {
                                        setSurnameError(false);
                                }
                                break;
                        case "email":
                                setFormData({ ...formData, email: event.target.value });
                                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
                                        setEmailError(true);

                                } else {
                                        setEmailError(false);
                                }
                                break;
                        case "cedula": {
                                setFormData({ ...formData, cedula: purgueNumerics(event.target.value) })
                                break;
                        }
                        case "contrato": {
                                setFormData({ ...formData, contrato: event.target.value.replace(/ /g, " ") })
                                break;
                        }
                        case "address": {
                                setFormData({ ...formData, address: event.target.value.replace(/ /g, " ") })
                                break;
                        }
                        case "suburb": {
                                setFormData({ ...formData, suburb: event.target.value.replace(/ /g, " ") })
                                break;
                        }
                        case "departamento": {
                                setFormData({ ...formData, department: event.target.value.replace(/ /g, " ") });
                                loadCities(event.target.value);
                                break;
                        }
                        case "municipio": {
                                setFormData({ ...formData, city: event.target.value.replace(/ /g, " ") })
                                break;
                        }
                        case "telefonofijo": {
                                setFormData({ ...formData, phone: purgueNumerics(event.target.value) })
                                break;
                        }
                        case "celular": {
                                setFormData({ ...formData, cellphone: purgueNumerics(event.target.value) })
                                break;
                        }
                        case "referido": {
                                setFormData({ ...formData, referido: event.target.value.replace(/ /g, " ") })
                                break;
                        }
                        case "anexo1": {
                                setFormData({ ...formData, anexo1: event })
                                break;
                        }
                        case "anexo2": {
                                setFormData({ ...formData, anexo2: event })
                                break;
                        }
                        case "anexo3": {
                                setFormData({ ...formData, anexo3: event })
                                break;
                        }
                        case "anexo4": {
                                setFormData({ ...formData, anexo4: event })
                                break;
                        }
                        default:
                                break;
                }
        }
        const handleClose = () => { window.location.href = '/' };
        const handleShow = () => setModalStarted(true);
        useEffect(() => {
                if (listDepartment.length === 0) {
                        loadDepartments(169);
                }
        }, [])

        return (<div className="container">
                <Spacer px={80} />
                {showLoader && <Loader />}
                <section>
                        <div className="white-card-bkg">
                                <div className="row">
                                        <div className="col-12">
                                                <h1 style={{ textAlign: 'center' }}>Créditos hogares felices</h1>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label={t("pages.checkout.step-2.name")}
                                                        type={"text"}
                                                        value={formData.name}
                                                        onchange={(e) => handleOnChange("name", e)}
                                                        placeholder={t("pages.checkout.step-2.name-placeholder")}
                                                        isUserError={nameError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label={t("pages.checkout.step-2.surname")}
                                                        type={"text"}
                                                        value={formData.surname}
                                                        onchange={(e) => handleOnChange("surname", e)}
                                                        placeholder={t("pages.checkout.step-2.surname-placeholder")}
                                                        isUserError={surnameError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Cédula"
                                                        type={"text"}
                                                        value={formData.cedula}
                                                        onchange={(e) => handleOnChange("cedula", e)}
                                                        placeholder="Escribe tu Cédula"
                                                        isUserError={cedulaError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Email"
                                                        type={"text"}
                                                        value={formData.email}
                                                        onchange={(e) => handleOnChange("email", e)}
                                                        placeholder="Escribe tu correo electrónico"
                                                        isUserError={emailError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Contrato de los servicios de EPM"
                                                        type={"text"}
                                                        value={formData.contrato}
                                                        onchange={(e) => handleOnChange("contrato", e)}
                                                        placeholder="# De contrato de los servicios públicos"
                                                        isUserError={contratoError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Departamento"
                                                        type={"select"}
                                                        element={"departamento"}
                                                        value={formData.department}
                                                        handleOnChange={handleOnChange}
                                                        placeholder="Selecciona tu departamento"
                                                        isUserError={departamentoError}
                                                        options={listDepartment}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Municipio / Ciudad"
                                                        type={"select"}
                                                        element={"municipio"}
                                                        value={formData.city}
                                                        handleOnChange={handleOnChange}
                                                        placeholder="Selecciona tu municipio / ciudad"
                                                        isUserError={municipioError}
                                                        options={listCities}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Dirección"
                                                        type={"text"}
                                                        value={formData.address}
                                                        onchange={(e) => handleOnChange("address", e)}
                                                        placeholder="Escribe tu dirección"
                                                        isUserError={addressError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Barrio"
                                                        type={"text"}
                                                        value={formData.suburb}
                                                        onchange={(e) => handleOnChange("suburb", e)}
                                                        placeholder="Escribe tu barrio"
                                                        isUserError={barrioError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Teléfono"
                                                        type={"text"}
                                                        value={formData.phone}
                                                        onchange={(e) => handleOnChange("telefonofijo", e)}
                                                        placeholder="Escribe tu teléfono"
                                                        isUserError={fijoError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Celular"
                                                        type={"text"}
                                                        value={formData.cellphone}
                                                        onchange={(e) => handleOnChange("celular", e)}
                                                        placeholder="Escribe tu número de celular"
                                                        isUserError={celularError}
                                                />
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <InputPrimary
                                                        label="Código del asesor o referido (opcional)"
                                                        type={"text"}
                                                        value={formData.referido}
                                                        onchange={(e) => handleOnChange("referido", e)}
                                                        placeholder="Escriba el código del asesor"
                                                        isUserError={referidoError}
                                                />
                                        </div>
                                </div>
                                <div className="row">
                                        <div className="col-12">
                                                <h3 style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>Para agilizar el proceso adjunta los siguientes documentos</h3>
                                        </div>
                                </div>
                                <div className="row">
                                        <div className="col-sm-6 col-12">
                                                <div className="row">
                                                        <div className="col-10">
                                                                <FileInput
                                                                        label='Anexar foto frontal de la cédula'
                                                                        placeholder={t('pages.work-with-us.curriculum-vitae-file')}
                                                                        isUserError={anexo1Error}
                                                                        value={formData.anexo1}
                                                                        onChange={(file) => { handleFileChange('anexo1', file) }} />
                                                        </div>
                                                        <div className="col-2">
                                                                <img src={fotoFrontal} className="t__img-help" />
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <div className="row">
                                                        <div className="col-10">
                                                                <FileInput
                                                                        label='Anexar foto posterior de la cédula'
                                                                        placeholder={t('pages.work-with-us.curriculum-vitae-file')}
                                                                        isUserError={anexo2Error}
                                                                        value={formData.anexo2}
                                                                        onChange={(file) => { handleFileChange('anexo2', file) }} />
                                                        </div>
                                                        <div className="col-2">
                                                                <img src={fotoPosterior} className="t__img-help" />
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <div className="row">
                                                        <div className="col-10">
                                                                <FileInput
                                                                        label='Anexar foto frontal de los servicios públicos'
                                                                        placeholder={t('pages.work-with-us.curriculum-vitae-file')}
                                                                        isUserError={anexo3Error}
                                                                        value={formData.anexo3}
                                                                        onChange={(file) => { handleFileChange('anexo3', file) }} />
                                                        </div>
                                                        <div className="col-2">
                                                                <img src={utilyBillFront} className="t__img-help" />
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                                <div className="row">
                                                        <div className="col-10">
                                                                <FileInput
                                                                        label='Anexar foto posterior de los servicios públicos'
                                                                        placeholder={t('pages.work-with-us.curriculum-vitae-file')}
                                                                        isUserError={anexo4Error}
                                                                        value={formData.anexo4}
                                                                        onChange={(file) => { handleFileChange('anexo4', file) }} />
                                                        </div>
                                                        <div className="col-2">
                                                                <img src={utilyBillBack} className="t__img-help" />
                                                        </div>
                                                </div>
                                        </div>
                                        <Spacer px={30} />
                                        <div className="col-12">
                                                <div className="t__text-align-right t__recaptcha">
                                                        <InputPrimary
                                                                style
                                                                label={`${val1} + ${val2} = `}
                                                                type={"text"}
                                                                //value={formData.referido}
                                                                onchange={(e) => setValueRecaptcha(e.target.value)}
                                                                placeholder=" "
                                                                isUserError={errorRecaptcha}
                                                        />
                                                        <Button
                                                                classnames="primary gtmPagoEnvioPagarConPlacetopay t__position-relative t__chackout-send-form"
                                                                text='Enviar'
                                                                type="button"
                                                                onclick={() => handleSend()}
                                                        ></Button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                        <Modal show={modalStarted} onHide={handleClose} size='lg' centered>
                                <Modal.Header closeButton className="t__closeButton">
                                </Modal.Header>
                                <Modal.Body>
                                        <div className="row t__modal">
                                                <div className="col-12" style={{ textAlign: 'center' }}>
                                                        <img src={Logo} style={{ maxWidth: 326, marginBottom: 30 }} />
                                                        <h4 style={{ fontWeight: 300, padding: '20px 40px 0px 40px' }}> <strong>Muchas gracias</strong> por la información registrada, tu solicitud será atendida por uno de nuestros asesores y te estaremos contactando en un término de 24 horas, los datos del registro serán enviados a tu correo electrónico (revisa tu bandeja de entrada o la carpeta de correos no deseados).
 </h4>
                                                </div>
                                        </div>
                                </Modal.Body>
                        </Modal>
                </section>
        </div >
        )
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SomosCreditTemplate);
