import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PaymentServices from "../../../services/PaymentServices/PaymentServices";
import Spacer from "../../atoms/Spacer/Spacer";
import Button from "../../atoms/Button/Button";
import UserInfo from '../../../common/LocalStorage/UserInfo';
import Loader from "../../atoms/Loader/Loader";
import Cart from '../../../common/LocalStorage/Cart';

const PaymentCancelled = (props) => {
  const { t } = useTranslation();
  const [paymentInfo, setPaymentInfo] = useState([]);
  //const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || 'es';
  const { id } = props.match.params;
  const userInfo = JSON.parse(UserInfo.getUser());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Cart.deleteCart();
    const getPaymentStatus = async () => {
      let payload = {
        params: {
          referenciaDePago: id
        }
      };

      setLoading(true);
      const result = await PaymentServices.getPaymentStatus(payload);

      if (result.estadoRespuesta === 0) {
        setPaymentInfo(result.estadoDePago);
      } else {
        console.warn("Error products home: ", result);
      }
      setLoading(false);
    };
    getPaymentStatus();
  }, [id]);

  return (
    <div className="container">
      {loading === true &&
        <Loader />
      }
      <section className="u-payment-cancelled">
        <h2>{t("pages.checkout.step-3.title")}</h2>
        <p>{t("pages.checkout.step-3.description-pay")}</p>
        <Spacer px={30} />
        <div className="white-card-bkg">
          <div className="max-width-800">
            <div className="t__purchase">
              <div className="t__purchase-item">
                <span>{t("pages.checkout.step-3.transaction-status")} </span>
                <p>{(paymentInfo.descripcion) ? paymentInfo.descripcion : "Sin respuesta"}</p>
              </div>
              <div className="t__purchase-item">
                <span>{t("pages.checkout.step-3.transaction-number")} </span> <p>{(paymentInfo.referenciaDePago) ? paymentInfo.referenciaDePago : props.match.params.id}</p>
              </div>
              {(userInfo) &&
                <div>
                  <div className="t__purchase-item">
                    <span>{t("pages.checkout.step-3.name")} </span> <p>{userInfo.nombre}</p>
                  </div>
                  <div className="t__purchase-item">
                    <span>{t("pages.checkout.step-3.email")} </span>{" "}
                    <p>{userInfo.email}</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <Spacer px={50} />
        <div className="t__checkout-btns">
          <Button
            classnames="primary t__center-btn"
            text={t("pages.checkout.step-3.btn-accept")}
            type="link"
            href="/"
          ></Button>
        </div>
        <Spacer px={50} />
      </section>
    </div>
  );
};

export default PaymentCancelled;
