import React from 'react';
import PropTypes from 'prop-types';

const Image = props => (
    <img src={props.src} className={props.classname} alt={props.alt} />
)
// props.type === 'base64' ? `data:image/png;base64,${props.src}` : 

Image.propTypes = {
    src: PropTypes.string.isRequired,
    classname: PropTypes.string,
    type: PropTypes.string.isRequired,
    alt: PropTypes.string
};

export default Image;

