import React, { useState, useEffect } from 'react';
import CategorySection from '../../organisms/CategorySection/CategorySection';
import CategorySubcategories from '../../organisms/CategorySubcategories/CategorySubcategories';
import CategoryServices from '../../../services/CategoryServices/CategoryServices';
import { useTranslation } from "react-i18next";
import Loader from "../../atoms/Loader/Loader";
import InConstruction from '../inConstruction/InConstruction';
import Spacer from '../../atoms/Spacer/Spacer';
import { MetaTags } from 'react-meta-tags';

const CategoryTemplate = props => {
    const { t } = useTranslation();
    const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';
    const [isLoading, setIsLoading] = useState(true);
    const [showConstruction, setShowConstruction] = useState(false);
    const [category, setCategory] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const [actualDescription, setActualdescription] = useState('');
    const { id } = props.match.params;
    const metaDescription = {
        "Ferretería": `Encuentra en nuestro sitio web todo lo relacionado para tu hogar y negocio
        en swichería, grifería, herramientas, discos de corte, seguridad industrial,
        adhesivos, pintura en aerosol, cables y extensiones`,
        "Tecnología": `Encuentra en nuestro sitio web parlantes, videojuegos, accesorios para pc,
        fuentes y accesorios para celular`,
        "Iluminación": `Encuentra en nuestro sitio web todo lo relacionado con la iluminación LED y
        contribuye con el medio ambiente utilizando nuestras lámparas y paneles`,
        "Proyectos Especiales": `Encuentra todo lo relacionado para tu negocio en protección salud, Ups
        online, energía solar y Cctv`,
        "Hogares Felices": `Encuentra en nuestro sitio web neveras, lavadoras, celulares,
        computadores portátiles, sonido, estufas, televisores, hornos, microondas,
        aires acondicionados, air fryer, licuadoras e hidrolavadoras`,
        "Movilidad Eléctrica": `En nuestro sitio web podrás encontrar una nueva alternativa para tu
        transporte, ahorrar dinero y cuidar el ambiente con las bicicletas eléctricas`,
    }
    useEffect(() => {
        const getCategory = (id) => {
            setIsLoading(true);
            setShowConstruction(false);
            let payload = {
                params: {
                    idioma: getCurrentLng(),
                    categoria: id
                }
            };
            CategoryServices.getCategory(payload).then(res => {
                if (res.estadoRespuesta === 0) {
                    if (res.categorias[0].subcategorias.length > 0) {
                        setCategory(res.categorias[0]);
                        setActualdescription(metaDescription[res.categorias[0].nombreCategoria]);
                        setSubCategories(res.categorias[0].subcategorias);
                    } else {
                        setShowConstruction(true);
                    }
                    setIsLoading(false);
                } else {
                    console.log(res);
                }
            }).catch(err => console.log(err));
        };

        getCategory(id);
    }, [id]);

    return (isLoading === false && showConstruction === false ?
        <section className="t__category ">
            <MetaTags>
                <meta name='description' content={actualDescription} />
            </MetaTags>
            <Spacer px={40} />
            <div className="container">
                <CategorySection title={category.nombreCategoria} description={t("categories-descriptions." + id)} />
            </div>
            <div>
                <CategorySubcategories cat={category} subcategories={subcategories} />
            </div>
        </section>
        : (showConstruction === true) ?
            <InConstruction />
            : <Loader />
    )
};

export default CategoryTemplate;