import React from 'react';
import PropTypes from 'prop-types';

const CategorySection = props => {
    const { title, description } = props
    return (
        <section className="o_categorySection">
            {title &&
                <h1>{title}</h1>
            }
            {description &&
                <p>{description}.</p>
            }
        </section>
    )
}

CategorySection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    /*CategorySubcategories        paramCategory: PropTypes.any */
}

export default CategorySection;