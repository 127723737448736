/* Action Types */
/* Products */
export const HOME_BANNER_PRODUCTS_SUCCESS = 'HOME_BANNER_PRODUCTS_SUCCESS';
export const HOME_BANNER_PRODUCTS_FAILED = 'HOME_BANNER_PRODUCTS_FAILED';
export const HOME_FEATURED_PRODUCTS_SUCCESS = 'HOME_FEATURED_PRODUCTS_SUCCESS';
export const HOME_FEATURED_PRODUCTS_FAILED = 'HOME_FEATURED_PRODUCTS_FAILED';
export const HOME_TRENDING_PRODUCTS_SUCCESS = 'HOME_TRENDING_PRODUCTS_SUCCESS';
export const HOME_TRENDING_PRODUCTS_FAILED = 'HOME_TRENDING_PRODUCTS_FAILED';

/* Categories */
export const CATEGORY_ITEMS_SUCCESS = 'CATEGORY_ITEMS_SUCCESS';
export const CATEGORY_ITEMS_FAILED = 'CATEGORY_ITEMS_FAILED';
export const SUBCATEGORY_PRODUCTS_SUCCESS = 'SUBCATEGORY_PRODUCTS_SUCCESS';
export const SUBCATEGORY_PRODUCTS_FAILED = 'SUBCATEGORY_PRODUCTS_FAILED';

/* UI */
// MODAL
export const UI_MODAL_OPEN = 'UI_MODAL_OPEN';
export const UI_MODAL_CLOSE = 'UI_MODAL_CLOSE';
export const UI_MODAL_TYPE = 'UI_MODAL_TYPE';

// Toast
export const UI_TOAST_SHOW_SUCCESS = 'UI_TOAST_SHOW_SUCCESS';
export const UI_TOAST_SHOW_FAILED = 'UI_TOAST_SHOW_FAILED';
export const UI_TOAST_HIDE = 'UI_TOAST_HIDE';

// Loader
export const UI_LOADER_SHOW = 'UI_LOADER_SHOW';
export const UI_LOADER_HIDE = 'UI_LOADER_HIDE';

// Login
export const UI_LOGIN_ERROR_SHOW = 'UI_LOGIN_ERROR_SHOW';
export const UI_LOGIN_ERROR_HIDE = 'UI_LOGIN_ERROR_HIDE';

//  Recover
export const UI_RECOVER_SHOW = 'UI_RECOVER_SHOW';
export const UI_RECOVER_HIDE = 'UI_RECOVER_HIDE';
export const UI_RECOVER_MSG_SHOW = 'UI_RECOVER_ERROR_SHOW';
export const UI_RECOVER_MSG_HIDE = 'UI_RECOVER_ERROR_HIDE';

// Menu
export const UI_MENU_SHOW = 'UI_MENU_SHOW';
export const UI_MENU_HIDE = 'UI_MENU_HIDE';

// Key
export const UI_KEY_MODAL_SHOW = 'UI_KEY_MODAL_SHOW';
export const UI_KEY_MODAL_HIDE = 'UI_KEY_MODAL_HIDE';
export const UI_KEY_MSG_SHOW = 'UI_KEY_MSG_SHOW';
export const UI_KEY_MSG_HIDE = 'UI_KEY_MSG_HIDE';

// Confirm
export const UI_CONFIRM_SHOW = 'UI_CONFIRM_SHOW';
export const UI_CONFIRM_HIDE = 'UI_CONFIRM_HIDE';
export const UI_CONFIRM_SUCCESS = 'UI_CONFIRM_SUCCESS';
export const UI_CONFIRM_ERROR = 'UI_CONFIRM_ERROR';

// General
export const UI_RESET = 'UI_RESET';
export const UI_EXPIRATION_SHOW = 'UI_EXPIRATION_SHOW';

/* Account */
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_FAILED = 'ACCOUNT_LOGIN_FAILED';
export const ACCOUNT_LOGIN_LOGOUT = 'ACCOUNT_LOGIN_LOGOUT';
export const ACCOUNT_RECOVER_SUCCESS = 'ACCOUNT_RECOVER_SUCCESS';
export const ACCOUNT_RECOVER_FAILED = 'ACCOUNT_RECOVER_FAILED';
export const ACCOUNT_UPDATE_POINTS = 'ACCOUNT_UPDATE_POINTS';

/* Cart */
export const GET_CART = 'GET_CART';
export const VALOR_TOTAL = 'VALOR_TOTAL';
export const VALOR_TOTAL_IVA = 'VALOR_TOTAL_IVA';
export const VALOR_TOTAL_FULL = 'VALOR_TOTAL_FULL';
export const SCALE_SELECTED = 'SCALE_SELECTED';