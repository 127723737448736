import React from 'react';
import rgicon from './../../../assets/images/btn-recargar-100.png';
import { useTranslation } from "react-i18next";

const RecargasButton = (props) => {
    const { t } = useTranslation();
    const clase = `rcgs-button gtmHomeLatRecargas ${props.class ? props.class : ''}`; 
    return (
        <a className={clase} target="_blank" href="https://www.unitecrecargas.com.co/" rel="noopener noreferrer">
            <img className="rcgs-button-img gtmHomeLatRecargas" src={rgicon} alt={"recargas unitec"} />
        </a>
    )
}

export default RecargasButton; 