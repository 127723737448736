import React, { useState, useEffect } from "react";
import Card from "../../molecules/Card/Card";
import noimage from "./../../../assets/images/no-image-available.png";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { useHistory, useLocation } from "react-router";
//require("bootstrap/less/bootstrap.less");
const SubCategoryProducts = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const { subcatid, subcategoryname, products, page } = props;
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [activePage, setActivePage] = useState();
  useEffect(() => {
    setSubCategoryName(subcategoryname);
    //alert(page);
    if (page) {
      setActivePage(parseInt(page));
    } else {
      setActivePage(1);
    } 
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [subcategoryname, page]);
  const handlePageChange = (pageNumber) => {
    let _url = history.location.pathname;
    let lastChart = _url.slice(-1);
    //alert(lastChart)
    let _add = lastChart === "/" ? `${pageNumber}` : `/${pageNumber}`;
    _url = page
      ? history.location.pathname.replace(/.{0,2}$/, _add)
      : `${_url}${_add}`;
    
    history.push(_url);
    setActivePage(pageNumber);
  };

  return (
    <div className="o__subcategoryproducts">
      <h1>{subCategoryName}</h1>
      <p>{t("categories-descriptions." + subcatid)}</p>
      <br />
      <section className="d-flex o__subcategoryproducts--container">
        <div className="o__outletproducts__products">
          {products.length > 0 ? (
            products.map((prod, i) => {
              let limit_right = 12 * activePage;
              let limit_left = limit_right - 12;
              if (i >= limit_left && i < limit_right) {
                return (
                  <Card
                    cardtype="subcategory"
                    imagetype="img"
                    //image_src={(prod.img) ? "data:image/png;base64, " + prod.img : noimage}
                    image_src={prod.img ? prod.img : noimage}
                    alt={prod.nombre}
                    text={prod.nombre}
                    id={prod.id}
                    precio={prod.precio}
                    classname=""
                    href={`/product${prod.slug}/${prod.id}`}
                    key={i}
                  />
                );
              } else {
                return false;
              }
            })
          ) : (
            <h3 className="no-products">
              No hay productos disponibles en esta categoría
            </h3>
          )}
        </div>
      </section>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={12}
        totalItemsCount={products.length}
        pageRangeDisplayed={3}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  );
};

export default SubCategoryProducts;
