import { put, takeLatest, all } from 'redux-saga/effects';

import {
    getBannerProductsHome,
    bannerProductsHome,
    getProductsRecommendedHome,
    productsRecommendedHome,
    getProductsTrendingHome,
    productsTrendingHome
} from './ProductsActions';
import Api from '../../common/Api/Api';


function* fetchGetBannerProductsHome() {
    const data = yield Api.get(`/obtenerproductoshome.php`)
    if (data && !data.mensajeDeError) {
        yield put(bannerProductsHome(data.productosHome));
    } else {
        const error = new TypeError(data.mensajeDeError);
        yield put(bannerProductsHome(error));
    }
}

function* fetchGetProductsRecommendedHome() {
    const data = yield Api.get(`/obtenerproductosdestacados.php`)
    if (data && !data.mensajeDeError) {
        yield put(productsRecommendedHome(data.productosDestacados));
    } else {
        const error = new TypeError(data);
        yield put(productsRecommendedHome(error));
    }
}
function* fetchGetProductsTrendingHome() {
    const data = yield Api.get(`/obtenerproductostendencias.php`)
    if (data && !data.mensajeDeError) {
        yield put(productsTrendingHome(data.productosTrending));
    } else {
        const error = new TypeError(data);
        yield put(productsTrendingHome(error));
    }
}

function* ActionWatcher() {
    yield takeLatest(getBannerProductsHome, fetchGetBannerProductsHome)
    yield takeLatest(getProductsRecommendedHome, fetchGetProductsRecommendedHome)
    yield takeLatest(getProductsTrendingHome, fetchGetProductsTrendingHome)
}

export default function* rootSaga() {
    yield all([
        ActionWatcher(),
    ]);
}