import * as types from './../constants/actionConstants';
import * as helpers from './../../utils/overflowHelper';

const initialState = {
  modalIsOpen: false,
  modalType: 'login-modal',
  loaderIsOpen: false,
  toastIsOpen: false,
  showMsg: false,
  loginError: false,
  showRecover: false,
  showKey: false,
  showMenu: false,
  showConfirm: false,
  confirmError: false,
  valorTotal: 0,
  totalIVA: 0,
  totalFull: 0,
  scaleSelected: null,
};

const rdc_interface = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.UI_LOADER_SHOW:
      helpers.addNoScroll();
      return { ...state, loaderIsOpen: true };
    case types.UI_LOADER_HIDE:
      return { ...state, loaderIsOpen: false };
    case types.UI_MODAL_OPEN:
      return { ...state, modalIsOpen: true, modalType: payload };
    case types.UI_MODAL_CLOSE:
      return { ...state, modalIsOpen: false };
    case types.VALOR_TOTAL:
      return { ...state, valorTotal: Math.round(payload) };
    case types.VALOR_TOTAL_IVA:
      return { ...state, totalIVA: Math.round(payload) };
    case types.VALOR_TOTAL_FULL:
      return { ...state, totalFull: Math.round(payload) };
    case types.SCALE_SELECTED:
      return { ...state, scaleSelected: payload };
    default:
      return state;
  }
};

export default rdc_interface;