import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import { useHistory } from "react-router-dom";

const InfoUserTemplate = () => {
  let userInfo = JSON.parse(UserInfo.getUser());
  console.log(userInfo);
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) history.push("/");
  }, []);
  return (
    <section className="t__subcategory">
      <div className="container">
        <div className="white-card-bkg">
          <div className="max-width-800">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                      <label>Nombre:</label>
                      <h3>{`${userInfo.nombre} ${userInfo.apellido}`}</h3>
              </div>
              <div className="col-xs-12 col-md-6">
                      <label>Email:</label>
                      <h3>{`${userInfo.email}`}</h3>
              </div>
              <hr className="hr-info-user" />
              <div className="col-xs-12 col-md-6">
                      <label>Celular:</label>
                      <h3>{`${userInfo.celular}`}</h3>
              </div>
              <div className="col-xs-12 col-md-6">
                      <label>Documento:</label>
                      <h3>{`${userInfo.document}`}</h3>
              </div>
              <hr className="hr-info-user" />
              <div className="col-xs-12 col-md-6">
                      <label>Dirección de envío:</label>
                      <h3>{`${userInfo.direccionDeEnvio}`}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InfoUserTemplate);
