import React, { useState, useEffect, useRef } from 'react';
import IconButton from '../IconButton/IconButton';
import PropTypes from 'prop-types';
import dataLayer from '../../../common/GTM/dataLayer';
import SearchServices from '../../../services/SearchServices/SearchServices';
import UserInfo from "../../../common/LocalStorage/UserInfo";
import { useTranslation } from "react-i18next";

import * as constants from "./../../../state/constants/appConstants";

const SearchBar = props => {
    var rolUsuario = constants.rolUsuario;

    const [isSearchActive, setSearchStatus] = useState(true);
    const { type } = props;
    const textInputRef = useRef(null);
    const [resultList, setResultList] = useState([]);
    const { t } = useTranslation();
    const userInfo = JSON.parse(UserInfo.getUser());
    const getCurrentLng = () => useTranslation.language || localStorage.getItem('i18nextLng') || '';

    const searchData = (e) => {
        if (e.target.value !== '') {
            if (e.target.value.length >= 4) {
                if (!isSearchActive) setSearchStatus(true);
                let queryData = [];
                setResultList([{
                    id: -1,
                    slug: false,
                    nombre: getCurrentLng() === 'en' ? `Searching...` : `Buscando...`
                }]);

                let payload = {
                    params: {
                        idioma: getCurrentLng(),
                        rolUsuario,
                        textoBusqueda: e.target.value,
                        pagina: 1
                    }
                };
                SearchServices.search(payload).then(res => {
                    console.log(res);
                    if (res.data.estadoRespuesta === 0) {
                        if (res.data.productos.length > 0) {
                            //console.log(res.data.productos)
                            let results = "";
                            res.data.productos.forEach((prod, i) => {
                                if (res.data.productos.length === (i + 1)) {
                                    results += `${prod.nombre}`;
                                } else {
                                    results += `${prod.nombre},`;
                                }
                            });

                            res.data.productos.forEach(function (item) {
                                if (queryData.length < 15) {
                                    queryData.push(item);
                                }
                            });

                            setResultList(queryData);

                            dataLayer.pushEvent('Home', 'ClicTerminoDeBusqueda', payload.params.textoBusqueda);
                            dataLayer.pushEvent('Home', 'ClicResultadosDeBusqueda', results);
                        } else {
                            setResultList([{
                                id: -1,
                                slug: false,
                                nombre: t('header.searchbar-no-results')
                            }]);
                        }
                    } else {
                        setResultList([]);
                        console.warn("Response error: ", res);
                    }
                }).catch(err => console.error(err));
            } else {
                setResultList([{
                    id: -1,
                    slug: false,
                    nombre: getCurrentLng === 'en' ? `Type ${4 - e.target.value.length} more character(s) to start search` : `Escribe ${4 - e.target.value.length} caracter(es) más para iniciar busqueda`
                }]);
            }
        } else {
            setResultList([]);
        }
    };

    const handleKeyDown = (e) => {
        console.log(e.key)
        if (e.key === 'Escape') {
            setSearchStatus(!isSearchActive);
        }
    };

    const hideSearchInput = () => {
        window.setTimeout(() => {
            setSearchStatus(false);
        }, 500);
    };

    useEffect(() => {
        if (isSearchActive === true) {
            textInputRef.current.focus();
        }
    }, [isSearchActive]);

    return (
        <div className="u-searchbar">
            <div className='m__searchbar m__searchbar--active'>
                <div className="ga__box--conainter">
                    <input type="text" ref={textInputRef} placeholder={`${t('header.searchbar-placeholder')}`} onChange={(e) => searchData(e)} onKeyDown={(e) => handleKeyDown(e)} onBlur={hideSearchInput} />
                    <IconButton type={type} />
                </div>
            </div>
            {(resultList.length > 0 && isSearchActive) &&
                <div className="u-searchbar-results">
                    <ul>
                        {resultList.map(function (result) {
                            return <li key={result.id}>
                                {(result.id !== -1) ?
                                    <a href={`/product${result.slug}/${result.id}/`}>
                                        {result.nombre}<span className='code'> - ({result.id})</span>
                                        <img src={result.img} />
                                    </a>
                                    : result.nombre
                                }
                            </li>
                        })}
                    </ul>
                </div>
            }
        </div>)
}

SearchBar.propTypes = {
    type: PropTypes.string.isRequired
}

export default SearchBar;