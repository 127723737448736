import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class HomeServices {
  static getListProducts(payload) {
    return axios.get(endpoints.getProductsHome, payload).then(res => res.data);
  }
  static getListFeaturedProducts(payload) {
    return axios.get(endpoints.getFeaturedProducts, payload).then(res => res.data);
  }
  static getListTrendingProducts(payload) {
    return axios.get(endpoints.getTrendingProducts, payload).then(res => res.data);
  }
  static downloadCatalogue(payload) {
    return axios.get(endpoints.downloadCatalogue, payload).then(res => res.data);
  }
  static getProductsHome(payload) {
    return axios.get(endpoints.downloadCatalogue, payload).then(res => res.data);
  }
  static getSectionsHome(payload) {
    return axios.get(endpoints.newSections, payload).then(res => res.data);
  }
  static getManifiestos(payload) {
    return axios.get(endpoints.manifiestos, payload).then(res => res.data);
  }
}

export default HomeServices;