import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class RegisterServices {
  static register(payload) {
    return axios.post(endpoints.postRegister, payload).then(res => res);
  }
  static registerReferral(payload) {
    return axios.post(endpoints.registerReferral, payload).then(res => res);
  }
  static getReferral(payload) {
    return axios.post(endpoints.getReferral, payload).then(res => res);
  }
}

export default RegisterServices;