import React from 'react';
import { connect } from 'react-redux';
import OutletProducts from '../../organisms/OutletProducts/OutletProducts';

const OutletTemplate = () => {
    return (
        <section className="t__subcategory">
            {
                <div className="container-xxl">
                    <OutletProducts />
                </div>
            }
        </section>
    )
};

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OutletTemplate);
