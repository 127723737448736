import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "./../../atoms/Button/Button";
import InputPrimary from "./../../atoms/InputPrimary/InputPrimary";
import Spacer from "../../atoms/Spacer/Spacer";
import dataLayer from "../../../common/GTM/dataLayer";
import { ToastContainer, toast, Slide } from 'react-toastify';
import CPQRS from "../../../services/Cpqrs/Cpqrs";
import { useTranslation } from "react-i18next";

const QCCTemplate = props => {
  const { t } = useTranslation();
  const defaultFormCPQRS = {
    email: "",
    asunto: "CPQRS",
    mensaje: "",
    nombre: "",
    celular: "",
    autorizacion: 1,
    tipo: 1
  };
  const [formCPQRS, setformCPQRS] = useState(defaultFormCPQRS);
  const [isChecked, setisChecked] = useState(false);
  const [isCheckedError, setisCheckedError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [mensajeError, setmensajeError] = useState(false);
  const [nombreError, setnombreError] = useState(false);
  const [celularError, setcelularError] = useState(false);

  const resetInputs = () => {
    setformCPQRS(defaultFormCPQRS);
    document.getElementById("u-mensaje").value = "";
  };

  const generatedCPQRS = () => {
    if (!validateErrors()) {
      CPQRS.addCPQRS(formCPQRS)
        .then(res => {
          if (res.estadoRespuesta === 0) {
            dataLayer.pushEvent("PreguntasQuejas", "ClicFormulario", "EnviarExitoso");
            dataLayer.pushEvent("PreguntasQuejas", "ClicFormularioCorreo", formCPQRS.email);
            resetInputs();
            toast.success(t("pages.pqrs.successful-register"), {
              className: "toast-u-container",
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              transition: Slide
            });
          } else if (res.estadoRespuesta === 1) {
            console.warn("Error técnico CPQRS:", res);
          } else if (res.estadoRespuesta === 2) {
            console.error("Los parámetros de entrada son incorrectos CPQRS:", res);
          } else if (res.estadoRespuesta === 3) {
            console.warn("Exitoso sin datos CPQRS:", res);
          }
        })
        .catch(err => console.error(err));
    }
  };

  const handleOnChange = (node, event) => {
    node === "nombre"
      ? setformCPQRS({
        ...formCPQRS,
        nombre: event.target.value.replace(/ {2}/g, " ")
      })
      : node === "celular"
        ? setformCPQRS({
          ...formCPQRS,
          celular: event.target.value.slice(0, 10).replace(/ /g, "")
        }
        )
        : node === "email"
          ? setformCPQRS({
            ...formCPQRS,
            email: event.target.value.replace(/ /g, "")
          }
          )
          : node === "mensaje"
            ? setformCPQRS({
              ...formCPQRS,
              mensaje: event.target.value
            }
            )
            : setformCPQRS({ ...formCPQRS });
  };

  const validateErrors = () => {
    if (
      formCPQRS.nombre === "" ||
      formCPQRS.nombre === " "
    ) {
      setnombreError(true);
    } else {
      setnombreError(false);
    }

    if (formCPQRS.mensaje.trim() === "") {
      setmensajeError(true);
    } else {
      setmensajeError(false);
    }

    if (
      formCPQRS.celular === "" ||
      formCPQRS.celular > 9999999999 ||
      formCPQRS.celular < 1000000000
    ) {
      setcelularError(true);
    } else {
      setcelularError(false);
    }

    if (
      formCPQRS.email === "" ||
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        formCPQRS.email
      )
    ) {
      setemailError(true);
    } else {
      setemailError(false);
    }

    if (!isChecked) {
      setisCheckedError(true);
    } else {
      setisCheckedError(false);
    }

    return (
      !isChecked ||
      formCPQRS.nombre === "" ||
      formCPQRS.nombre === " " ||
      formCPQRS.celular === "" ||
      formCPQRS.celular > 9999999999 ||
      formCPQRS.celular < 1000000000 ||
      formCPQRS.mensaje.trim() === "" ||
      formCPQRS.mensaje === " " ||
      formCPQRS.email === "" ||
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        formCPQRS.email
      )
    );
  };

  return (
    <section className="p__qcc">
        <Spacer px={40} />
      <ToastContainer />
      <div className="container">
        <Spacer px={30} hideon="small" />
        <h1 className="max-width-800">
          {t("pages.questions-complaints-and-claims.title")}
        </h1>
        <Spacer px={50} hideon="small" />
        <div className="white-card-bkg">
          <form className="max-width-800">
            <Spacer px={30} hideon="small" />
            <div className="row">
              <div className="col-sm-6 col-12">
                <InputPrimary
                  label={t("pages.questions-complaints-and-claims.full-name")}
                  type={"text"}
                  value={formCPQRS.nombre}
                  onchange={e => {
                    handleOnChange("nombre", e);
                  }}
                  isUserError={nombreError}
                  placeholder={t("pages.questions-complaints-and-claims.full-name-placeholder")}
                ></InputPrimary>
              </div>
              <div className="col-sm-6 col-12">
                <InputPrimary
                  label={t("pages.questions-complaints-and-claims.phone")}
                  type={"number"}
                  value={formCPQRS.celular}
                  onchange={e => {
                    handleOnChange("celular", e);
                  }}
                  isUserError={celularError}
                  placeholder={t("pages.questions-complaints-and-claims.phone-placeholder")}
                ></InputPrimary>
              </div>
              <div className="col-sm-12 col-12">
                <InputPrimary
                  label={t("pages.questions-complaints-and-claims.email")}
                  type={"text"}
                  value={formCPQRS.email}
                  onchange={e => {
                    handleOnChange("email", e);
                  }}
                  isUserError={emailError}
                  placeholder={t("pages.questions-complaints-and-claims.email-placeholder")}
                ></InputPrimary>
              </div>
              <div className="col-12">
                <InputPrimary
                  label={t("pages.questions-complaints-and-claims.message")}
                  type={"textarea"}
                  value={formCPQRS.mensaje}
                  onchange={e => {
                    handleOnChange("mensaje", e);
                  }}
                  id={"u-mensaje"}
                  isUserError={mensajeError}
                  placeholder={t("pages.questions-complaints-and-claims.message-placeholder")}
                ></InputPrimary>
              </div>
            </div>
            <Spacer px={35} hideon="small" />
            <div className="center-content">
              <div className="a__check-box">
                <label
                  className={isCheckedError ? "a__checkError" : ""}
                >
                  {" "}
                  {t("pages.questions-complaints-and-claims.terms-and-conditions.accept")}{" "}
                  <Link to="/terms-and-conditions">{t("pages.questions-complaints-and-claims.terms-and-conditions.terms")}</Link>
                  <input
                    type="checkbox"
                    className="gtmPreguntasQuejasTerminosCondicionesAceptar"
                    onChange={() => {
                      setisChecked(!isChecked);
                    }}
                  ></input>
                  <span></span>
                </label>
              </div>
              <Spacer px={15} hideon="small" />
              <Button
                onclick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  generatedCPQRS();
                }}
                classnames="gtmPreguntasQuejasEnviar primary"
                text={t("pages.questions-complaints-and-claims.btn-submit")}
                type="button"
              ></Button>
              <Spacer px={50} hideon="small" />
            </div>
          </form>
        </div>
        <Spacer px={150} hideon="small" />
      </div>
    </section>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QCCTemplate);
