import React, { useState, useEffect } from "react";
import CustomSlider from "../../organisms/CustomSlider/CustomSlider";
import CustomSectionOne from "../../organisms/CustomSectionOne/CustomSectionOne";
import CustomSectionTwo from "../../organisms/CustomSectionTwo/CustomSectionTwo";
import CustomSectionThree from "../../organisms/CustomSectionThree/CustomSectionThree";
import CustomSectionFour from "../../organisms/CustomSectionFour/CustomSectionFour";
import Loader from "../../atoms/Loader/Loader";
import { useTranslation } from "react-i18next";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import HomeServices from "../../../services/HomeServices/HomeServices";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import BtnClienteFinal from "../../../assets/images/pup-up-final.jpg";
import BtnDistribuidor from "../../../assets/images/pup-up-mayoristas.jpg";
import facebook from "../../../assets/images/facebook.png";
import instagram from "../../../assets/images/instagram.png";
import twitter from "../../../assets/images/twitter.png";
import MetaTags from "react-meta-tags";
import * as constants from "./../../../state/constants/appConstants";
import Slider from "react-slick";

const HomeTemplate = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [listProductsHome, setListProductsHome] = useState([]);
  const [listFeaturedProducts, setlistFeaturedProducts] = useState([]);
  const [listTrendingProducts, setlistTrendingProducts] = useState([]);
  const [sectionsOne, setSesctionsOne] = useState();
  const [sectionsTwo, setSesctionsTwo] = useState();
  const [sectionsThree, setSesctionsThree] = useState();
  const [sectionsFour, setSesctionsFour] = useState();
  const [sectionsFive, setSesctionsFive] = useState();
  const [lPrecio, setlPrecio] = useState();
  var rolUsuario = constants.rolUsuario; // 1 > final, 2 > codificado, 3 > hogares felices;
  const [modalStarted, setModalStarted] = useState(
    rolUsuario != 2 && !document.referrer.includes("unitec") ? true : false
  );
  const _responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ];
  function subDomain(url) {
    // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
    url = url.replace(new RegExp(/^\s+/), ""); // START
    url = url.replace(new RegExp(/\s+$/), ""); // END

    // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
    url = url.replace(new RegExp(/\\/g), "/");

    // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
    url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), "");

    // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
    url = url.replace(new RegExp(/^www\./i), "");

    // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
    url = url.replace(new RegExp(/\/(.*)/), "");

    // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
    if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
      url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), "");

      // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
    } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
      url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), "");
    }

    // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
    var subDomain = url.match(new RegExp(/\./g)) ? true : false;

    return subDomain;
  }
  const getCurrentLng = () =>
    useTranslation.language || localStorage.getItem("i18nextLng") || "";
  useEffect(() => {
    const userInfo = JSON.parse(UserInfo.getUser());
    setlPrecio(userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20);
    const getListProductHome = async () => {
      let payload = {
        params: {
          idioma: getCurrentLng(),
          //  rolUsuario: (userInfo && userInfo.rol) ? userInfo.rol : 1
          rolUsuario,
        },
      };

      const result = await HomeServices.getListProducts(payload);
      if (result.estadoRespuesta === 0) {
        //console.log("Products Home:", result);
        setListProductsHome(result.productosHome);
        //while we do the tests
        setIsLoading(false);
      } else {
        console.warn("Error products home: ", result);
        setIsLoading(false);
      }
    };
    const getSectionsHome = async (section) => {
      let payload = {
        params: {
          idioma: getCurrentLng(),
          //rolUsuario: (userInfo && userInfo.rol) ? userInfo.rol : 1
          rolUsuario,
          seccion: section,
          listaPrecio:
            userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
          /* linea: '0001',  */
        },
      };
      const result = await HomeServices.getSectionsHome(payload);
      switch (section) {
        case 1:
          setSesctionsOne(result);
          break;
        case 2:
          setSesctionsTwo(result);
          break;
        case 3:
          setSesctionsThree(result);
          break;
        case 4:
          setSesctionsFour(result);
          break;
        case 5:
          setSesctionsFive(result);
          //console.log(result);
          break;

        default:
          break;
      }
      if (section < 5) {
        getSectionsHome(++section);
      }
    };
    const getListProductRecommended = async () => {
      let payload = {
        params: {
          idioma: getCurrentLng(),
          //rolUsuario: (userInfo && userInfo.rol) ? userInfo.rol : 1
          rolUsuario,
        },
      };
      const result = await HomeServices.getListFeaturedProducts(payload);
      if (result.estadoRespuesta === 0) {
        //console.log("Products recommended:", result);
        setlistFeaturedProducts(result.productosDestacados);
      } else {
        console.warn("Error products recommended: ", result);
        setIsLoading(false);
      }
    };

    const getListProductTrending = async () => {
      let payload = {
        params: {
          idioma: getCurrentLng(),
          //  rolUsuario: (userInfo && userInfo.rol) ? userInfo.rol : 1
          rolUsuario,
        },
      };
      const result = await HomeServices.getListTrendingProducts(payload);

      if (result.estadoRespuesta === 0) {
        // console.log("Products trending:", result);
        setlistTrendingProducts(result.productosTendencias);
      } else {
        console.warn("Error products trending: ", result);
        setIsLoading(false);
      }
    };

    getListProductHome();
    getListProductRecommended();
    getListProductTrending();
    getSectionsHome(1);
  }, []);

  const handleClose = () => {
    setModalStarted(false);
  };
  /* const handleShow = () => setModalStarted(true);
  const redirectCredit = () => (window.location = "/credito-epm"); */
  useEffect(() => {
    const userInfo = JSON.parse(UserInfo.getUser());
    if (
      listProductsHome.length > 0 &&
      listTrendingProducts.length > 0 &&
      rolUsuario != 2 &&
      listFeaturedProducts.length > 0
    ) {
      setIsLoading(false);
    } else if (rolUsuario === 2) {
      setIsLoading(false);
    }
  }, [listProductsHome, listTrendingProducts, listFeaturedProducts]);

  return isLoading === true ? (
    <Loader />
  ) : (
    <div>
      <MetaTags>
        <meta
          name="description"
          content="Te ofrecemos un amplio portafolio en las líneas de tecnología, ferretería,
iluminación LED, movilidad eléctrica, proyectos especiales y todo lo
relacionado para el hogar"
        />
        <meta property="og:title" content="Inicio" />
        <meta
          name="facebook-domain-verification"
          content="yp0ngbax7vbx5eye395o7pkc5xt3kw"
        />
      </MetaTags>
      {/* {rolUsuario === 2 ? (
        <section className="t__home"></section>
      ) : (
        <> */}
      <section className="t__home">
        {sectionsFive && (
          <Slider
            dotsClass="slider-dots--banner"
            className="o__banner-slider"
            slidesToShow={1}
            dots={true}
            autoplay={true}
            autoplaySpeed={3500}
          >
            <img src={sectionsFive.banner[0].infobanner[0].imagen} />
          </Slider>
          /*  <CustomSlider
            slider_class="o__banner-slider"
            slider_slidestoshow={1}
            slider_dots={true}
            slider_dotclass="slider-dots--banner"
            image_class="o__banner-image"
            array={listProductsHome}
            type="banner"
            autoplay={true}
            autoplaySpeed={3500}
          /> */
        )}
        <div className="container-xxl  mb-2">
          {listFeaturedProducts && (
            <CustomSlider
              slider_class="o__featured-slider slider-destacados"
              title={t("pages.home.subtitle-featured-products")}
              titleclass="title-tendencias"
              slider_dots={false}
              slider_slidestoshow={4}
              slider_dotclass="slider-dots--banner"
              image_class="o__featured-image"
              array={listFeaturedProducts}
              type="img"
              autoplay={true}
              autoplaySpeed={4000}
              responsive={_responsive}
            />
          )}
        </div>
        <div className="container-xxl  mb-5">
          {listTrendingProducts && (
            <CustomSlider
              slider_class="o__featured-slider slider-tendencias"
              title={t("pages.home.subtitle-trends")}
              titleclass="title-destacados"
              slider_dots={false}
              slider_slidestoshow={4}
              slider_dotclass="slider-dots--banner"
              image_class="o__featured-image"
              array={listTrendingProducts}
              type="img"
              autoplay={true}
              autoplaySpeed={4000}
              responsive={_responsive}
            />
          )}
        </div>
        {sectionsFour && (
          <CustomSectionFour data={sectionsFour}></CustomSectionFour>
        )}
        {sectionsOne && (
          <CustomSectionOne
            data={sectionsOne}
            listaPrecio={lPrecio}
          ></CustomSectionOne>
        )}
        {sectionsTwo && (
          <CustomSectionTwo data={sectionsTwo}></CustomSectionTwo>
        )}
        {sectionsThree && (
          <CustomSectionThree data={sectionsThree}></CustomSectionThree>
        )}
      </section>

      {/*       </>
      )} */}
      <Modal show={modalStarted} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton className="t__closeButton"></Modal.Header>
        <Modal.Body>
          <div className="row t__modal-container">
            <div className="col-12" style={{ textAlign: "center" }}>
              <div className="row">
                {/* <div className="col-12" style={{ textAlign: "center" }}>
                  <Spacer px={10} />
                  <img
                    src={Popup}
                    style={{ maxWidth: "80%", margin: "0px auto" }}
                  />
                  <Spacer px={10} />
                </div> */}
                <div
                  className="col-sm-12 col-md-6 btn-scale btn-popup"
                  style={{ padding: "10px 5px 10px 25px" }}
                  onClick={() => {
                    if (constants.rolUsuario === 1) {
                      window.location.href =
                        "https://distribuidor.unitecusa.com.co/";
                    } else if (constants.rolUsuario === 2) {
                      setModalStarted(false);
                    }
                  }}
                >
                  <img src={BtnDistribuidor} style={{ margin: "0px auto" }} />
                </div>
                <div
                  className="col-sm-12 col-md-6 btn-scale btn-popup"
                  style={{ padding: "10px 25px 10px 5px" }}
                  onClick={() => {
                    if (constants.rolUsuario === 2) {
                      window.location.href = "https://unitecusa.com.co/";
                    } else if (constants.rolUsuario === 1) {
                      setModalStarted(false);
                    }
                  }}
                >
                  {/* <p>
                    * Si eres cliente final y no distribuidor activo Unitec,
                    <br />
                    haz click aquí
                  </p> */}
                  <img src={BtnClienteFinal} style={{ margin: "0px auto" }} />
                </div>
              </div>
            </div>
            <div className="col-12  t__modal-redes">
              <ul>
                <li>
                  <img
                    src={facebook}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/UnitecUsa/",
                        "_blanc"
                      )
                    }
                  />
                </li>
                <li>
                  <img
                    src={instagram}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/unitec_usa/",
                        "_blanc"
                      )
                    }
                  />
                </li>
                <li>
                  <img
                    src={twitter}
                    onClick={() =>
                      window.open("https://twitter.com/UsaUnitec/", "_blanc")
                    }
                  />
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeTemplate;
