import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import HomeServices from "../../../services/HomeServices/HomeServices";
import * as constants from "./../../../state/constants/appConstants";
import NumberFormat from "react-number-format";

const CustomSectionOne = ({ data, listaPrecio }) => {
  const [titleOne, setTitleOne] = useState();
  const [categoriesBannerOne, setCategoriasBannerOne] = useState();
  const [bannerOne, setBannerOne] = useState();
  const [URLBannerOne, setURLBannerOne] = useState();
  const [productsSectionOne, setProductsSectionOne] = useState();
  const [titleTwo, setTitleTwo] = useState();
  const [categoriesBannerTwo, setCategoriasBannerTwo] = useState();
  const [bannerTwo, setBannerTwo] = useState();
  const [URLBannerTwo, setURLBannerTwo] = useState();
  const [productsSectionTwo, setProductsSectionTwo] = useState();
  var rolUsuario = constants.rolUsuario; // 1 > final, 2 > codificado, 3 > hogares felices;

  useEffect(() => {
    if (data.banner) {
      let content = data.banner[0] || null;
      if (content) {
        if (content.infobanner) {
          setTitleOne(content.infobanner[0].titulo);
        }
        if (content.infobanner) {
          setBannerOne(content.infobanner[0].imagen);
        }
        if (content.infobanner) {
          setURLBannerOne(content.infobanner[0].url);
        }
        if (content.categoriasbanner) {
          setCategoriasBannerOne(content.categoriasbanner);
        }
        if (content.productosDestacados) {
          setProductsSectionOne(content.productosDestacados);
        }
      }
      let contentTwo = data.banner[1] || null;
      if (contentTwo) {
        if (contentTwo.infobanner) {
          setTitleTwo(contentTwo.infobanner[0].titulo);
        }
        if (contentTwo.infobanner) {
          setBannerTwo(contentTwo.infobanner[0].imagen);
        }
        if (contentTwo.infobanner) {
          setURLBannerTwo(contentTwo.infobanner[0].url);
        }
        if (contentTwo.categoriasbanner) {
          setCategoriasBannerTwo(contentTwo.categoriasbanner);
        }
        if (contentTwo.productosDestacados) {
          setProductsSectionTwo(contentTwo.productosDestacados);
        }
      }
    }
    //console.log(data);
  }, []);
  const getSectionsHome = async (linea, id, prepo) => {
    let payload = {
      params: {
        idioma: "es",
        //rolUsuario: (userInfo && userInfo.rol) ? userInfo.rol : 1
        rolUsuario,
        seccion: 1,
        linea,
        listaPrecio
      },
    };
    console.log(payload);
    const result = await HomeServices.getSectionsHome(payload);
    if (result.estadoRespuesta === 0) {
      if (prepo === "licat_") {
        setProductsSectionOne(result.banner[id].productosDestacados);
      } else {
        setProductsSectionTwo(result.banner[id].productosDestacados);
      }
      console.log(result.banner[id].productosDestacados);
    }
  };
  const changeCate = (e, line, id, prepo) => {
    let _length =
      prepo === "licat_"
        ? categoriesBannerOne.length
        : categoriesBannerTwo.length;
    for (let index = 0; index < _length; index++) {
      let _li = document.getElementById(`${prepo}${index}`);
      _li.classList.remove("catActive");
    }
    e.currentTarget.className = "catActive";
    getSectionsHome(line, id, prepo);
  };
  return (
    <>
      <div className="ContainerSectionOne container-xxl">
        <div className="row ContainerSectionOneBorderBottom">
          <div className="col-xl-6">
            <div className="containerTitle">
              {titleOne && <h3 className="titleSectionOne">{titleOne}</h3>}
            </div>
          </div>
          <div className="col-xl-6">
            {categoriesBannerOne && (
              <div className="containerCategories">
                <ul>
                  {categoriesBannerOne.map((cat, i) => {
                    return (
                      <li
                        key={i}
                        id={`licat_${i}`}
                        onClick={(e) => changeCate(e, cat.id, i, "licat_")}
                      >
                        {cat.nombre}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="containerImgProducts">
              {bannerOne && (
                <Link to={URLBannerOne}>
                  <img src={bannerOne} />
                </Link>
              )}
            </div>
          </div>
          <div className="col-xl-8 padding-cero">
            <div className="containerProducts">
              {productsSectionOne && (
                <ul>
                  {productsSectionOne.map((prod, i) => {
                    if (i >= 4) {
                      return;
                    }
                    return (
                      <li key={`prods_one_${i}`}>
                        <Link to={`/product${prod.slug}/${prod.id}`}>
                          <img src={prod.img} />
                          <h5>{prod.nombre}</h5>
                          <p>{prod.descripcion.slice(0, 30)}</p>
                          {prod.porcentajeDescuento > 0 && (
                            <NumberFormat
                              className="t__product-small--outlet"
                              value={prod.precioConDescuento.COP}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          )}
                          <NumberFormat
                            className="t__product-small"
                            value={prod.precioAnterior.COP}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ContainerSectionOne container-xxl">
        <div className="row ContainerSectionOneBorderBottom">
          <div className="col-xl-6">
            <div className="containerTitle">
              {titleTwo && <h3 className="titleSectionOne">{titleTwo}</h3>}
            </div>
          </div>
          <div className="col-xl-6">
            {categoriesBannerTwo && (
              <div className="containerCategories">
                <ul>
                  {categoriesBannerTwo.map((cat, i) => {
                    return (
                      <li
                        key={i}
                        id={`licat_two_${i}`}
                        onClick={(e) => changeCate(e, cat.id, i, "licat_two_")}
                      >
                        {cat.nombre}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="containerImgProducts">
              {bannerTwo && (
                <Link to={URLBannerTwo}>
                  <img src={bannerTwo} />
                </Link>
              )}
            </div>
          </div>
          <div className="col-xl-8 padding-cero">
            <div className="containerProducts">
              {productsSectionTwo && (
                <ul>
                  {productsSectionTwo.map((prod, i) => {
                    if (i >= 4) {
                      return;
                    }
                    return <li key={`prods_two_${i}`}>
                      <Link to={`/product${prod.slug}/${prod.id}`}>
                        <img src={prod.img} />
                        <h5>{prod.nombre}</h5>
                        <p>{prod.descripcion.slice(0, 30)}</p>
                        {prod.porcentajeDescuento > 0 && (
                          <NumberFormat
                            className="t__product-small--outlet"
                            value={prod.precioConDescuento.COP}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        )}
                        <NumberFormat
                          className="t__product-small"
                          value={prod.precioAnterior.COP}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Link>
                    </li>
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
CustomSectionOne.propTypes = {
  data: PropTypes.object.isRequired,
};
export default CustomSectionOne;
