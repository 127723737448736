import * as types from '../constants/actionConstants';

const INITIAL_STATE = {
    cart: {}
};

const cart = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.GET_CART:
            return {
                cart: payload
            }
        default:
            return state;
    }
};

export default cart;