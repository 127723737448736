import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "./../../atoms/Button/Button";
import { useHistory } from "react-router-dom";

import Icon from "./../../atoms/Icon/Icon";
import CategoryServices from "../../../services/CategoryServices/CategoryServices";
import { ToastContainer, toast, Slide } from "react-toastify";

import dataLayer from "../../../common/GTM/dataLayer";
import { getCart } from "../../../state/actions/act_cart";
import { useTranslation } from "react-i18next";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import Token from "../../../common/LocalStorage/Token";
import * as constants from "./../../../state/constants/appConstants";
import IconButton from "../IconButton/IconButton";
import preLoaderIcon from "../../../assets/images/preloader.gif";

const HeaderLinks = (props) => {
  let cateName =
    constants.rolUsuario !== 3 ? "nombreCategoria" : "nombreSubcategoria";
  const getCurrentLng = () =>
    useTranslation.language || localStorage.getItem("i18nextLng") || "";
  const [activeItem, setActiveItem] =
    useState(-1); /* -1 means menu item is closed */
  const userInfo = JSON.parse(UserInfo.getUser());
  const [loggedIn, setLoggedIn] = useState(false);
  const token = Token.getToken();
  const timer = Token.getTimer() ? parseInt(Token.getTimer()) : null;
  const [listCategories, setListCategories] = useState([]);
  const cart = useSelector((state) =>
    state.rdc_cart.cart ? state.rdc_cart.cart : 0
  );
  const [totalCartNumber, setTotalCartNumber] = useState(0);
  const { i18n, t } = useTranslation();
  const history = useHistory();

  //console.log(window.innerWidth < 1106);
  const [showBurguer, setShowBurguer] = useState(window.innerWidth < 1106);
  const [showSubmenu, setShowSubmenu] = useState(false);
  useEffect(() => {
    if (showBurguer) {
      let _cats = document.getElementById('listCategories');
      let _contenedor = document.getElementById('mainElement');
      document.getElementById('listCategories').remove();
      _contenedor.appendChild(_cats);
      setShowBurguer(false);
      // _contenedor.appendChild(_cats);
      // console.log(_cats);
      //   alert(showBurguer)
    }
  }, [showBurguer]);
  useEffect(() => {
    const getListCategories = () => {
      props.getCart();
      let payload = {
        params: {
          rolUsuario: constants.rolUsuario,
          idioma: getCurrentLng(),
        },
      };
      CategoryServices.getCategories(payload)
        .then((res) => {
          //console.log(res.categorias);
          setListCategories(res.categorias);
          if (constants.rolUsuario !== 3) {
            setListCategories(res.categorias);
          } else {
            res.categorias.forEach((element) => {
              console.log(element);
              if (element.codigo === "0006") {
                setListCategories(element.subcategorias);
              }
            });
          }
        })
        .catch((err) => console.log(err));
    };
    getListCategories();
  }, [])

  useEffect(() => {
    if (userInfo && token) {
      setTimeout(() => {
        let date = new Date();
        //  date.setHours(date.getHours() - 30);//provistional for testing
        let _timeStmp = date.getTime();
        //  console.log(_timeStmp.toString());
        if (timer) {
          let diff = parseInt(Math.floor(_timeStmp - timer) / 3600 / 1000);
          //console.log(diff);
          if (diff < 6) {
            setLoggedIn(true);
          } else {
            toast.warning(
              "Se sesión a caducado, por favor ingrese nueva mente al sistema",
              {
                className: "toast-u-container",
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                transition: Slide,
                toastId: 200,
              }
            );
            logout();
          }
        }
      }, 1000);
    } else {
      setLoggedIn(false);
    }
  }, [userInfo, token]);

  useEffect(() => {
    if (cart.length > 0) {
      let totalnumber = 0;
      for (let item of cart) {
        totalnumber += item.cantidad;
      }
      setTotalCartNumber(totalnumber);
    } else {
      setTotalCartNumber(0);
    }
  }, [cart]);

  const openModal = (nameModal) => {
    props.switchmodal(nameModal);
  };

  const handleCategory = (value) => {
    if (props.type !== "header") {
      props.setMenuView(false);
    }

    dataLayer.pushEvent("Home", "ClicMenuCategorias", value);
  };

  const handleSubCategory = (value) => {
    if (props.type !== "header") {
      props.setMenuView(false);
    }
    dataLayer.pushEvent("Home", "ClicMenuSubCategorias", value);
  };
  const activeSubMenu = () => {
    setShowSubmenu(true)
    /*  let submenu = document.getElementById("submenuuser");
     //    alert(submenu)
     submenu.classList.add("m__sub-active"); */
  };
  const deactiveSubMenu = () => {
    setShowSubmenu(false)
    /*     // alert('leave')
        let submenu = document.getElementById("submenuuser");
        //    alert(submenu)
        submenu.classList.remove("m__sub-active"); */
  };
  const logout = () => {
    // setNitCustomer(false);
    UserInfo.deleteUser();
    Token.deleteToken();
    Token.deleteTimer();
    history.push("/");
    window.location.reload();
  };
  return (
    <>
      <div
        className={
          props.type === "header" ? "m__header-links" : "m__burguer-links"
        }
        id="mainElement"
      >
        <ToastContainer />

        <Button
          text="PROMOCIONES"
          classnames={
            props.type === "header"
              ? "nav-button gtmHomeSupOutlet"
              : "nav-button nav-button--burguer gtmHomeSupOutlet"
          }
          type="link"
          href={"/outlet"}
        />

        {props.type === "header" ? (
          <div className="m__nav-btn">
            {!loggedIn ? (
              <>
                <IconButton
                  type="userButton"
                  id="gtmHomeSupIniciarSesion"
                  gtm="gtmHomeSupIniciarSesion"
                  className={
                    loggedIn ? "headerIcon userActivated" : "headerIcon"
                  }
                />
                <div id="submenuuser" className="m__sub-menu-box">
                  <Link
                    to={"#"}
                    onClick={() => {
                      openModal("login-modal");
                    }}
                  >
                    {" "}
                    Login{" "}
                  </Link>
                </div>
              </>
            ) : (
              <IconButton
                type="userButtonActive"
                id="gtmHomeSupIniciarSesion"
                gtm="gtmHomeSupIniciarSesion"
                className={
                  loggedIn ? "headerIcon userActivated" : "headerIcon"
                }
                onclick={() => activeSubMenu()}
              />
            )}
            <IconButton
              type="carButton"
              id="gtmHomeSupCarrito"
              gtm="gtmHomeSupCarrito"
              className={loggedIn ? "headerIcon userActivated" : "headerIcon"}
              totalCartNumber={totalCartNumber}
              openModal={openModal}
            />
          </div>
        ) : null}
      </div>
      {listCategories.length > 0 ?
      <div className="m__nav-categories" id="listCategories">
        <div className="m__nav-btn">
          <div className="m__nav-btn--primary">
            <Link to="/">Inicio</Link>
          </div>
        </div>
        { listCategories?.map((cat, i) => (
            <div className="m__nav-btn" key={i}>
              <div className="m__nav-btn--primary">
                <Link
                  /* to={`/cat/${constants.rolUsuario !== 3 ? cat.codigo + cat.slug : "0006"
                  }/`} */
                  to="#"
                  className={`gtmHomeSupCateg${cat[cateName].replace(
                    /\s/g,
                    ""
                  )}`}
                  onClick={() => handleCategory(cat[cateName])}
                >
                  {cat[cateName]}
                </Link>
                {props.type !== "header" && (
                  <Icon
                    icon={
                      `icon-chevron-right btn-arrow ` +
                      (activeItem === 0 && `rotate-btn`)
                    }
                    size="1-5"
                    tagtype="span"
                    onclick={() => {
                      activeItem === 0 ? setActiveItem(-1) : setActiveItem(0);
                    }}
                  />
                )}
                {cat?.subcategorias && (
                  <div
                    className={
                      `m__nav-btn--secondary ` +
                      (activeItem === 0 ? `open-secondary` : ` `)
                    }
                  >
                    {cat.subcategorias.map((subcat, i) => (
                      <Link
                        to={`/cat/${cat.codigo}/subcat/${subcat.codigo + subcat.slug
                          }/`}
                        className={`gtmHomeSupSubcateg${subcat.nombreSubcategoria.replace(
                          /\s/g,
                          ""
                        )}`}
                        onClick={() => {
                          handleSubCategory(
                            cat[cateName] + "_" + subcat.nombreSubcategoria
                          );
                        }}
                        key={i}
                      >
                        {subcat.nombreSubcategoria}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      :
      <div className="m__nav-categories" id="listCategories">
         <img src={preLoaderIcon} style={{ maxWidth: 50 }} />
      </div> 
    }
      {showSubmenu &&
        <div id="submenuuser" className="m__sub-menu-box" onMouseLeave={() => deactiveSubMenu()}>
          <h6 gtm="gtmCerrarSubMenu" className="gtmCerrarSubMenu" onClick={() => deactiveSubMenu()}>X</h6>
          <h6 gtm="gtmNombreUsuario" style={{ textDecoration: 'underline', color: '#1b398e', fontWeight: 'bold', marginTop: 10 }}>{`${userInfo.nombre} ${userInfo.apellido}`}</h6>
          <hr />
          {constants.rolUsuario === 2 && (
            <>
              <a className="gtmHomeSupCerrarSesion" href="/debs-to-pay">
                Cartera
              </a>
              <hr />
               <a className="gtmHomeSupCerrarSesion" href="/manifiestos">
                Manifiestos
              </a>
              <hr />
            </>
          )}
          {constants.rolUsuario <= 2 && (
            <>
              <a className="gtmHomeSupCerrarSesion" href="/info-user">
                Mi información personal
              </a>
              <hr />
              <a
                className="gtmHomeSupReferalForm"
                href="/referral-form"
              >
                Agregar Referidos
              </a>
              <hr />
              <a
                className="gtmHomeSupReferalBoard"
                href="/referral-board"
              >
                Mis Referidos
              </a>
              <hr />
            </>
          )}
          <a className="gtmHomeSupCerrarSesion" href="/payment-history">
            Historial de pedidos
          </a>
          <hr />
          <a className="gtmHomeSupCerrarSesion" href="/reset-password">
            Cambiar contraseña
          </a>
          <hr />
          <button
            className="gtmHomeSupCerrarSesion"
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            {t("nav.log-out")}
          </button>
        </div>
      }
    </>
  );
};

HeaderLinks.propTypes = {
  type: PropTypes.string.isRequired,
  switchmodal: PropTypes.func,
  setMenuView: PropTypes.func,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  getCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
