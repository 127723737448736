export class dataLayer {

    pushEvent(category, action, tag) {
        if (!window.dataLayer) {
            return false;
        }

        window.dataLayer.push({
            'event': 'pushEventGA',
            'categoria': category,
            'accion': action,
            'etiqueta': tag
        });
    }
}

export default new dataLayer();