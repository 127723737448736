import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DateCountdown from "react-date-countdown-timer";
const CountDown = ({ start, end }) => {
  const [toDate, setToDate] = useState();
  useEffect(() => {
    console.log(start, end);
    let date = new Date(end); 
    //date = date.addDays(5);
    console.log(end);
    setToDate(date); 
  }, []);
  const toTimestamp = (strDate) => {
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  return (
    <>
      <div className="containerCountDown">
        {toDate && <DateCountdown dateTo={toDate} />}
        {/*  <h6>{`${start}-${end}`}</h6> */}
      </div>
    </>
  );
};
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
CountDown.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
};

export default CountDown;
