import React from "react";
import PropTypes from "prop-types";
import Icon from "../../atoms/Icon/Icon";
import { useTranslation } from "react-i18next";

const LightBoxHeader = props => {
  const { t } = useTranslation();
  return (
    <div className="m__lightboxheader">
      <h5>
        <Icon
          size="1"
          tagtype="span"
          icon={
            props.modaltype === "cart-modal"
              ? "icon-shopping-cart1 cerulean"
              : props.modaltype === "login-modal"
                ? "icon-user cerulean"
                : ""
          }
        />{" "}
        {props.modaltype === "cart-modal"
          ? t("pages.shopping-cart.title")
          : props.modaltype === "login-modal"
            ? t("pages.log-in.title")
            : t("pages.checkout.modal-header")}
      </h5>
      <span className={`${(props.modaltype === 'cart-modal') ? 'gtmCarritoDeCompraCerrar' : ''} m__lb-close`} onClick={props.onclick}>
        <Icon
          gtm={(props.modaltype === 'cart-modal') ? 'gtmCarritoDeCompraCerrar' : ''}
          size="1-5"
          tagtype="span"
          icon={"icon-plus hit-gray rotate-icon"}
        />
      </span>
    </div>
  );
};
LightBoxHeader.propTypes = {
  modaltype: PropTypes.string.isRequired,
  onclick: PropTypes.func.isRequired
};

export default LightBoxHeader;
