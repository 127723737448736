import React, { useEffect } from "react";
import { connect } from "react-redux";
import LoginTemplate from "./../templates/LoginTemplate/LoginTemplate";

const Login = (props) => {
  const { handleswitchmodal } = props;
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LoginTemplate handleswitchmodal={handleswitchmodal} />
  );
}; 

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
