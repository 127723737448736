import axios from "axios";
import * as endpoints from "../EndPoints/EndPoints";

class RecoverPassServices {
  static send(payload) {
    return axios.post(endpoints.postSendPassword, payload).then((res) => res);
  }
  static reset(payload) {
    return axios.post(endpoints.postRecoverPass, payload).then((res) => res);
  }
  static check(payload) {
    return axios.post(endpoints.verificarToken, payload).then((res) => res);
  }
  static change(payload) {
    return axios.post(endpoints.modificarPass, payload).then((res) => res);
  }
  static remember(payload) {
    return axios.post(endpoints.recordarmail, payload).then((res) => res);
  }
  static recover(payload) {
    return axios
      .post(endpoints.recuperarcontrasena, payload)
      .then((res) => res);
  }
}

export default RecoverPassServices;
