import { handleActions } from 'redux-actions';
import {
    getBannerProductsHome,
    bannerProductsHome,
    getProductsRecommendedHome,
    productsRecommendedHome,
    getProductsTrendingHome,
    productsTrendingHome
} from './ProductsActions'

const defaultState = {
    loading: false
};

const reducer = handleActions(
    {
        [getBannerProductsHome]:
            (state) => ({ ...state, loading: true })
        ,
        [bannerProductsHome]: {
            next(state, action) { return { ...state, bannerProductsHome: action.payload, loading: false } },
            throw(state, action) { return { ...state, bannerProductsHome: action.payload.message, loading: false } },
        },
        [getProductsRecommendedHome]:
            (state) => ({ ...state, loading: true })
        ,
        [productsRecommendedHome]: {
            next(state, action) { return { ...state, productsRecommendedHome: action.payload, loading: false } },
            throw(state, action) { return { ...state, productsRecommendedHome: action.payload.message, loading: false } },
        },
        [getProductsTrendingHome]:
            (state) => ({ ...state, loading: true })
        ,
        [productsTrendingHome]: {
            next(state, action) { return { ...state, productsTrendingHome: action.payload, loading: false } },
            throw(state, action) { return { ...state, productsTrendingHome: action.payload.message, loading: false } },
        }
    },
    defaultState
);

export default reducer;