import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { PublicRoute } from "./../../utils/RoutesHelper";
import PropTypes from "prop-types";
import HomeTemplate from "./../templates/HomeTemplate/HomeTemplate";
import CategoryTemplate from "./../templates/CategoryTemplate/CategoryTemplate";
import SubCategoryTemplate from "./../templates/SubCategoryTemplate/SubCategoryTemplate";
import ProductTemplate from "./../templates/ProductTemplate/ProductTemplate";
import SomosCreditTemplate from "../templates/SomosCreditTemplate/SomosCreditTemplate";
import CheckoutTemplate from "./../templates/CheckoutTemplate/CheckoutTemplate";
import OutletTemplate from "./../templates/OutletTemplate/OutletTemplate";
import ContactUs from "./../pages/ContactUs";
import Register from "../pages/Register";
import WorkWithUs from "./../pages/WorkWithUs";
import AboutUs from "./../pages/AboutUs";
import Faq from "./../pages/FAQ";
import PersonalPolicy from "./../pages/PersonalPolicy";
import PricesPolicy from "./../pages/PricesPolicy";
import ForeignSales from "./../pages/ForeignSales";
import PlaceToPay from "./../pages/PlaceToPay";
import WarrantyPolicy from "./../pages/WarrantyPolicy";
import RecoverPassword from "./../pages/RecoverPassword";
import ResetPassword from "./../pages/ResetPassword";
import Manifiestos from "./../pages/Manifiestos";
import QCC from "./../pages/QCC";
import TermsAndConditions from "../pages/TermsAndConditions";
import InConstruction from "../templates/inConstruction/InConstruction";
import PaymentHistoryTemplate from "../templates/PaymentHistoryTemplate/PaymentHistoryTemplate";
import PaymentProcessed from "../templates/PaymentProcessed/PaymentProcessed";
import PaymentCancelled from "../templates/PaymentCancelled/PaymentCancelled";
import DebsToPayTemplete from "../templates/DebsToPayTemplate/DebsToPayTemplate";
import ReferralFormTemplate from "../templates/ReferralFormTemplate/ReferralFormTemplate";
import ReferralBoardTemplate from "../templates/ReferralBoardTemplate/ReferralBoardTemplate";

import InfoUser from "./../pages/InfoUser";
import LoginInfo from "./../pages/Login";
import * as constants from "../../state/constants/appConstants";
import Token from "../../common/LocalStorage/Token";

const Routes = (props) => {
  const { handleswitchmodal, auth } = props;
  var rolUsuario = constants.rolUsuario; // 1 > final, 2 > codificado, 3 > hogares felices;
  let token = Token.getToken();
  let loggedIn = !token && rolUsuario === 2 ? false : true;
  return (
    <>
      {loggedIn ? (
        <Switch>
          <PublicRoute exact auth={auth} path="/" component={HomeTemplate} />
          <PublicRoute
            exact
            auth={auth}
            path="/checkout/:step"
            handleswitchmodal={handleswitchmodal}
            component={CheckoutTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/credito-epm/"
            handleswitchmodal={handleswitchmodal}
            component={SomosCreditTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/manifiestos/"
            component={Manifiestos}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/register"
            handleswitchmodal={handleswitchmodal}
            component={Register}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/contact-us"
            component={ContactUs}
          />
          <PublicRoute exact auth={auth} path="/about-us" component={AboutUs} />
          <PublicRoute exact auth={auth} path="/faq" component={Faq} />
          <PublicRoute
            exact
            auth={auth}
            path="/work-with-us"
            component={WorkWithUs}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/recover-password"
            component={RecoverPassword}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/reset-password"
            handleswitchmodal={handleswitchmodal}
            component={ResetPassword}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/questions-and-claims"
            component={QCC}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/personal-info-policy"
            component={PersonalPolicy}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/foreign-sales-policy"
            component={ForeignSales}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/palce-to-pay-faqs"
            component={PlaceToPay}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/prices-policy"
            component={PricesPolicy}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/warranty-policy"
            component={WarrantyPolicy}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/outlet"
            component={OutletTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/in-construction"
            component={InConstruction}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/payment-history"
            component={PaymentHistoryTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/debs-to-pay"
            component={DebsToPayTemplete}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/pago-procesado/:id"
            component={PaymentProcessed}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/pago-cancelado/:id"
            component={PaymentCancelled}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/cat/:id/:category/"
            component={CategoryTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/cat/:catid/subcat/:subcatid/:category/:subcategory/:page?"
            component={SubCategoryTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/product/:category/:subcategory/:product/:productid"
            component={ProductTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/info-user"
            component={InfoUser}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/referral-form"
            component={ReferralFormTemplate}
          />
          <PublicRoute
            exact
            auth={auth}
            path="/referral-board"
            component={ReferralBoardTemplate}
          />
          <PublicRoute exact auth={auth} path="/login" component={LoginInfo} />
          <Redirect from="*" to="/" />
        </Switch>
      ) : (
        <Switch>
          <PublicRoute exact auth={auth} path="/login" component={LoginInfo} />
          <PublicRoute
            exact
            auth={auth}
            path="/recover-password"
            component={RecoverPassword}
          />
          <Redirect from="*" to="/login" />
        </Switch>
      )}
    </>
  );
};

Routes.propTypes = {
  auth: PropTypes.bool,
  handleswitchmodal: PropTypes.func,
};

export default Routes;
