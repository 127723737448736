import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/Icon/Icon';

const Stepper = props => {
    
    const { steps, activeStep } = props;

    return (
        <ul className="m__stepper"> 
            {steps.map((step, index) =>
                <li key={index} className="step-item">  
                    <div className = { `step-container ` + ( activeStep && index + 1 === activeStep ? `is-active` : `` ) + ( activeStep && index + 1 < activeStep ? `is-checked`:`` )}> 
                        <Icon icon={ ( activeStep && index + 1 <= activeStep ? `${step.icon} white` : `${step.icon} cerulean` )} tagtype="i" size="1-5" />
                    </div> 
                    <span> {step.title} </span>
                </li>
            )}
        </ul>
    )
}

Stepper.propTypes = {
    steps: PropTypes.array.isRequired,
    activeStep: PropTypes.number.isRequired
}

export default Stepper;