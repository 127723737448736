import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import { ToastContainer, toast, Slide } from 'react-toastify';
import Loader from "../../atoms/Loader/Loader";
import RecoverPassServices from "../../../services/RecoverPassServices/RecoverPassServices";
import { useTranslation } from "react-i18next";
import UserInfo from '../../../common/LocalStorage/UserInfo';
import { useHistory } from "react-router-dom";
import Token from '../../../common/LocalStorage/Token';
import ReactPasswordStrength from 'react-password-strength';
import Icon from '../../atoms/Icon/Icon';


const ResetPasswordTemplate = props => {
  const defaultForm = {
    email: ""
  };
  const [newPass, setNewPass] = useState(false);
  const [tempNewPass, setTempNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tipo1, setTipo1] = useState('password');
  const [tipo2, setTipo2] = useState('password');
  const { t } = useTranslation();
  const userInfo = JSON.parse(UserInfo.getUser());
  const [loggedIn, setLoggedIn] = useState(false);
  const [withToken, setWithToken] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowingToast, setIsShowingToast] = useState('');

  const token = Token.getToken();
  const history = useHistory();
  useEffect(() => {
    if (history.location.search.length > 0) {
      let _token = history.location.search.replace('?token=', '');
      //console.log(_token);
      setWithToken(true);
      let payload = {
        token: _token
      }
      RecoverPassServices.check(payload).then((res) => {
       // console.log(res);
        if (res.data === 0) {
        } else {
          toast.error('Su token ha vencido por favor vuelva a intentarlo', {
            className: "toast-u-container",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            transition: Slide
          });
          setWithToken(false);
        }
      })
    }
  }, [])
  useEffect(() => {
    // console.log(history);
    if (userInfo && token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      //alert('ddd')
      openModal('login-modal');
      //history.push('/')
    }
    //  console.log(userInfo);
  }, [userInfo, token]);
  const openModal = (nameModal) => {
    props.handleswitchmodal(nameModal)
  };
  /*   const validateError = () => {
      if (formData.new  === "" || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
  
      return (
        (formData.email === "") ||
        (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email))
      );
    }; */

  const reditect = () => {
    history.push('/');
  };

  const recoverPassword = () => {
    setIsLoading(true); 
    let payload = {
      email: userInfo.email,
      url: 'https://unitecusa.com.co/reset-password',
      usuario: userInfo.document
    }
    //  console.log(payload);
    RecoverPassServices.reset(payload).then(res => {
     // console.log(res)
        if (res.data.estado === 0) {
          setIsShowingToast(true);
          toast.success('Por favor revise su correo electrónico y siga las instrucciones', {
            className: "toast-u-container",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            onClose: reditect,
            transition: Slide
          });
          //reset();
        } else {
          console.warn("Response data: ", res.data);
        }
      setIsLoading(false);
    }).catch(err => {
      console.error(err);
      setIsLoading(false);
    });
  }

  const handleSubmit = event => {
    event.preventDefault();
    recoverPassword();
  };
  const handleSubmit2 = event => {
    event.preventDefault();
    if (newPass && !isLoading) {
      if (newPass.length > 0) {

        if (newPass === confirmNewPass) {
          setIsLoading(true);
          let payload = {
            email: userInfo.email,
            usuario: userInfo.document,
            contrasena: newPass
          }
          // console.log(payload);
          RecoverPassServices.change(payload).then(res => {
            //  console.log(res.data);
            setIsLoading(false);
            if (res.data === 0) {
              setIsShowingToast(true);
              toast.success('¡Su contraseña se ha cambiado con éxito!', {
                className: "toast-u-container",
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                onClose: onClose,
                transition: Slide
              });
            }
          })
        } else {
          setErrorMessage('Las contraseñas no coinciden');
        }
      } else {
        setErrorMessage('Por favor complete los campos de contraseña');
      }
    } else if (tempNewPass.length > 0) {
      setErrorMessage('La contraseña es muy débil, por favor agregue números, mayúsculas y caracteres');
    } else {
      setErrorMessage('Por favor complete los campos de contraseña');
    }
  };
  const onClose = () => {
    setIsShowingToast(false);
    history.push('/');
  }
  const handleOnChange = (state) => {
    setTempNewPass(state.password);
    if (state.isValid) {
      setNewPass(state.password);
    }
  };
  const handleOnChange2 = (e) => {
    let val = e.target.value
    if (newPass === val) {
      setConfirmNewPass(val);
    }
  };
  const replaceCharts = (str) => {
    let ret = '';
    let section1 = str.split('@')[0];
    let section2 = str.split('@')[1];
    let percentSection1 = 50;
    let percentSection2 = 20;
    for (let index = 0; index < section1.length; index++) {
      const element = section1[index];
      let percent = (index * 100) / section1.length;
      if (percent > percentSection1 && percent <= percentSection1 + 50) {
        ret += '*';
      } else {
        ret += element;
      }
    }
    ret += '@';
    for (let index = 0; index < section2.length; index++) {
      const element = section2[index];
      let percent = (index * 100) / section2.length
      if (percent > percentSection2 && percent <= percentSection2 + 30) {
        ret += '*';
      } else {
        ret += element;
      }
    }
    return ret;
  }
  return (
    <>
      {!withToken && userInfo ?
        <section className="p__recover-pwd"  >
          {isLoading === true &&
            <Loader />
          }
          <ToastContainer />
          <div className={isShowingToast ? `container uni__disabled`: 'container'} >
            <Spacer px={30} hideon="small" />
            <h1>Cambiar Contraseña</h1>
            <Spacer px={30} hideon="small" />
            <div className="white-card-bkg">
              <Spacer px={30} hideon="small" />
              <p>Te enviaremos un correo electrónico a {replaceCharts(userInfo.email)} con las instrucciones para cambiar tu contraseña. Una vez realizada esta solicitud tiene 5 minutos para hacer el cambio de contraseña</p>
              <Spacer px={20} hideon="small" />
              <form>
                <div className="center-content">
                  <Spacer px={50} hideon="small" />
                  <Button classnames="gtmIniciaSesionRestableceTuContrasena primary" text='Solicitar Cambio de contraseña' type="button" onclick={e => handleSubmit(e)}></Button>
                  <Spacer px={50} hideon="small" />
                </div>
              </form>

            </div>
            <Spacer px={150} hideon="small" />
          </div>
        </section>
        :
        <section className="p__recover-pwd">
          <div className="container">
            {isLoading === true &&
              <Loader />
            }
            <ToastContainer />
            <div className="white-card-bkg">
              <form className={isShowingToast ? "uni__disabled" : null}>
                <div className="row">
                  <div className="col-12" style={{ marginTop: 50, position: 'relative' }}>
                    <label>Nueva contraseña</label>
                    <ReactPasswordStrength
                      className="customClass"
                      style={{ display: 'block' }}
                      minLength={5}
                      minScore={2}
                      tooShortWord='Muy Corta'
                      scoreWords={['muy débil', 'débil', 'buena', 'muy fuerte', 'fuerte']}
                      changeCallback={(state, result) => handleOnChange(state)}
                      inputProps={{ name: "password", autoComplete: "off", className: "form-control changePassw", type: tipo1 }}
                    />
                    <div className={`iconShowPass activated-${tipo1}`} onClick={() => {
                      if (tipo1 === 'password') {
                        setTipo1('text')
                      } else {
                        setTipo1('password')
                      }
                    }} >
                      <Icon icon='icon-eye' tagtype="i" size="2" />
                    </div>
                    <label style={{ marginTop: 50 }}>Confirmar nueva contraseña</label>
                    <input onChange={handleOnChange2} type={tipo2} name="confirmpassword" autoComplete="off" className="ReactPasswordStrength-input form-control changePassw"></input>
                    <div className={`iconShowPass2  activated2-${tipo2}`} onClick={() => {
                      if (tipo2 === 'password') {
                        setTipo2('text')
                      } else {
                        setTipo2('password')
                      }
                    }} >
                      <Icon icon='icon-eye' tagtype="i" size="2" />
                    </div>
                  </div>
                </div>
                <div className="center-content">
                  <Spacer px={50} hideon="small" />
                  <Button classnames="gtmIniciaSesionRestableceTuContrasena primary" text='Cambiar contraseña' type="button" onclick={e => handleSubmit2(e)}></Button>
                  <Spacer px={50} hideon="small" />
                </div>
              </form>
              {errorMessage.length > 0 &&
                <div>
                  <p className='msj-error'>*{errorMessage}</p>
                </div>
              }
            </div>
          </div>
        </section>
      }
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordTemplate);
