import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Spacer from "../atoms/Spacer/Spacer";
import pdf from "../../assets/images/Download-512.png";
import HomeServices from "../../services/HomeServices/HomeServices";
import Button from "../atoms/Button/Button";
import preLoaderIcon from "../../assets/images/preloader.gif";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Resultado = ({ searchType, url, code }) => {
  const arr = url.split("/");
  let fileName = arr[arr.length - 1];
  return (
    <>
      <h2>Resultado de la búsqueda</h2>
      <Spacer px={10} />
      <Row>
        <Col xl={8}>
          <h4>
            {searchType}: <strong>{code}</strong>
          </h4>
          <h4>
            Nombre del
            <br /> archivo: <strong>{fileName}</strong>
          </h4>
        </Col>
        <Col xl={4}>
          <a target='_blank' download href={url}>
            <div className='btnPdf'>
              <img src={pdf} style={{ maxWidth: 200 }} />
              <h6>{fileName}</h6>
            </div>
          </a>
        </Col>
      </Row>
    </>
  );
};
const ErrorMessage = ({ searchType, searchValue }) => {
  return (
    <h3 style={{ textAlign: "center" }}>
      *No se encontraron resultados para {searchType}:
      <span
        style={{
          fontSize: "1.5em",
          fontFamily: '"SairaSemiCondensed-SemiBold"',
          color: "red",
        }}>
        {searchValue}
      </span>
    </h3>
  );
};
const Manifiestos = (props) => {
  const [searchType, setSearchType] = useState("serial");
  const [searchValue, setSearchValue] = useState();
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [result, setResult] = useState({});
  const [resultModal, setResultModal] = useState([]);
  const [modalStarted, setModalStarted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const onChange = (e, fn) => {
    setSearched(false);
    setResult({});
    fn(e.target.value);
  };
  const getManifiestos = async () => {
    setLoading(true);
    const payload = {
      params: {},
    };
    payload.params[searchType] = searchValue;
    const result = await HomeServices.getManifiestos(payload);
    setSearched(true);

    if (result?.manifiesto) {
      setResult(result);
    }
    setModalStarted(result?.manifiesto?.length > 1);
    setLoading(false);
  };
   const handleClose = () => {
    setModalStarted(false)
   }
   const searchSerial = (value) => {
   const r =  result?.manifiesto?.filter(m => m.serial  === value);
   setResultModal(r[0]);
   }
  return (
    <div className='contaninerManifiestos'>
      <h1>Manifiestos Unitec</h1>

      <div className='container'>
        <section>
          <div className='white-card-bkg'>
            <form onSubmit={handleSubmit}>
              <h5>Búsqueda por: </h5>
              <Row>
                <Col xl={4} md={12}>
                  <select
                    style={{ height: 60 }}
                    className='form-control'
                    value={searchType || ""}
                    onChange={(e) => onChange(e, setSearchType)}>
                    <option value='serial'>Serial</option>
                    <option value='referencia'>Referencia</option>
                  </select>
                </Col>
                <Col xl={4} md={12}>
                  <input
                    style={{ height: 60 }}
                    placeholder='Código aquí...'
                    className='form-control'
                    type='text'
                    value={searchValue || ""}
                    onChange={(e) => onChange(e, setSearchValue)}
                  />
                </Col>
                <Col xl={4} md={12}>
                  <Button
                    disabled={searchValue?.length > 0 ? false : true}
                    text='Buscar'
                    classnames='primary'
                    type='button'
                    onclick={() => getManifiestos()}
                  />
                </Col>
              </Row>
              <Spacer px={10} />
              <hr />
              {result?.manifiesto?.length === 1 && (
                <Resultado
                  url={result?.manifiesto[0].url}
                  searchType={searchType}
                  code={searchValue}
                />
              )}
              <Modal
                show={modalStarted}
                onHide={handleClose}
                size='lg'
                centered>
                <Modal.Header
                  closeButton
                  className='t__closeButton'></Modal.Header>
                <Modal.Body>
                    <h4>La referencia: {searchValue} tiene más de {result?.manifiesto?.length} seriales </h4>
                    <h3>Por favor digite aquí el seríal</h3>
                <input
                    style={{ height: 60 }}
                    placeholder='Código aquí...'
                    className='form-control'
                    type='text'
                    onChange={(e) => searchSerial(e.target.value)}
                  />
                  {resultModal?.serial && 
                    <a target='_blank' download href={resultModal?.url} style={{textAlign: 'center'}}>
                    <div className='btnPdf'>
                        <h4>Descargar</h4>
                      <img src={pdf} style={{ maxWidth: 100 }} />
                      <h6>{resultModal?.serial}</h6>
                    </div>
                  </a>
                  }
                </Modal.Body>
              </Modal>
              {!result?.manifiesto && searched && (
                <ErrorMessage
                  searchType={searchType}
                  searchValue={searchValue}
                />
              )}

              {loading && (
                <div className='m__nav-categories' id='listCategories'>
                  <img src={preLoaderIcon} style={{ maxWidth: 50 }} />
                </div>
              )}
            </form>
          </div>
          <Spacer px={30} />
        </section>
      </div>
    </div>
  );
};

export default Manifiestos;
