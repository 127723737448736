import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import LightBoxHeader from "../../molecules/LightBoxHeader/LightBoxHeader";
import { closeModal } from "../../../state/actions/act_interface";
import { getCart } from "../../../state/actions/act_cart";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import CartCardProduct from "../../molecules/CartCardProduct/CartCardProduct";
import FreeShippingBar from "../../molecules/FreeShippingBar/FreeShippingBar";
import Loader from "../../atoms/Loader/Loader";
import CheckoutServices from "../../../services/CheckoutServices/CheckoutServices";
import ProductServices from "../../../services/Products/ProductServices";
import { useTranslation } from "react-i18next";
import UserInfo from "../../../common/LocalStorage/UserInfo";
import Token from "../../../common/LocalStorage/Token";
import Cart from "../../../common/LocalStorage/Cart";
import Currency from "../../../common/LocalStorage/Currency";
import FormatNumber from "../../../utils/FormatNumber";
import dataLayer from "../../../common/GTM/dataLayer";
import LoginServices from "../../../services/LoginServices/LoginServices";
import { ToastContainer, toast, Slide } from "react-toastify";
import * as constants from "./../../../state/constants/appConstants";
import CartRender from "../../molecules/Cart/Cart";
const LightBox = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { rdc_interface, handleHideModal } = props;
  const { modalType, modalIsOpen } = rdc_interface;
  const defaultForm = {
    usuario: "",
    clave: "",
  };
  const [formData, setformData] = useState(defaultForm);
  const [usuarioError, setusuarioError] = useState(false);
  const [claveError, setclaveError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [cartItems, setCartItems] = useState();
  const [cost, setCost] = useState([]);
  const [firstTime, setFirsTime] = useState();
  const [isLightBoxFullScreen, setisLightBoxFullScreen] = useState(false);
  const [currency, setCurrency] = useState(
    Currency.getCurrency() ? Currency.getCurrency() : "COP"
  );
  const [selectCurrency, setSelectCurrency] = useState(false);
  const userInfo = JSON.parse(UserInfo.getUser());
  const [loadingCart, setLoadingCart] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [totalOrder, setTotalOrder] = constants.useStateCallback(0);
  const [totalOrderLessIVA, setTotalOrderLessIVA] = constants.useStateCallback(0);
  const [escala, setEscala] = useState();
  const [scaleSelected, setScaleSelected] = constants.useStateCallback();
  const textError = useRef(null);

  useEffect(() => {

    const handleRootNodeClick = (event) => {
      const rootNode = document.querySelector("#root");
      if (modalIsOpen) {
        rootNode.removeEventListener("mousedown", handleRootNodeClick);
        event.stopPropagation();
      }
    };
    window.addEventListener("scroll", handleAnimation, false);
    document
      .querySelector("#root")
      .addEventListener("mousedown", handleRootNodeClick);
    if (!cartItems && modalType === "cart-modal") {
      setCartItems(Cart.getCart() ? JSON.parse(Cart.getCart()) : []);
    }
  }, []);


  /*   useEffect(() => {
      let payload = {
        params: {
          rol: constants.rolUsuario,
          listaprecio:
            parseInt(userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20),
        }
      }
      // //console.log(payload);
      ProductServices.getEscalas(payload).then((res) => {
        //console.log(res.escala);
        setEscala(res.escala);
      });
  
    }, []) */


  /*   useEffect(() => {
      if (escala && escala.length > 0 && !scaleSelected && cartItems && cartItems.length > 0) {
        calculateTotals();
      }
    }, [escala, cartItems]); */


  /*  const settingScale = (_totalOrderLessIVA) => {
     let scaleSetted = false;
     escala.forEach(element => {
       if (parseFloat(element.minimo) < _totalOrderLessIVA && _totalOrderLessIVA <= parseFloat(element.maximo)) {
         console.log(convertToFloat(element))
         setScaleSelected(convertToFloat(element), r => getCalulateShipping(r));
         scaleSetted = true;
       }
     });
     if (!scaleSetted) {
       let scale = {
         descuento: 0,
         idescala: 0,
         maximo: 0,
         minimo: 0,
       }
       scaleSetted = true;
       setScaleSelected(scale, r => getCalulateShipping(r));
     }
   } */
  /*   const convertToFloat = (element) => {
      let _element = { ...element }
      for (const key in _element) {
        if (Object.hasOwnProperty.call(_element, key)) {
          _element[key] = parseFloat(_element[key]);
        }
      }
      return _element;
    } */
  /*  const calculateTotals = async () => {
     let _totalOrder = 0;
     let _totalOrderLessIVA = 0;
     const asyncRes = await Promise.all(
       cartItems.map(async (i) => {
         let pr = await getProductById(i.idProducto);
         if (pr.COP !== 0) {
           let IVA = (i.porcentajeIva / 100) + 1;
           let precioSinIVA = Math.round(pr.COP / IVA);
           _totalOrder += pr.COP * i.cantidad;
           _totalOrderLessIVA += precioSinIVA * i.cantidad;
         }
         return pr;
       })
     );
     setTotalOrder(_totalOrder, s => console.log(s))
     setTotalOrderLessIVA(_totalOrderLessIVA, l => settingScale(l))
   } */
  const handleAnimation = () => {
    if (document.documentElement.scrollTop > 2) setisLightBoxFullScreen(true);
    else if (document.documentElement.scrollTop < 2)
      setisLightBoxFullScreen(false);
  };

  const handleRootNodeClick = (event) => {
    const rootNode = document.querySelector("#root");
    if (modalIsOpen) {
      rootNode.removeEventListener("mousedown", handleRootNodeClick);
      event.stopPropagation();
    }
  };

  /* const updateProductList = (_id, cant) => {
    let indexof = cartItems.findIndex((item) => {
      return item.idProducto === _id;
    });
    cartItems.splice(indexof, 1);
    setCartItems(cartItems);
    Cart.setCart(JSON.stringify(cartItems));
    //console.log(cartItems);
    getCalulateShipping();
  }; */

  const reset = () => {
    setformData(defaultForm);
  };

  const login = () => {
    setLoginLoader(true);
    setLoginError(false);
    LoginServices.login(formData)
      .then((res) => {
        // //console.log('  >>>>  ', res);
        if (res.status === 200) {
          if (res.data.estadoRespuesta === 0) {
            let usuario = { ...res.data.datosUsuario };
            usuario.document = formData.usuario;
            UserInfo.setUserInfo(JSON.stringify(usuario));
            Token.setToken(res.data.token);
            let date = new Date();
            date.setHours(date.getHours() - 5); //provistional for testing
            Token.setTimer(date.getTime().toString());
            reset();
            handleHideModal();
            toast.success(
              "¡Unitec le da la bienvenida a nuestra tienda virtual!",
              {
                className: "toast-u-container",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                transition: Slide,
              }
            );
            setTimeout(() => {
              dataLayer.pushEvent(
                "IniciaSesion",
                "ClicIniciarSesion",
                "IngresarExitoso"
              );
            }, 5000);
            //   history.push("/");
          } else if (res.data.estadoRespuesta === 2) {
            setLoginError(true);
            textError.current.innerHTML = res.data.mensajeDeError;
          } else {
            console.warn("Response data: ", res.data);
          }
        } else {
          console.error("Response error: ", res);
        }
        setLoginLoader(false);
      })
      .catch((err) => console.error(err));
  };

  const validateErrors = () => {
    if (formData.usuario === "") {
      setusuarioError(true);
    } else {
      setusuarioError(false);
    }

    if (formData.clave === "" || formData.clave.length < 6) {
      setclaveError(true);
    } else {
      setclaveError(false);
    }

    return (
      formData.usuario === "" ||
      formData.clave === "" ||
      formData.clave.length < 6
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateErrors()) {
      login();
    }
  };

  const handleOnChange = (node, event) => {
    node === "usuario"
      ? setformData({
        ...formData,
        usuario: event.target.value.replace(/ /g, ""),
      })
      : node === "clave"
        ? setformData({
          ...formData,
          clave: event.target.value.replace(/ /g, ""),
        })
        : setformData({ ...formData });
  };

  /* const getCalulateShipping = async (_scaleSelected = null) => {
    console.log(_scaleSelected);
    setLoadingCart(true);
    if (!_scaleSelected) {
      calculateTotals();
      return;
    }
    let _totalOrder = 0;
    const needed = [
      "idProducto",
      "cantidad",
      "precioUnitario",
      "srcImg",
      "title",
      "divisor",
      "porcentajeIva",
      "descuento",
      "IVA"
    ];
    let newObjs = cartItems.map((o) => {
      let tmp = {};
      for (let [key, val] of Object.entries(o)) {
        if (needed.includes(key)) {
          tmp[key] = val;
        }
      }
      ////console.log(o)

      //  totalOrder += o.precioUnitario.COP * o.cantidad;
      return tmp;
    });
    let toDelete = [];
    const asyncRes = await Promise.all(
      cartItems.map(async (i, index) => {
        let pr = await getProductById(i.idProducto);
        console.log(pr, ' :pr')
        if (pr.COP === 0) {
          toDelete.push(i);
          delete newObjs[index];
          return;
        }
        let IVA = (i.porcentajeIva / 100) + 1;
        let precioSinIVA = Math.round(pr.COP / IVA);
        console.log(precioSinIVA, ' :precioSinIVA')
        let _descuentoEscalaProducto = parseInt(i.divisor) > 0 ? parseInt(i.divisor) : _scaleSelected.descuento;
        let porcentajeDescuento = _descuentoEscalaProducto === _scaleSelected.descuento ? 0 : _scaleSelected.descuento / _descuentoEscalaProducto;
        let descuento = precioSinIVA - (precioSinIVA * (porcentajeDescuento / 100));
        newObjs[index].descuento = _scaleSelected.descuento === 0 ? 0 : descuento;
        newObjs[index].iva = IVA;
        newObjs[index].precioUnitario = pr;
        if (_scaleSelected.descuento === 0) {
          _totalOrder += pr.COP * i.cantidad;
        } else {
          _totalOrder += (descuento * IVA) * i.cantidad;
        }
        //console.log(_totalOrder);
        return pr;
      })
    );
    if (Object.entries(newObjs).length === 0) {
      newObjs = [];
      Cart.deleteCart();
    }
    setCartItems(newObjs);
    Cart.setCart(JSON.stringify(newObjs));
    ////console.log(newObjs);
    let payload = {
      ciudadEnvio: userInfo ? userInfo.ciudad : 1,
      direccion: userInfo ? userInfo.direccionDeEnvio : "",
      pedido: newObjs,
      valorTotalPedido: _totalOrder,
    };
    CheckoutServices.calculateShipping(payload)
      .then((res) => {
        //console.log("Peticion realizada correctamente", res);
        switch (res.estadoRespuesta) {
          case 0:
            setCost(res.costo);
            break;

          case 2:
            console.error("Error calculating shipping: ", res);
            break;

          default:
            //console.log("Respuesta calculateShipping: ", res);
            break;
        }
        setLoadingCart(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoadingCart(false);
      });
  }; */
  /*  const chequearDiponibilidad = async (productID, cant) => {
     let payload = {
       params: {
         idProducto: productID,
         idioma: "es",
         rolUsuario: constants.rolUsuario,
         listaPrecio:
           userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
       },
     };
     let res = await ProductServices.getProductById(payload);
     //console.log(res, res.detalleProducto.inventarioDisponible >= cant);
     return res.detalleProducto.inventarioDisponible >= cant;
   }; */
  /*   const updateAmount = async (id, value) => {
      if ((await chequearDiponibilidad(id, value)) === true) {
        let order_aux = cartItems;
        let i = order_aux.findIndex((item) => {
          return item.idProducto === id;
        });
        order_aux[i].cantidad = value;
        setCartItems(order_aux);
        Cart.setCart(JSON.stringify(order_aux));
        getCalulateShipping();
      } else {
        alert(
          "Ya no hay más unidades disponibles de este producto en el inventario"
        );
      }
    }; */

  /*  const getProductById = async (productID, cant) => {
     let payload = {
       params: {
         idProducto: productID,
         idioma: "es",
         rolUsuario: constants.rolUsuario,
         listaPrecio:
           userInfo && userInfo.listaPrecio ? userInfo.listaPrecio : 20,
       },
     };
     let res = await ProductServices.getProductById(payload);
     console.log(res);
     return res.detalleProducto.precio;
   }; */

  const confirmCheckout = () => {
    Currency.setCurrency(currency);
    handleHideModal();
  };

  const handleGTMGoToPay = () => {
    cartItems.forEach((item) => {
      dataLayer.pushEvent(
        "CarritoDeCompra",
        "ClicSeleccionProductoIrAPagar",
        item.title
      );
    });
  };

  return (
    <section
      className={
        isLightBoxFullScreen
          ? "o__lightbox-wrapper fullscreen"
          : "o__lightbox-wrapper"
      }
    >
      <ToastContainer />
      {modalType === "login-modal" ? (
        <article className="o__lightbox o__lblogincont">
          {loginLoader === true && (
            <div className="cart-loader">
              <Loader />
            </div>
          )}
          <LightBoxHeader
            modaltype={modalType}
            onclick={() => {
              handleHideModal();
              document
                .querySelector("#root")
                .removeEventListener("mousedown", handleRootNodeClick);
            }}
          />
          <div className="o__lightboxlogin">
            <div className="a__input-primary">
              <span> {t("pages.log-in.user")} </span>
              <input
                className={usuarioError ? "field-error" : ""}
                type="text"
                value={formData.usuario}
                onChange={(e) => handleOnChange("usuario", e)}
              />
            </div>

            <div className="a__input-primary">
              <span> {t("pages.log-in.password")} </span>
              <input
                className={claveError ? "field-error" : ""}
                type="password"
                value={formData.clave}
                onChange={(e) => handleOnChange("clave", e)}
              />
            </div>

            {(usuarioError === true ||
              claveError === true ||
              loginError === true) && (
                <div className="center-content">
                  <p className="msj-error" ref={textError}>
                    *{t("pages.checkout.step-2.verify-fields")}
                  </p>
                </div>
              )}

            <div className="o__loginactions">
              <Link
                className="gtmIniciaSesionOlvidasteTuContrasena o__pwdrecoverylink"
                to="/recover-password"
                onClick={() => handleHideModal()}
              >
                {t("pages.log-in.forget-password")}
              </Link>
              {constants.rolUsuario > 2 ? (
                <Link
                  className="gtmIniciaSesionEnvioDeContrasena o__pwdrecoverylink"
                  to="/recover-password"
                  onClick={() => handleHideModal()}
                >
                  Envío de contraseña
                </Link>
              ) : (
                <Link
                  className="gtmIniciaSesionEnvioDeContrasena o__pwdrecoverylink"
                  to="/register"
                  onClick={() => handleHideModal()}
                >
                  Quiero Registrarme
                </Link>
              )}
              <Button
                classnames="gtmIniciaSesionIngresar primary"
                text={t("pages.log-in.btn-enterokay")}
                type="link"
                href={`/`}
                onclick={(e) => handleSubmit(e)}
              ></Button>
            </div>
            {/* <div className="o__registeraction">
              <p> {t("pages.log-in.dont-have-account-yet")} </p>
              <Link className="gtmIniciaSesionRegistrate" to="/register" onClick={() => handleHideModal()}>
                {t("pages.log-in.sing-up")}
              </Link>
            </div> */}
          </div>
        </article>
      ) : modalType === "cart-modal" ? (
        <article className="o__lightbox o__lightbox-cart">
          <LightBoxHeader
            modaltype={modalType}
            onclick={() => {
              handleHideModal();
              document
                .querySelector("#root")
                .removeEventListener("mousedown", handleRootNodeClick);
            }}
          />
          {loadingCart === true && (
            <div className="cart-loader">
              <Loader />
            </div>
          )}
          {cartItems && cartItems.length > 0 ? (
            <CartRender showButton />
          ) : (
            <p className="no-products">
              {t("pages.shopping-cart.no-products")}
            </p>
          )}
        </article>
      ) : (
        <article className="o__lightbox o__lbsuccesscont">
          <LightBoxHeader
            modaltype={modalType}
            onclick={() => {
              handleHideModal();
              document
                .querySelector("#root")
                .removeEventListener("mousedown", handleRootNodeClick);
            }}
          />
          <div className="o__lbsuccess">
            <p>{t("pages.checkout.success-msg")}</p>
            <Button
              classnames="primary gtmPagoComprobanteDePagoContinuar"
              text={t("pages.checkout.btn-continue")}
              type="link"
              href={`/`}
              onclick={() => handleHideModal()}
            ></Button>
          </div>
        </article>
      )}
    </section>
  );
};

const mapStateToProps = ({
  rdc_interface,
  rdc_account,
  rdc_product,
  rdc_cart,
}) => ({
  rdc_interface,
  rdc_account,
  rdc_product,
  rdc_cart,
});

const mapDispatchToProps = (dispatch) => ({
  handleHideModal() {
    dispatch(closeModal());
  },
  getCart,
});

export default connect(mapStateToProps, mapDispatchToProps)(LightBox);
