import React, { useState, useEffect, useRef, useCallback } from "react";
export const success = 0;
export const validationError = 1;
export const exceptionError = 2;
export const rolUsuario = 2;// 1 > final, 2 > codificado, 3 > hogares felices;

//env -- pref back-end
export const secretKey = "mhWNLp59y3qkrWtwq7bZrgiHO";


export const useStateCallback = (initialState) => {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
        cbRef.current = cb; // store current, passed callback in ref
        setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
        // cb.current is `null` on initial render, 
        // so we only invoke callback on state *updates*
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
        }
    }, [state]);

    return [state, setStateCallback];
}